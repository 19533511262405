import React from "react";
import MaterialTable from "material-table";
import { Button } from "semantic-ui-react";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from "@material-ui/core/styles";
import TablePagination from '@material-ui/core/TablePagination';
import { getAlertHistory } from "../../resources/DataAccessors";
import "../../assets/css/AlertHistory.css";
import DialogActions from '@material-ui/core/DialogActions';

const useStyles = makeStyles({
  dialogContent: {
    padding: '0px !important',
  },
  dialog: {
    width: 'auto',
  },
});

export default function AlertHistory({ device }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  // state stores the column names and data of the table
  const [state] = React.useState({
    columns: [
      { title: "Alert Name", field: "alertName" },
      { title: "Type", 
        field: "triggerType",
        render: (rowData) => {
          if (rowData.triggerType === "threshold") {
            return "static";
          }
          return "deadman";
        }
       },
      {
        title: "Level",
        field: "level",
        render: (rowData) => {
          if (rowData.level === "OK") {
            return (
              <div className="light-box">
                <div className="light-green"></div>
              </div>
            );
          } else {
            return (
              <div className="light-box">
                <div className="light-red"></div>
              </div>
            );
          }
        },
      },
      { title: "Value", field: "value" },
      { title: "Time", field: "time" },
    ],
    data: [],
  });

  const handleOpen = async () => {
    let response = await getAlertHistory(device.id, undefined, undefined);
    if(response[0].success && response[0].data) {
      /**
      * columns is a list of column names, including "alertName", "value", "time" and so on.
      * values is a list of history items. Each history item is a list of values that corresponds to the column names.
      * We convert columns and values to formattedData which is list of dictionary, like [{"alertName": "test", value: "0"...}, ....]
      * Each dictionary corresponds to a row of the table
      */
      let columns = response[0].data[0].columns,
      values = response[0].data[0].values;
      let formattedData = values.map((currElement, index) => {
        let temp = {};
        for(let i = 0; i < currElement.length; i += 1) {
          if (columns[i] === "time") {
            var date = new Date(currElement[i]);
            var formattedDate = date.toLocaleString();
            temp[columns[i]] = formattedDate;
          } else {
            temp[columns[i]] = currElement[i];
          }
        }
        return temp;
      });
      state.data = formattedData;

    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleOpen}>
        Alert History
      </Button>
      <Dialog open={open} className={classes.dialog} maxWidth="lg">
        <DialogContent className={classes.dialogContent} dividers>
        <MaterialTable
          title="Alert History"
          columns={state.columns}
          data={state.data}
          components={{
            Pagination: props => (
              <TablePagination
                  {...props}
                  rowsPerPageOptions={[5, 25, 50, 100, 150, 200]}
              />
            ),
          }}
          options={{
              dpaging: false,
              maxBodyHeight: "370px",
              headerStyle: { position: 'sticky', top: 0 },
              pageSize:50,
              exportButton: true
          }}
        />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="blue" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
