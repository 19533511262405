import React from "react";
import moment from "moment";

export default class CalendarWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  toggleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    let from = moment(this.props.from).format("M/DD");
    let to = this.props.to ? moment(this.props.to).format("M/DD") : "";

    let childStyle =
      "calendar-wrapper-child-" + (this.state.isOpen ? "open" : "closed");

    return (
      <div className="calendar-wrapper-container">
        <div
          className="calendar-wrapper-header"
          onClick={this.toggleOpen}
          style={{ backgroundColor: this.props.backgroundColor }}
        >
          <div className="calendar-wrapper-title">{this.props.title}</div>
          <div className="calendar-wrapper-date">
            {from} {this.props.to && "- " + to}
          </div>
        </div>
        <div className={childStyle}>{this.props.children}</div>
      </div>
    );
  }
}
