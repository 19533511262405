import React from "react";
import { FormGroup, Label, Input, Alert } from "reactstrap";

import {
  getSurveyQuestions,
  getSurveyAnswersByDeviceId,
  getAllBindedUsersByDeviceId,
  updateSurveyAnswers
} from "../../../resources/DataAccessors";

import AddDefault from "./AddDefault.js";

/**
 * SurveyComponent Renders the Survey Questions/Answers from database dynamically
 */
export default class AddElectricity extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initializedState();
  }

  initializedState() {
    return {
      questions: [], // Survey Questions
      answers: [], // Survey Answers Json
      isFirstUser: true,
      isSurveyVisible: false,
      registeredDeviceId: null,
    };
  }

  async componentDidMount() {
    if (this.props.showSurvey) {
      this.onCompleteRegisterDevice(this.props.deviceId);
    }
  }

  getSurveyQuestionsAndAnswersByDeviceId = async (deviceId) => {
    // Get all the survey questions
    const questions = await getSurveyQuestions();
    await this.setState({ questions: questions });

    // Prepopulate answers with question_id as key and value with ""
    let answers = {};
    Object.keys(questions).map(key => {
      answers[questions[key].question_id] = "";
      return null;
    });
    await this.setState({ answers: answers });

    const bindedUsers = await getAllBindedUsersByDeviceId(deviceId);

    await this.setState({
      isFirstUser: !bindedUsers || bindedUsers.length === 0
    });

    if (this.state.isFirstUser) return;

    const response = await getSurveyAnswersByDeviceId(deviceId);

    // If survey answers is null, do not overwrite answers with null
    if (response.survey_answers != null) {
      this.setState({ answers: response.survey_answers });
    }
  }

  handleChange = event => {
    // Copy new answers to state
    let answers = {};
    Object.keys(this.state.answers).map(key => {
      if (key === event.target.id) {
        answers[key] =
          event.target.type === "number"
            ? parseInt(event.target.value)
            : event.target.value;
      } else {
        answers[key] = this.state.answers[key];
      }
      return null;
    });

    this.setState({
      answers: answers
    });
  };

  //called when they are done registering the device id
  onCompleteRegisterDevice = (deviceId) => {
    this.getSurveyQuestionsAndAnswersByDeviceId(deviceId);
    this.setState({
      registeredDeviceId: deviceId,
      isSurveyVisible: true
    });
  }

  onSubmitSurvey = async () => {
    await updateSurveyAnswers(
      this.state.registeredDeviceId,
      this.state.answers
    );
    this.props.onComplete();
  }

  render() {
    if (
      this.state.questions === undefined &&
      this.state.answers === undefined
    ) {
      return <div>Loading Survey</div>;
    } else {
      let questions = this.state.questions.map((question, index) => {
        // Parse answer array
        let answer_arr = null;
        const answers = this.state.answers;
        if (answers != null) {
          answer_arr = Object.keys(answers).map(function (key) {
            return answers[key];
          });
        }

        if (question.question_type === "select") {
          // Parse answer_options into array of pair
          var answer_options_arr = Object.keys(question.answer_options).map(
            function (key) {
              return [String(key), question.answer_options[key]];
            }
          );

          return (
            <FormGroup key={index} survey_answers={this.state.answers}>
              <Label for={`Q${question.question_id}`}>
                {question.question_content}
              </Label>
              <Input
                type="select"
                name={question.question_id}
                id={question.question_id}
                value={answer_arr ? answer_arr[index] : ""}
                onChange={this.handleChange}
              >
                {answer_options_arr.map(elt => (
                  <option value={elt[0]} key={elt[0]}>
                    {elt[1]}
                  </option>
                ))}
              </Input>
            </FormGroup>
          );
        } else {
          return (
            <FormGroup key={index}>
              <Label for={`Q${question.question_id}`}>
                {question.question_content}
              </Label>
              <Input
                type="number"
                name={question.question_id}
                id={question.question_id}
                min={parseInt(question.answer_options.min)}
                defaultValue={
                  answer_arr && answer_arr.length > 0
                    ? answer_arr[index]
                    : undefined
                }
                onChange={this.handleChange}
              />
            </FormGroup>
          );
        }
      }); // End this.state.questions.map
      return (
        <>
          <div className="survey-electricity-title"> Electricity </div>
          {/* Show Warning if users are not not the first people to edit the survey */}
          {!this.state.isSurveyVisible &&
            <AddDefault
              hasDeviceBeenRegistered={this.props.hasDeviceBeenRegistered}
              onAddDevice={this.props.onAddDevice}
              onComplete={this.onCompleteRegisterDevice}
            />}
          {!this.state.isFirstUser && this.state.isSurveyVisible && (
            <Alert color="warning" isOpen={true}>
              Warning: this device has already been registered, and the
              following questions have been prepopulated with the previous
              registration values.
            </Alert>
          )}
          {this.state.isSurveyVisible && questions}
          {this.state.isSurveyVisible &&
            <div
              className="add-device-continue add-device-button"
              onClick={this.onSubmitSurvey}
            >
              Submit
          </div>
          }
        </>
      );
    }
  }
} // End Component
