import React from "react";
import { Modal, ModalBody, FormGroup, Input } from "reactstrap";
import { pageStyles } from "../../../resources/constants.js";
import Button from '@material-ui/core/Button';

export default class AddCovid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roomId: "",
      roomName: "",
      devices: [
        {
          deviceId: "",
          deviceName: ""
        }
      ],
      isOpen: false,
    }
  }

  getInitialState() {
    return {
      isOpen: false,
      roomName: "",
      roomDescription: "",
      roomId: "",
      visiblePage: null
    };
  }
  handleClose = async () => {
    await this.setState(this.getInitialState());
  };

  handleChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  };
  getVisiblePage() {
    return this.renderDefaultContent();
  }

  handleAddAnotherDevice = () => {
    this.state.devices.push(
      {
        deviceId: "",
        deviceName: ""
      }
    )
    this.forceUpdate()
  }

  render() {
    return (
      <div
        style={{ marginLeft: "200px" }}
        className="sidemenu-add-button"
        onClick={() => this.setState({ isOpen: !this.state.isOpen })}
      >
        +
        <Modal
          isOpen={this.state.isOpen}
          toggle={this.handleClose}
          style={pageStyles.editDeviceModal}
          backdrop={"static"}
          keyboard={false}
          className="add-device-modal"
        >
          <div className="add-device-header">
            Add Room
          </div>
          <ModalBody className="add-form-modal-body">
            <FormGroup>
              <Input
                onChange={this.handleChange}
                id="roomName"
                className="add-form-input"
                placeholder="Room Name"
                style={{ height: "50px" }}
              // value={this.state.tempName}
              />
            </FormGroup>
            <div className="add-device-header" style={{ marginBottom: "40px" }}>
              Add Devices
            </div>
            {this.state.devices.map((x, i) =>
              <div>
                <FormGroup>
                  <Input
                    onChange={this.handleChange}
                    id="deviceId1"
                    className="add-form-input"
                    placeholder={"Device Id #" + (i + 1)}
                    style={{ height: "50px" }}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    onChange={this.handleDevicePush}
                    id="deviceName1"
                    className="add-form-input"
                    placeholder={"Device Name #" + (i + 1)}
                    style={{ height: "50px" }}
                  />
                </FormGroup>
              </div>


            )}
            {/* <FormGroup>
              <Input
                onChange={this.handleChange}
                id="deviceId1"
                className="add-form-input"
                placeholder="Device ID #1"
                style={{ height: "50px" }}
              />
            </FormGroup>
            <FormGroup>
              <Input
                onChange={this.handleDevicePush}
                id="deviceName1"
                className="add-form-input"
                placeholder="Device Name #1"
                style={{ height: "50px" }}
              />
            </FormGroup> */}
            <FormGroup>
              <Button onClick={this.handleAddAnotherDevice} variant="outlined" size="large" color="primary" style={{ marginLeft: "110px" }}>
                Add Another Device
              </Button>
            </FormGroup>

          </ModalBody>

          <div className="add-device-footer">
            <div
              className="add-device-close add-device-button"
              onClick={this.handleClose}
            >
              Close
            </div>
            <div
              className="add-device-continue add-device-button"
              onClick={this.onContinue}
            >
              {this.state.visiblePage ? "Add" : "Continue"}
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}