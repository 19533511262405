import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import useWindowScrollPosition from "@rehooks/window-scroll-position";

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// Create Scroll Effects for Menu
export function MenuNavScroll() {
  const [change, setChange] = useState(false);
  const changePosition = 300;

  let position = useWindowScrollPosition();
  // position == { x: 0, y: 0 }

  if (position.y > changePosition && !change) {
    setChange(true);
  }

  if (position.y <= changePosition && change) {
    setChange(false);
  }

  let style = {
    backgroundColor: change ? "black" : "transparent",
    transition: "500ms ease",
    height: "70px",
    position: "fixed",
    zIndex: 1000,
    right: 0,
    left: 0,
    top: 0
  };

  return (
    // Empty Div that handles the menu nav bar on scroll function
    <div style={style}></div>
  );
}
