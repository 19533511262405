import React from "react";
// Our Sample Example Section
import analysis from "../../assets/img/landing.png"; // relative path to image
import analysis2 from "../../assets/img/sidemenu.png"; // relative path to image
import { landingFields } from "../../resources/constants";

export default () => {
  let imgStyle = {
    width: "100%",
    height: "auto",
    maxHeight: "400px",
    maxWidth: "600px",
    boxShadow: "5px 5px 17px -4px rgba(199, 199, 199, 0.6)"
  };

  let sideMenuStyle = {
    width: 80,
    height: "auto"
  };

  let marginStyle = {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  };

  return (
    <>
      <section className="page-section" id="projects">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <h2>{landingFields.view_data_in_real_time.title}</h2>
              <p>{landingFields.view_data_in_real_time.body}</p>
            </div>
            <div className="col-md-7">
              <img src={analysis} alt="analysis1" style={imgStyle} />
            </div>
          </div>

          <hr />
          <br />
          <div className="row">
            <div className="col-md-1"> </div>
            <div className="col-md-3">
              <img src={analysis2} alt="analysis2" style={sideMenuStyle} />
            </div>
            <div className="col-md-1"> </div>
            <div className="col-md-7" style={marginStyle}>
              <h2>{landingFields.monitor_multiple_devices.title}</h2>
              <p>{landingFields.monitor_multiple_devices.body}</p>
            </div>
          </div>
        </div>
      </section>
      {/*landingFields.projects.map((project, index) => {
        return (
          <section key={index} className={"page-section " + (index % 2 === 0 ? "bg-light" : "")}>
            <div className="container">
              <div className="row">
                <div className="col-md-5">
                  <h2>{project.title}</h2>
                  <p>{project.body}</p>
                </div>
                <div className="col-md-7">
                  <img
                    src={IMAGES[project.summary_image_in_constants]}
                    alt={project.title}
                    style={imgStyle} />
                </div>
              </div>
            </div>
          </section>
        )
      })*/}
    </>
  );
};
