export const mapDisplayMode = {
  realtime: "real-time",
  playback: "playback",
  refreshPlayback: "refresh-playback"
};

export const deviceType = {
  drone: "drone",
  stationary: "stationary",
  handHeld: "handHeld"
};

export const markerType = {
  connectedCircle: "connected-circle",
  default: "default"
};
