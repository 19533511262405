import React from "react";
import { Line } from "react-chartjs-2";
import { pageStyles } from "../../resources/constants.js";

export default class LineChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: {} };
  }

  componentDidMount() {
    this._initializeData([this.props.data]);
  }

  async componentDidUpdate(prevProps) {
    //want to take the deviceId tag and parse it to the specific data we want
    if (
      (this.props.fieldsY && this.props.fieldsY[0] !== prevProps.fieldsY[0]) ||
      this.props.data !== prevProps.data
    ) {
      this._initializeData([this.props.data]);
    }
  }

  /*
  Formats data the way that react-chartjs-2 uses
  data MUST have field "timestamp"
  */
  _initializeData(data) {
    //if there is no data, render a blank chart
    if (!data || data.length === 0) {
      return {};
    }

    let x_values = [];
    let y_values = [];
    let datasets = [];

    let format = this.props.formatter; // format y values

    //first iterate through each dataset
    for (let i = 0; i < data.length; i++) {
      //check if the dataset exists
      if (data[i] && data[i].values.length > 0) {
        var data_values = data[i].values.slice(-1 * this.props.size);
        //push all the timestamps into the x_values list
        if (x_values.length === 0) {
          let indexX = data[i].columns.findIndex(
            field => field === this.props.fieldsX[0]
          );
          data_values.forEach((d, i) => {
            x_values.push(this._formatTimestamp(d[indexX]));
          });
        }

        //initialize an acc for each field we want to display on the page
        for (let y = 0; y < this.props.fieldsY.length; y++) {
          y_values.push([]);

          //find which column the field is in for the dataset
          let indexY = data[i].columns.findIndex(
            field => field === this.props.fieldsY[y]
          );

          data_values.forEach((d, i) => {
            y_values[y].push(format(d[indexY]));
          });

          datasets.push({
            label: this.props.datasetLabels[y],
            data: y_values[y],
            ...pageStyles.lineChartLinesStyles
          });

          if (this.props.alertData) {
            this.props.alertData.forEach((d, i) => {
              if (d.trigger === "threshold") {
                let thresh_value = d.values.value;
                let thresh_name = d.name;
                if (y_values[y][y_values[y].length - 1] < thresh_value) {
                  datasets.push({
                    label: thresh_name,
                    data: Array.apply(null, Array(y_values[y].length)).map(
                      function() {
                        return thresh_value;
                      }
                    ),
                    ...pageStyles.lineChartLinesStylesThreshold
                  });
                } else {
                  datasets.push({
                    label: thresh_name,
                    data: Array.apply(null, Array(y_values[y].length)).map(
                      function() {
                        return thresh_value;
                      }
                    ),
                    ...pageStyles.lineChartLinesStylesThresholdAlert
                  });
                }
              }
            });
          }
        }
      }
    }

    let final = {
      labels: x_values,
      datasets: datasets
    };
    this.setState({ data: final });
  }

  /*
    Formats the timestamp in terms of the format option
    if null, returns current date
  */
  _formatTimestamp(timestamp) {
    //let date = new Date(timestamp);
    //now format it by how consumer wants
    return this.props.formatXAxis(timestamp);
  }

  render() {
    let data = this.state.data;
    options = JSON.parse(JSON.stringify(options));
    options.scales.yAxes[0].scaleLabel.labelString = this.props.yAxisLabel;
    options.scales.xAxes[0].scaleLabel.labelString = this.props.xAxisLabel;
    options.scales.yAxes[0].ticks.callback = this.props.formatYAxis;
    return (
      <div className={"simple-line-chart-wrapper"} style={{ width: "100%" }}>
        {data.length !== 0 && <Line data={data} options={options} />}
        {data.length === 0 && (
          <div className="default-line-data"> No data for selected range </div>
        )}
      </div>
    );
  }
}

let options = {
  legend: {
    labels: {
      fontColor: pageStyles.lineChartStyles.legendLabelTextColor
    }
  },
  scales: {
    yAxes: [
      {
        ticks: {
          fontColor: pageStyles.lineChartStyles.ticksColor,
          suggestedMax: 10
        },
        scaleLabel: {
          display: true,
          padding: 1
        },
        gridLines: {
          display: true,
          color: pageStyles.lineChartStyles.gridLinesColor
        }
      }
    ],
    xAxes: [
      {
        ticks: {
          autoSkipPadding: 15,
          maxRotation: 0,
          fontColor: pageStyles.lineChartStyles.ticksColor
        },
        scaleLabel: {
          display: true,
          padding: 1,
          labelString: "Time (hh:mm:ss)"
        },
        gridLines: {
          display: true,
          color: pageStyles.lineChartStyles.gridLinesColor
        }
      }
    ]
  }
};
