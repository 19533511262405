export default class ElectricityData {
  constructor(startDate, endDate, deviceId, mode, rawData) {
    this._startDate = startDate;
    this._endDate = endDate;
    this._deviceId = deviceId;
    this._mode = mode;
    this._name = rawData.name;
    this._columns = rawData.columns;

    this._values = Array.isArray(rawData.values)
      ? this._convertValues(rawData.values)
      : [];

    this._maxPower = null;
    this._maxPowerDate = null;

    this._convertMaxPowerValues(rawData["max-power"]);
  }

  toString() {
    return this._rawData;
  }

  _convertMaxPowerValues(data) {
    let isValid = !Array.isArray(data) && Array.isArray(data.values);

    this._maxPower = isValid ? data.values[0][1] : undefined;
    this._maxPowerDate = isValid ? data.values[0][0] : undefined;
  }

  _convertValues(values) {
    return values.map(value => new Data(value[0], value[1], value[2]));
  }

  isEmpty() {
    return !this._values || this._values.length === 0;
  }

  getValues() {
    return this._values;
  }

  getDeviceId() {
    return this._deviceId;
  }

  getStartDate() {
    return this._startDate;
  }

  getEndDate() {
    return this._endDate;
  }

  getMode() {
    return this._mode;
  }

  getName() {
    return this._name;
  }

  getMaxPowerInformation() {
    return {
      max: this._maxPower,
      date: this._maxPowerDate
    };
  }

  setName(name) {
    this._name = name;
  }
}

class Data {
  constructor(time, power, maxPower) {
    this.timestamp = time;
    this.power = power;
    this.maxPower = maxPower;
  }

  toString() {
    return `Time: ${this.time} \t Power: ${this.power} \t Max Power: ${this.maxPower}`;
  }
}
