import React from "react";
import SimpleCalendar from "../Calendar/SimpleCalendar.js";
import PastRangeCalendar from "../Calendar/PastRangeCalendar";
import moment from "moment";
import CalendarWrapper from "../Calendar/CalendarWrapper";
import TRHChart from "./TRHChart";

import {
  lineChartModes,
  getPrevDay,
  getTimeInterval,
  getNextDay,
  getNearbyDates,
  getPrevDays,
  pageStyles,
  lineColors
} from "../../resources/constants.js";

import {
  getDailyTemperatureRelativeHumidityReadings,
  getHourlyTemperatureRelativeHumidityReadings
} from "../../resources/DataAccessors.js";

const dateFormat = "YYYY-MM-DD";

export default class TRHInterface extends React.Component {
  constructor(props) {
    super(props);

    let { today, lastWeek } = getNearbyDates();
    let yesterday = getPrevDay(today);
    let interval = getTimeInterval(lastWeek, today);
    let dayBeforeLastWeek = getPrevDay(lastWeek);

    this.state = {
      lineData: [],
      displayedDevices: [],
      selectionNotFinished: false,
      interval: interval,

      //day tab selections
      currentSelectedDay: today,
      pastSelectedDay: yesterday,

      //range tab selections, default to the last 7 days
      currentSelectedDayRange: {
        startDate: lastWeek,
        endDate: today
      },
      pastSelectedDayRange: {
        // want 1 less to make start date inclusive
        startDate: getPrevDays(dayBeforeLastWeek, interval),
        endDate: dayBeforeLastWeek
      },

      disabledDays: {
        after: moment(dayBeforeLastWeek).toDate()
      },
      simpleDisabledDays: {
        after: moment(yesterday).toDate()
      },

      //average vs peak power and daily vs hourly modes
      mode: props.mode || lineChartModes.daily,
    };

  }

  async componentDidMount() {
    await this.refreshData();
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.device &&
      (!prevProps.device || this.props.device.id !== prevProps.device.id)
    ) {
      await this.initializeDevices();
      await this.refreshData();
    }
  }

  refreshData = async () => {
    await this.props.setIsLoading(true);

    let nowDateRange = {};

    if (this.state.mode === lineChartModes.hourly) {
      nowDateRange = {
        startDate: this.state.currentSelectedDay,
        endDate: getNextDay(this.state.currentSelectedDay)
      };
    } else {
      nowDateRange = {
        startDate: this.state.currentSelectedDayRange.startDate,
        endDate: getNextDay(this.state.currentSelectedDayRange.endDate)
      };
    }


    if (this.state.displayedDevices.length === 0) {
      await this.setState({ lineData: [] });
    } else if (this.state.displayedDevices.length > 0) {

      let data = [];

      for (let i = 0; i < this.state.displayedDevices.length; i++) {
        let deviceIndex = this.state.displayedDevices[i];
        let device = this.props.devices[deviceIndex];

        let nowData = [];
        if (this.state.mode === lineChartModes.daily) {
          nowData = await getDailyTemperatureRelativeHumidityReadings(
            nowDateRange.startDate,
            nowDateRange.endDate,
            device.id
          );
        } else {
          nowData = await getHourlyTemperatureRelativeHumidityReadings(
            nowDateRange.startDate,
            nowDateRange.endDate,
            device.id
          );
        }
        nowData.setName(device.name);

        data.push(nowData);
      }

      await this.setState({ lineData: data });
    }

    await this.props.setIsLoading(false);
  };

  async currentRangeCalendarSelect(start, end) {
    let formattedStartDate = moment(start).format(dateFormat);
    let formattedEndDate = moment(end).format(dateFormat);

    await this.setState({
      currentSelectedDayRange: {
        startDate: formattedStartDate,
        endDate: formattedEndDate
      },
      interval: getTimeInterval(formattedStartDate, formattedEndDate),

      //disable days after the start of the current selection
      disabledDays: {
        after: moment(getPrevDay(start)).toDate()
      }
    });

    //update the past selection if it overlaps with the current selection
    let formattedPastEndDate = moment(this.state.pastSelectedDayRange.endDate);
    if (formattedPastEndDate.isAfter(getPrevDay(formattedStartDate))) {
      //selection is invalid
      let interval = getTimeInterval(formattedStartDate, formattedEndDate) + 1;

      await this.setState({
        pastSelectedDayRange: {
          startDate: getPrevDays(formattedStartDate, interval),
          endDate: getPrevDay(formattedStartDate)
        }
      });
    }

    await this.refreshData();
  }

  async currentDayCalendarSelect(date) {
    let startDate = moment(date).format(dateFormat);

    await this.setState({
      currentSelectedDay: startDate,
      simpleDisabledDays: {
        after: moment(getPrevDay(date)).toDate()
      }
    });

    //correct past date if invalid
    let pastDay = moment(this.state.pastSelectedDay);
    if (pastDay.isAfter(moment(getPrevDay(startDate)))) {
      await this.setState({ pastSelectedDay: getPrevDay(startDate) });
    }

    await this.refreshData();
  }

  async dayCalendarSelect(date) {
    if (this.state.mode === lineChartModes.daily) {
      let { startDate, endDate } = this.state.currentSelectedDayRange;
      let differenceInDays = getTimeInterval(startDate, endDate);

      await this.setState({
        pastSelectedDayRange: {
          startDate: getPrevDays(date, differenceInDays),
          endDate: moment(date).format(dateFormat)
        },
        interval: differenceInDays
      });
    } else {
      await this.setState({
        pastSelectedDay: moment(date).format(dateFormat)
      });
    }
    await this.refreshData();
  }

  calendarResetHandler = async () => {
    await this.setState({
      disabledDays: {
        daysOfWeek: [0, 1, 2, 3, 4, 5, 6]
      },
      selectionNotFinished: true
    });
  };

  selectionFinished = async () => {
    await this.setState({ selectionNotFinished: false });
  };

  renderCalendarPicker() {
    let currentStartDate = this.state.currentSelectedDayRange.startDate;
    let currentEndDate = this.state.currentSelectedDayRange.endDate;


    return this.state.mode === lineChartModes.daily ? (
      <span>
        <div>
          <CalendarWrapper
            title={"Current Period"}
            from={currentStartDate}
            to={currentEndDate}
            backgroundColor={lineColors[0]}
          >
            <PastRangeCalendar
              onSelect={(start, end) =>
                this.currentRangeCalendarSelect(start, end)
              }
              from={currentStartDate}
              to={currentEndDate}
              calendarResetHandler={this.calendarResetHandler}
              selectionFinished={this.selectionFinished}
            />
          </CalendarWrapper>
        </div>
      </span>
    ) : (
        <span>
          <div>
            <CalendarWrapper
              title={"Current Day"}
              from={this.state.currentSelectedDay}
              backgroundColor={lineColors[0]}
            >
              <SimpleCalendar
                selectedDay={this.state.currentSelectedDay}
                onSelect={date => this.currentDayCalendarSelect(date)}
                disabledDays={{ after: new Date() }}
              />
            </CalendarWrapper>
          </div>
        </span>
      );
  }

  async swapToDaily() {
    await this.setState({ mode: lineChartModes.daily });
    await this.refreshData();
  }

  async swapToHourly() {
    await this.setState({ mode: lineChartModes.hourly });
    await this.refreshData();
  }


  getChartYAccessor() {
    if (this.props.label === "temperature") {
      return { yAccessor: d => d.temperature };
    } else {
      return { yAccessor: d => d.relativeHumidity };
    }
  }


  async displayDevice(deviceIndex) {
    if (deviceIndex >= this.props.devices.length) {
      console.log("invalid device selection.");
      return;
    }

    if (!this.state.displayedDevices.includes(deviceIndex)) {
      await this.setState({
        displayedDevices: this.state.displayedDevices.concat(deviceIndex)
      });
    } else {
      await this.setState({
        displayedDevices: this.state.displayedDevices.filter(id => id !== deviceIndex)
      });
    }
    await this.refreshData();
  }

  renderFooterButtons() {
    let buttons = []
    for (let i = 0; i < this.props.devices.length; i++) {
      buttons.push(
        <TRHDeviceButton
          device={this.props.devices[i]}
          isSelected={this.state.displayedDevices.includes(i)}
          onClick={async () => await this.displayDevice(i)}
          key={i}
        />
      )
    }
    return buttons;
  }

  render() {
    return (
      <div className="you-vs-container">

        <div className="linechart-calendar">

          <div className="linechart-left">
            <TRHChart
              isLoading={this.props.isLoading}
              data={this.state.lineData}
              xAccessor={d => d.timestamp}
              yAccessorsAndLabel={[this.getChartYAccessor()]}
              formatXAxis={time => this.state.mode.format(time)}
              formatYAxis={value => value + " °F"}
              formatter={value => (value).toFixed(1)}
              xAxisLabel="Date"
              yAxisLabel="°F"
              label={this.props.label}
            />

            <span className="trh-chart-footer">

              <span className="youvsyou-chart-button-wrapper trh-footer-buttons-wrapper" style={{ boxShadow: "none" }}>
                {this.renderFooterButtons()}
              </span>

              <span
                className="youvsyou-chart-button-wrapper"
                style={{ marginLeft: 30 }}
              >
                <div
                  className="trh-chart-mode-button"
                  style={{
                    backgroundColor:
                      this.state.mode === lineChartModes.daily
                        ? pageStyles.lineChartStyles.selectedColor
                        : pageStyles.lineChartStyles.deselectedColor,
                    color:
                      this.state.mode === lineChartModes.daily
                        ? pageStyles.lineChartStyles.selectedColorText
                        : pageStyles.lineChartStyles.deselectedColorText
                  }}
                  onClick={async () => await this.swapToDaily()}
                >
                  Daily
                  </div>
                <div
                  className="trh-chart-mode-button"
                  style={{
                    backgroundColor:
                      this.state.mode === lineChartModes.hourly
                        ? pageStyles.lineChartStyles.selectedColor
                        : pageStyles.lineChartStyles.deselectedColor,
                    color:
                      this.state.mode === lineChartModes.hourly
                        ? pageStyles.lineChartStyles.selectedColorText
                        : pageStyles.lineChartStyles.deselectedColorText
                  }}
                  onClick={async () => await this.swapToHourly()}
                >
                  Hourly
                  </div>
              </span>
            </span>


          </div>
          <div className="right-calendar">{this.renderCalendarPicker()}</div>
        </div>
      </div>
    )
  }
}

const TRHDeviceButton = (props) => {
  return <div
    className="trh-chart-mode-button"
    style={{
      backgroundColor:
        props.isSelected
          ? pageStyles.lineChartStyles.selectedColor
          : pageStyles.lineChartStyles.deselectedColor,
      color:
        props.isSelected
          ? pageStyles.lineChartStyles.selectedColorText
          : pageStyles.lineChartStyles.deselectedColorText
    }}
    onClick={props.onClick}
  >
    {props.device.name}
  </div>
}