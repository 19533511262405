import React from "react";
import { Tooltip, DialogContent } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { Button } from "semantic-ui-react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import {
  getAlertsByDeviceID,
  createAlert,
  updateAlert,
  deleteAlert
} from "../../resources/DataAccessors";
import DialogActions from "@material-ui/core/DialogActions";
import { devices_codes } from "../../resources/constants.js";
import { MTableEditField } from "material-table";

const useStyles = makeStyles({
  dialogContent: {
    padding: "0px !important"
  },
  dialog: {
    width: "auto"
  }
});

export default function AlertInfo({ device }) {
  const classes = useStyles();

  const LargerTooltip = withStyles({
    tooltip: {
      fontSize: "1em"
    }
  })(Tooltip);

  const createHeaderWithTooltip = (title: string, tooltip: string) => {
    return (
      <LargerTooltip title={tooltip} placement="top" arrow>
        <div>{title}</div>
      </LargerTooltip>
    );
  };

  const [open, setOpen] = React.useState(false);
  // state stores the column names (with column description) and data of the table
  const [state, setState] = React.useState({
    columns: [
      { title: createHeaderWithTooltip("Alert", "Alert name"), field: "name" },
      // Important clarification: In the backend, the alert types are Threshold or Deadman, while in the frontend, they renamed as Static or Deadman. This is to distinguish with the threshold values that users need to set.
      {
        title: createHeaderWithTooltip(
          "Type",
          "Static alerts: Alert if the data cross a boundary; Deadman alerts: Alert if no relevant data is received for the specified time duration"
        ),
        field: "type",
        lookup: { threshold: "static", deadman: "deadman" }
      },
      {
        title: createHeaderWithTooltip(
          "Threshold",
          "For static alerts, set value for threshold to beat; For deadman alerts, set the number of hours as threshold."
        ),
        field: "threshold",
        render: rowData => {
          if (rowData.type === "threshold") {
            //static alerts
            return rowData.threshold;
          }
          return rowData.threshold + (rowData.threshold > 1 ? "hours" : "hour");
        }
      },
      {
        title: createHeaderWithTooltip(
          "Email",
          "Emails to send alerts to (separated by commas)"
        ),
        field: "email"
      }
    ],
    data: []
  });

  const handleOpen = async () => {
    let response = await getAlertsByDeviceID(device.id);
    if (response[0].success && response[0].rules) {
      /**
       * alerts is a list of alerts. Each alert item is a dictionary.
       * We convert alerts to data which is list of dictionary
       * Each dictionary corresponds to a row of the table
       */
      let alerts = response[0].rules;
      let data = alerts.map(alert => {
        return {
          name: alert.name,
          type: alert.trigger,
          threshold:
            alert.trigger === "threshold"
              ? alert.values.value
              : alert.values.period.substr(0, alert.values.period.indexOf("h")),
          email: alert.alertNodes.email[0].to.join(", "),
          ruleID: alert.id
        };
      });
      state.data = data;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /**
   * Validate the emails entered by user one by one.
   * If any of the email is in wrong format, return false; otherwise, return true.
   */
  const validateEmail = emailString => {
    if (!emailString) {
      return true;
    }
    var emailArray = emailString.split(/[ ,]+/);
    for (var i = 0; i < emailArray.length; i++) {
      if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailArray[i])) {
        return false;
      }
    }
    return true;
  };

  /**
   * Check if a number is positive integer
   */
  const isPositiveInteger = n => {
    return n >>> 0 === parseFloat(n);
  };

  return (
    <div>
      <Button onClick={handleOpen}>Edit Alerts</Button>
      <Dialog className={classes.dialog} open={open} maxWidth="lg">
        <DialogContent className={classes.dialogContent} dividers>
          <MaterialTable
            title="Alert Settings"
            columns={state.columns}
            data={state.data}
            editable={{
              onRowAdd: newData =>
                new Promise(resolve => {
                  setTimeout(() => {
                    (async () => {
                      var isEmailValid = validateEmail(newData.email);
                      if (
                        (newData.type === "threshold" &&
                          isNaN(newData.threshold)) ||
                        (newData.type === "deadman" &&
                          !isPositiveInteger(newData.threshold))
                      ) {
                        alert("Please enter a valid threshold!");
                        resolve();
                      } else if (!isEmailValid) {
                        alert("Please enter valid emails separated by comma!");
                        resolve();
                      } else {
                        let query = {};
                        if (device.type === devices_codes.air) {
                          query = {
                            database: "Air_Pollution",
                            measurement: "Air_Pollution_SEC",
                            retentionPolicy: "rp_sec",
                            field: "pm25data",
                            deviceID: device.id
                          };
                        } else if (device.type === devices_codes.electricity) {
                          query = {
                            database: "Electricity",
                            measurement: "USR_Electricity_SEC",
                            retentionPolicy: "rp_sec",
                            field: "power",
                            deviceID: device.id
                          };
                        }
                        const response = await createAlert(
                          newData.name,
                          newData.type,
                          newData.threshold,
                          newData.email,
                          query
                        );
                        resolve();
                        if (response[0].success) {
                          // If the alert is successfully added, add a new row with the alert information to the table
                          setState(prevState => {
                            const data = [...prevState.data];
                            newData.ruleID = response[0].ruleID;
                            data.push(newData);
                            return { ...prevState, data };
                          });
                        } else {
                          alert("Alert creation failed");
                        }
                      }
                    })();
                  }, 600);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise(resolve => {
                  setTimeout(() => {
                    (async () => {
                      var isEmailValid = validateEmail(newData.email);
                      if (
                        (newData.type === "threshold" &&
                          isNaN(newData.threshold)) ||
                        (newData.type === "deadman" &&
                          !isPositiveInteger(newData.threshold))
                      ) {
                        alert("Please enter an integer as the threshold!");
                        resolve();
                      } else if (!isEmailValid) {
                        alert("Please enter valid emails separated by comma!");
                        resolve();
                      } else {
                        let query = {};
                        if (device.type === devices_codes.air) {
                          query = {
                            database: "Air_Pollution",
                            measurement: "Air_Pollution_SEC",
                            retentionPolicy: "rp_sec",
                            field: "pm25data",
                            deviceID: device.id
                          };
                        } else if (device.type === devices_codes.electricity) {
                          query = {
                            database: "Electricity",
                            measurement: "USR_Electricity_SEC",
                            retentionPolicy: "rp_sec",
                            field: "power",
                            deviceID: device.id
                          };
                        }
                        const response = await updateAlert(
                          newData.ruleID,
                          newData.name,
                          newData.type,
                          newData.threshold,
                          newData.email,
                          query
                        );
                        resolve();
                        if (response[0].success) {
                          // If the alert is successfully updated, update the row with new alert information
                          if (oldData) {
                            setState(prevState => {
                              const data = [...prevState.data];
                              data[data.indexOf(oldData)] = newData;
                              return { ...prevState, data };
                            });
                          }
                        } else {
                          alert("Alert update failed");
                        }
                      }
                    })();
                  }, 600);
                }),
              onRowDelete: oldData =>
                new Promise(resolve => {
                  setTimeout(() => {
                    (async () => {
                      const response = await deleteAlert(oldData.ruleID);
                      resolve();
                      if (response[0].success) {
                        // If the alert is successfully deleted, remove the row from the table
                        setState(prevState => {
                          const data = [...prevState.data];
                          data.splice(data.indexOf(oldData), 1);
                          return { ...prevState, data };
                        });
                      } else {
                        alert("Alert deletion failed");
                      }
                    })();
                  }, 600);
                })
            }}
            options={{
              actionsColumnIndex: -1,
              dpaging: false,
              maxBodyHeight: "350px",
              headerStyle: { position: "sticky", top: 0 }
            }}
            components={{
              EditField: props => {
                // Set the placeholder of each edit field to empty string
                const newColumnDef = { ...props.columnDef, title: "" };
                let newProps = {
                  ...props,
                  ...{
                    columnDef: newColumnDef
                  }
                };
                return <MTableEditField {...newProps} />;
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="blue" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
