import React, { Component } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import LoaderButton from "../components/LoaderButton";
import { Auth } from "aws-amplify";
import {
  addNewAccount,
  getDeviceIdAndTypeById,
  addDeviceToAccount,
  getDevicesByEmail
} from "../resources/DataAccessors";
import { pageStyles } from "../resources/constants";
export const pageId = {
  email: "user",
  confirm: "confirmation",
  password: "password",
  deviceId: "device id",
  updateDeviceInfo: "update device info",
  signIn: "sign in"
};

var strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-!$%^&*()_+|~=`{}[\]:";'<>?,.\/\\])(?=.{8,})/;

export default class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: "",
      confirmPassword: "",
      confirmationCode: "",
      newUser: props.user || null,
      deviceId: "",
      pageId: props.pageId || pageId.email,
      deviceName: "",
      deviceDescription: ""
    };
  }

  validateDeviceIdForm() {
    return this.state.deviceId.length > 0;
  }

  validatePasswordForm() {
    return (
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword &&
      strongPasswordRegex.test(this.state.password)
    );
  }

  validateUsername() {
    return this.state.email.length > 0;
  }

  validateConfirmationForm() {
    return (
      this.state.confirmationCode && this.state.confirmationCode.length > 0
    );
  }

  validateDeviceInfoForm() {
    return this.state.deviceName && this.state.deviceName.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handlePasswordSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.signUp({
        username: this.state.email,
        password: this.state.password,
        attributes: {
          email: this.state.email
        }
      });
      this.setState({
        pageId: pageId.confirm
      });
    } catch (e) {
      console.warn(e);
    }

    this.setState({ isLoading: false });
  };

  handleConfirmationSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
      const newUser = await Auth.signIn(this.state.email, this.state.password);

      //add user to user table
      await addNewAccount(this.state.email);

      this.setState({
        pageId: pageId.deviceId,
        newUser: newUser,
        isLoading: false
      });
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  };

  handleUserSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    try {
      /*Appears that AWS Cognito dropped support for Auth.signIn without
        a password field. We want a dummy password to attempt to sign in this
        email to check if the account already exists. "password" is guaranteed
        not to be the correct password due to strong password requirements.
      */
      await Auth.signIn(this.state.email, "password");
    } catch (e) {
      if (e.code === "UserNotFoundException") {
        this.setState({ pageId: pageId.password });
      } else if (e.code === "UserNotConfirmedException") {
        if (
          window.confirm(
            "Email already exists, but is not confirmed. Would you like to resend the confirmation code?"
          )
        ) {
          Auth.resendSignUp(this.state.email);
          this.setState({ pageId: pageId.confirm });
        } else {
          this.setState({ email: "" });
        }
      } else {
        alert(
          "An account was found with the same username. Please try logging in."
        );
      }
      this.setState({ isLoading: false });
    }
  };

  handleDeviceIdSubmit = async event => {
    event.preventDefault();

    //if device id is valid, skip adding the device
    if (!this.validateDeviceIdForm()) {
      this.props.closeModal();
      this.props.userHasAuthenticated(true);
      return;
    }

    //check sql database to see if there is a corresponding device for this user

    const isValid = await getDeviceIdAndTypeById(this.state.deviceId);

    if (isValid) {
      this.setState({ pageId: pageId.updateDeviceInfo });
    } else {
      alert("Device ID Not found. Check if you typed in the code correctly.");
    }
  };

  handleDeviceInfoSubmit = async event => {
    event.preventDefault();

    // update database with new device information
    await addDeviceToAccount(
      this.state.email,
      this.state.deviceId,
      this.state.deviceName,
      this.state.deviceDescription
    );

    const devices = await getDevicesByEmail(this.state.email);
    this.props.updateDeviceIds(devices);
    this.props.closeModal();
    this.props.userHasAuthenticated(true);
  };

  renderConfirmationForm() {
    return (
      <form onSubmit={this.handleConfirmationSubmit}>
        <FormGroup>
          <Label>Confirmation Code</Label>
          <Input
            autoFocus
            type="tel"
            value={this.state.confirmationCode}
            onChange={this.handleChange}
            id="confirmationCode"
            style={pageStyles.input}
          />
        </FormGroup>
        <LoaderButton
          block
          disabled={!this.validateConfirmationForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Verify"
          loadingText="Verifying…"
        />
      </form>
    );
  }

  renderUserForm() {
    return (
      <form onSubmit={this.handleUserSubmit}>
        <FormGroup>
          <div className="header-warning">
            School emails (id@college.edu) may mark the confirmation mail as
            spam.
          </div>
          <br />
          <Label>Email</Label>
          <Input
            autoFocus
            type="email"
            value={this.state.email}
            onChange={this.handleChange}
            style={pageStyles.input}
            id="email"
          />
        </FormGroup>
        <LoaderButton
          block
          disabled={!this.validateUsername()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Next"
          loadingText="Checking user…"
        />
      </form>
    );
  }

  renderDeviceIDForm() {
    return (
      <form onSubmit={this.handleDeviceIdSubmit}>
        We now want to connect your{" "}
        <strong> physical device to this account </strong>. Look on the box or
        in a confirmation email for an alphanumeric code and enter it here. If
        you have multiple devices you will be able to add them after you log in!
        <br />
        <FormGroup>
          <Label>Device ID</Label>
          <Input
            type={"text"}
            value={this.state.deviceId}
            onChange={this.handleChange}
            id="deviceId"
            style={pageStyles.input}
          />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          isLoading={this.state.isLoading}
          text={!this.validateDeviceIdForm() ? "Skip" : "Signup"}
          loadingText="Verifying device id..."
        />
      </form>
    );
  }

  renderDeviceInfoForm() {
    return (
      <form onSubmit={this.handleDeviceInfoSubmit}>
        Please make up a name and a description of this device for you to be
        able to identify it in the future.
        <br />
        <FormGroup>
          <Label>Device Name</Label>
          <Input
            type={"text"}
            value={this.state.deviceName}
            onChange={this.handleChange}
            id="deviceName"
            style={pageStyles.input}
          />
        </FormGroup>
        <FormGroup>
          <Label>Device Description</Label>
          <Input
            type={"text"}
            value={this.state.deviceDescription}
            onChange={this.handleChange}
            id="deviceDescription"
            style={pageStyles.input}
          />
        </FormGroup>
        <LoaderButton
          block
          disabled={!this.validateDeviceInfoForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Submit"
          loadingText="Updating device..."
        />
      </form>
    );
  }

  renderPasswordForm() {
    return (
      <form onSubmit={this.handlePasswordSubmit}>
        <FormGroup>
          <Label>Password</Label>
          <Input
            value={this.state.password}
            onChange={this.handleChange}
            type="password"
            id="password"
            style={pageStyles.input}
          />
          <div className="form-subtext">
            Passwords must be longer than 8 characters, and must contain a upper
            and lower case letter, a number and a symbol.
          </div>
        </FormGroup>
        <FormGroup>
          <Label>Confirm Password</Label>
          <Input
            value={this.state.confirmPassword}
            onChange={this.handleChange}
            type="password"
            id="confirmPassword"
            style={pageStyles.input}
          />
        </FormGroup>
        <LoaderButton
          block
          disabled={!this.validatePasswordForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Next"
          loadingText="Checking password..."
        />
      </form>
    );
  }

  render() {
    let page = null;
    switch (this.state.pageId) {
      case pageId.email:
        page = this.renderUserForm();
        break;
      case pageId.confirm:
        page = this.renderConfirmationForm();
        break;
      case pageId.password:
        page = this.renderPasswordForm();
        break;
      case pageId.deviceId:
        page = this.renderDeviceIDForm();
        break;
      case pageId.updateDeviceInfo:
        page = this.renderDeviceInfoForm();
        break;
      default:
        page = null;
    }

    return (
      <div className="Signup">
        <div className="lander" />
        {page}
      </div>
    );
  }
}
