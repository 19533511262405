import moment from "moment";
import lightbulb from "../assets/img/lightbulb.png";
import landingFieldsJson from "./landingFields";
import electricityFieldsJson from "./electricityFields";
import electricityTipsJson from "./electricityTips.json";

export const landingFields = landingFieldsJson;
export const electricityFields = electricityFieldsJson;
export const electricityTips = electricityTipsJson;

//constants

//matches those written in the database
export const devices_codes = {
  electricity: "electricity",
  air: "air",
  trh: "trh",
  covid: "covid",
  digitalAgriculture: "digitalAgriculture"
};

/*Keys must exactly match those in device codes*/
export const deviceTypesNames = {
  electricity: "Electricity",
  air: "Air Pollution",
  water: "Water",
  trh: "Temperature and Humidity",
  covid: "Covid",
  digitalAgriculture: "Digital Agriculture"
};

export const deviceTypesColors = {
  electricity: "#fff7b0",
  air: "#ffdb9e",
  water: "#a6fcff",
  trh: "#ffe4e4",
  covid: "#ffd2fa",
  digitalAgriculture: "#dbffbd"
}

//global constants for the web app
export const constants = {
  confirmationCodeLength: 6,
  separator: "&"
};

export const IMAGES = {
  settings: require("../assets/img/settings.png"),
  calendar: require("../assets/img/calendar.png"),
  heatMap: require("../assets/img/heatmap.png"),
  lineChart: require("../assets/img/linechart.png"),
  hamburgerMenu: require("../assets/img/hamburger-menu.png"),
  addButton: require("../assets/img/add-button.png"),
  trophyLit: require("../assets/img/trophy-icon.png"),
  trophyGray: require("../assets/img/trophy-icon-gray.png"),
  //project sample images
  buildingsSample: require("../assets/img/settings.png"),
  solarSample: require("../assets/img/solar-sample.png"),
  healthSample: require("../assets/img/settings.png"),
  transportationSample: require("../assets/img/settings.png"),
  //the following keys must match exactly with the devices_codes
  air: require("../assets/img/air-icon.png"),
  water: require("../assets/img/water-icon.png"),
  electricity: require("../assets/img/electricity-icon.png"),
  trh: require("../assets/img/temperature-icon.png"),
  digitalAgriculture: require("../assets/img/tractor.png"),
  covid: require("../assets/img/covid.png")
};

export const lineChartModes = {
  hourly: {
    title: "hourly",
    description: "Daily Average",
    multiplier: 1,
    format: time => moment(time).format("ha")
  },
  daily: {
    title: "daily",
    description: "Daily Average",
    multiplier: 24,
    format: time => moment(time).format("M/DD")
  }
};

export const comparisonModes = {
  average: {
    title: "avg"
  },
  maximum: {
    title: "max"
  },
  carbon_emission: {
    title: "carbon"
  },
  cost: {
    title: "cost"
  }
};

export const dateFormat = "YYYY-MM-DD";

//FUNCTIONS

export function getPrevDay(date) {
  let d = moment(date).toDate();
  d.setDate(d.getDate() - 1);
  return moment(d).format(dateFormat);
}

export function getNextDay(date) {
  let d = moment(date).toDate();
  d.setDate(d.getDate() + 1);
  return moment(d).format(dateFormat);
}

export function getTimeInterval(date1, date2) {
  let d1 = moment(date1).toDate();
  let d2 = moment(date2).toDate();
  let differenceInTime = d2.getTime() - d1.getTime();
  let interval = differenceInTime / (1000 * 3600 * 24);
  return Math.trunc(interval);
}

// [1,2,3,4,6,8] to 1-4, 6, 8
export function getDaysString(dates) {
  let ans = "";
  if (dates.length === 0) {
    return "/";
  }
  let pre = null;
  let curr = null;
  for (let i = 0; i < dates.length; i++) {
    if (pre === null) {
      pre = dates[i];
      curr = dates[i];
    } else if (dates[i] === curr + 1) {
      curr += 1;
    } else {
      if (pre === curr) {
        ans += (pre + 1).toString() + ", ";
      } else {
        ans += (pre + 1).toString() + "-" + (curr + 1).toString() + ", ";
      }
      pre = curr = dates[i];
    }
  }
  if (pre === curr) {
    ans += (pre + 1).toString();
  } else {
    ans += (pre + 1).toString() + "-" + (curr + 1).toString();
  }
  return ans;
}

// get n days prior to date
export function getPrevDays(date, n) {
  let d = moment(date).toDate();
  d.setDate(d.getDate() - n);
  return moment(d).format(dateFormat);
}

export function getPrevWeek(date) {
  let d = moment(date).toDate();
  d.setDate(d.getDate() - 7);
  return moment(d).format(dateFormat);
}

export function getPrevMonth(date) {
  let d = moment(date).toDate();
  d.setDate(d.getDate() - 31);
  return moment(d).format(dateFormat);
}

export function getQueryString(params) {
  var esc = encodeURIComponent;
  return (
    "?" +
    Object.keys(params)
      .map(k => esc(k) + "=" + esc(params[k]))
      .join("&")
  );
}

/*
  Parses the device ids that are attached to the users
  profile into a javascript object. Retrieves metadata
  for each object.
*/
export async function parseDeviceIds(id) {
  // get information about this the id attached to the account

  let ids = id.split(constants.separator);
  let result = [];

  for (let x = 0; x < ids.length; x++) {
    id = ids[x];
    let d = await fetch("/api/device_information" + getQueryString({ id: id }));
    let d2 = await d.json();
    result.push(d2[0]);
  }
  return result;
}

/*Return date objects for tomorrow, last week, and the first day of the month.*/
export function getNearbyDates() {
  let today_date = new Date();
  let today = moment(today_date).format(dateFormat);

  today_date.setDate(today_date.getDate() + 1);
  let tomorrow = moment(today_date).format(dateFormat);

  today_date.setDate(today_date.getDate() - 7);
  let lastWeek = moment(today_date).format(dateFormat);

  today_date = new Date();
  today_date.setDate(1);
  let firstDayOfMonth = moment(today_date).format(dateFormat);
  return { today, tomorrow, lastWeek, firstDayOfMonth };
}

//STYLES
export const pageStyles = {
  headerCard: {
    color: "black",
    minHeight: 100,
    backgroundColor: "#ffffff15",
    borderWidth: 0
  },
  bodyWrapper: {
    padding: 30,
    minWidth: 500
  },
  bodyContainer: {
    shadowOffset: { width: 10, height: 10 },
    shadowColor: "black",
    shadowOpacity: 1.0
  },
  graphContainerStyles: {
    color: "black",
    backgroundColor: "#ffffff10",
    borderWidth: 0
  },
  thumbnail: {
    borderColor: "#f6ff00",
    borderStyle: "solid",
    backgroundColor: "#ffffff10"
  },
  input: {
    backgroundColor: "#00000099",
    borderWidth: 0,
    color: "white",
    borderRadius: "25px"
  },
  modal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#333333",
    backgroundImage: `url(${lightbulb})`,
    backgroundPosition: "left bottom",
    backgroundRepeat: "no-repeat",
    color: "white",
    borderRadius: "25px"
  },
  editDeviceModal: {
    backgroundColor: "white",
    opacity: 1,
    borderRadius: "10px",
    borderWidth: "0px"
  },
  editAlertModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  singleDatasetStyles: [
    { fill: "origin" },
    {
      fill: "origin",
      pointRadius: 3,
      borderDash: [10, 5],
      borderColor: "#000000",
      backgroundColor: "#00000050",
      pointBorderColor: "#000000",
      pointHoverBackgroundColor: "#000000"
    }
  ],
  lineChartStyles: {
    legendLabelTextColor: "black",
    ticksColor: "black",
    gridLinesColor: "black",
    selectedColor: "#ffcc14",
    selectedColorText: "#000000",
    deselectedColor: "#cccccc",
    deselectedColorText: "white"
  }
};

export function getLineChartStyles() {
  return lineColors.map(color => getLineChartStyle(color));
}

export function getLineChartStyle(hexColor) {
  return {
    fill: "origin",
    lineTension: 0.3,
    backgroundColor: `${hexColor}50`,
    borderColor: hexColor,
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    pointBorderColor: hexColor,
    pointBackgroundColor: hexColor,
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: hexColor,
    pointHoverBorderColor: hexColor,
    pointHoverBorderWidth: 2,
    pointRadius: 2,
    pointHitRadius: 10,
    spanGaps: false
  };
}

export const lineColors = [
  "#ffcc14",
  "#b8b8b8",
  "#ff6857",
  "#8cd9ff",
  "#ffadf5",
  "#ffac26"
];

export const displayTime = 10;
export const updatingInterval = 15000;
