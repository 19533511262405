import moment from "moment";
import { deviceType } from "./constants";
import { getLatestTimeForDevice } from "../../resources/DataAccessors.js";
export default class DeviceInfo {
  constructor(
    name,
    id,
    description,
    data,
    type,
    lastSeen,
    dType = deviceType.drone
  ) {
    this.name = name || "No name available";
    this.isAdded = false;
    this.type = type;
    this.data = data;
    this.id = id;
    this.deviceType = dType;
    this.description = description === "undefined" ? "" : description;
    this.lastSeen = lastSeen;
  }

  async refreshDeviceStatus() {
    this.lastSeen =
      this.lastSeen || (await getLatestTimeForDevice(this.id, this.type));
  }

  statusText() {
    if (!this.lastSeen) {
      return "not seen in last 30 days";
    } else {
      return "last seen " + moment(this.lastSeen).fromNow();
    }
  }

  isOffline() {
    if (!this.lastSeen) return true;
    return (
      Math.abs(
        moment
          .duration(moment(this.lastSeen).diff(moment(new Date())))
          .asMinutes()
      ) > 30
    );
  }

  toString() {
    return `
            id: ${this.id},
            name: ${this.name},
            description: ${this.description},
            device type: ${this.type}
            last seen: ${this.lastSeen}
          `;
  }
}
