import React from "react";
import Status from "../../components/Status/Status.js";
import '../../assets/css/DigitalAgriculture.css'
import { Card,CardBody,CardTitle,CardHeader,ListGroup,ListGroupItem} from "reactstrap";
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Button from '@material-ui/core/Button';
import CloudIcon from '@material-ui/icons/Cloud';
import OpacityIcon from '@material-ui/icons/Opacity';
import EcoIcon from '@material-ui/icons/Eco';
import HomeIcon from '@material-ui/icons/Home';
import {Line} from 'react-chartjs-2';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Gauge from 'react-radial-gauge';
import AppBar from '@material-ui/core/AppBar';
import { pageStyles} from "../../resources/constants.js";
import { Modal, ModalBody } from "reactstrap";

import AddDigitalAgriculture from '../../components/SideMenu/AddDeviceByTypes/AddDigitalAgriculture.js';

//four line chart 
const Tempchart = {
  labels: ['12am', '1am', '2am','3am', '4am','5am','6am','7am','8am','9am','10am',
           '11am', '12pm'],
  datasets: [
    {
      label: ['°F'],
      fill: false,
      lineTension: 0.5,
      backgroundColor: 'rgba(75,192,192,1)',
      borderColor: 'rgba(0,0,0,1)',
      borderWidth: 2,
      data: [72,73,74,70,71,75,75,76,78,80,82,81,79]
    }
  ]
}

const Humdchart= {
  
    labels: ['12am', '1am', '2am','3am', '4am','5am','6am','7am','8am','9am','10am',
          '11am', '12pm'],
    datasets: [
      {
        label: '%',
        fill:false,
        lineTension: 0.5,
        backgroundColor: 'rgba(75,192,192,1)',
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 2,
        data: [50,59,60,65,68,70,75,80,85,78,72,66,56]
      }
    ]
  
}

const Moisturechart= {
  
  labels: ['12am', '1am', '2am','3am', '4am','5am','6am','7am','8am','9am','10am',
        '11am', '12pm'],
  datasets: [
    {
      label: '%',
      fill:false,
      lineTension: 0.5,
      backgroundColor: 'rgba(75,192,192,1)',
      borderColor: 'rgba(0,0,0,1)',
      borderWidth: 2,
      data: [30, 32, 33, 32, 31, 29, 30, 34, 36, 40, 37, 38, 39]
    }
  ]

}

const PARchart= {
  
  labels: ['12am', '1am', '2am','3am', '4am','5am','6am','7am','8am','9am','10am',
        '11am', '12pm'],
  datasets: [
    {
      label: 'PPFD',
      fill:false,
      lineTension: 0.5,
      backgroundColor: 'rgba(75,192,192,1)',
      borderColor: 'rgba(0,0,0,1)',
      borderWidth: 2,
      data: [70, 66, 68, 71, 73, 69, 74, 65, 60, 52, 47, 45, 40]
    }
  ]

}

function Clock(props){
  
    return (
    <div className="letterStyle">
      <p>Today is {props.date.toLocaleDateString()}</p>
    </div>

  )
}

export default class DigitalAgriculture extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value:1, 
      data: Tempchart, //line chart data
      backgroundTempColor:"gray",
      backgroundHumdColor:"gray",
      backgroundMoistureColor:"gray",
      backgroundPARColor:"gray",
      backgroundHouseColor:"gray",
      visible1:false, //if icon 1-5 is visible
      visible2:false,
      visible3:false,
      visible4:false,
      visible5:false,
      activesensor:"",
      xCoordTemp:500,
      yCoordTemp:250,
      xCoordHumd:550,
      yCoordHumd:250,
      xCoordMoisture:600,
      yCoordMoisture:250,
      xCoordPAR:650,
      yCoordPAR:250,
      xCoordHouse:700,
      yCoordHouse:250,
      click:false,
     
      isOpen: false, //Modal
      
      };
  }

  HandleClick=(event)=>{
    if (this.state.activesensor === "Temp") {
      this.setState({
        xCoordTemp: event.clientX-5,
        yCoordTemp: event.clientY+75,
      })

    }
    else if (this.state.activesensor === "Humd"){
      this.setState({
        xCoordHumd: event.clientX-5,
        yCoordHumd: event.clientY+75,
      })

    }
    else if (this.state.activesensor === "Moisture"){
      this.setState({
        xCoordMoisture: event.clientX-5,
        yCoordMoisture: event.clientY+75,
      })

    }
    else if (this.state.activesensor === "PAR"){
      this.setState({
        xCoordPAR: event.clientX-5,
        yCoordPAR: event.clientY+75,
      })

    }
    else if (this.state.activesensor === "House"){
      this.setState({
        xCoordHouse: event.clientX-5,
        yCoordHouse: event.clientY+75,
      })

    }

  }
      
  TabChange=(e, newValue)=>{
    this.setState({
      value: newValue
    })
    if (newValue === 1) {
      this.setState({
        data: Tempchart,
        
      })
    } else if (newValue  === 2) {
      this.setState({
        data: Humdchart,
        
      })
    } else if (newValue  === 3) {
      this.setState({
        data: Moisturechart,
        
      })
    } else if (newValue ===4){
      this.setState({
        data: PARchart,
        
      })
    }
  }
 

 //change icon button color when we click
  changeTempColor(){
    this.setState({
      backgroundTempColor:"#ffcc14",
      backgroundHumdColor:"gray",
      backgroundMoistureColor:"gray",
      backgroundPARColor:"gray",
      backgroundHouseColor:"gray",
      activesensor:"Temp",
      visible1:true
    });
  }
  
  changeHumdColor(){
    this.setState({
      backgroundHumdColor:"#ffcc14",
      backgroundTempColor:"gray",
      backgroundMoistureColor:"gray",
      backgroundPARColor:"gray",
      backgroundHouseColor:"gray",
      activesensor:"Humd",
      visible2:true,
      
    });
  }
  changeMoistureColor(){
    this.setState({
      backgroundMoistureColor:"#ffcc14",
      backgroundTempColor:"gray",
      backgroundHumdColor:"gray",
      backgroundPARColor:"gray",
      backgroundHouseColor:"gray",
      activesensor:"Moisture",
      visible3:true
    });
  }
  
  changePARColor(){
    this.setState({
      backgroundPARColor:"#ffcc14",
      backgroundTempColor:"gray",
      backgroundHumdColor:"gray",
      backgroundMoistureColor:"gray",
      backgroundHouseColor:"gray",
      activesensor:"PAR",
      visible4:true
    });
  }

  changeHouseColor(){
    this.setState({
      backgroundHouseColor:"#ffcc14",
      backgroundTempColor:"gray",
      backgroundHumdColor:"gray",
      backgroundMoistureColor:"gray",
      backgroundPARColor:"gray",
      activesensor:"House",
      visible5:true
    });
  }

  // set Modal visible
  setModal(e){
     this.setState({
       isOpen:true,
     });

  }
  getPage() {
    return <AddDigitalAgriculture />
  }
  
  getInitialState() {
    return { isOpen: false };
  }

  handleClose = async () => {
    await this.setState(this.getInitialState());
  };

  render() {
   
    return (
         
    <div className="digitalagriculture-container">  
        <div>
         {this.props.devices && this.props.devices.length > 0 && (
         <Status devices={this.props.devices} isSelectable={false} /> 
         )}
        
        <Card>
          <CardBody>
          <CardTitle><Clock date={new Date()}/></CardTitle>
          </CardBody>
        </Card>
        <div className="textCenter">
        <Card style={{width: '20rem'}} >

          <CardHeader tag='h5'>Temperature
          


          </CardHeader>
          <ListGroup variant="flush">
          <ListGroupItem align="start">Current: 80°F</ListGroupItem>
          <ListGroupItem align="start">Maximum:82°F</ListGroupItem>
          <ListGroupItem align="start">Average:81.5°F</ListGroupItem>
          </ListGroup>
        </Card>
        
        <Card style={{width: '20rem'}}>
          <CardHeader tag='h5'>Humidity</CardHeader>
          <ListGroup variant="flush">
          <ListGroupItem align="start">Current:60%</ListGroupItem>
          <ListGroupItem align="start">Maximum:70%</ListGroupItem>
          <ListGroupItem align="start">Average:65%</ListGroupItem>
          </ListGroup>
        </Card>
        
        <Card style={{ width: '20rem' }}>
          <CardHeader tag='h5'>Soil moisture</CardHeader>
          <ListGroup variant="flush">
          <ListGroupItem align="start">Current:30%</ListGroupItem>
          <ListGroupItem align="start">Maximum:35%</ListGroupItem>
          <ListGroupItem align="start">Average:33%</ListGroupItem>
          <ListGroupItem>  Radial Gauge for soil moisture
          <Gauge
             size={240}
             currentValue={30}
             dialWidth={10}
             progressWidth={5}
          />
          </ListGroupItem>
          </ListGroup>   
        </Card>
        
        
        <Card style={{ width: '20rem' }}>
          <CardHeader tag='h5'>PAR</CardHeader>
          <ListGroup variant="flush">
          <ListGroupItem align="start">Current:60%</ListGroupItem>
          <ListGroupItem align="start">Max:70%</ListGroupItem>
          <ListGroupItem align="start">Avg:65%</ListGroupItem>
          </ListGroup>
        </Card>
        
        </div>
        </div>
        
        <div>
          <Button variant="contained" color="primary"
          style={{ width: '40px', height: '40px', position: 'absolute', left: 435, top: 70}}
          onClick={this.setModal.bind(this)} >+device</Button>

          <Modal 
          isOpen={this.state.isOpen}
          toggle={this.handleClose}
          style={pageStyles.editDeviceModal}
          backdrop={"static"}
          keyboard={false}
          className="add-device-modal"
        >    
          <div className="add-device-header">
              Add Device
          </div>
          <ModalBody className="add-form-modal-body">
            {this.getPage()}
          </ModalBody>

          <div className="add-device-footer">
            <div
              className="add-device-close add-device-button"
              onClick={this.handleClose}
            >
              Close
            </div>
            </div>
        </Modal>
         
          <div className="blankrectangle" onMouseDown={this.HandleClick.bind(this)}
              
          >
              <h1 align='center'>Floorplan</h1>
              {this.state.visible1? <WbSunnyIcon style={{ width: '40px', height: '40px', position: 'absolute', left: this.state.xCoordTemp, top: this.state.yCoordTemp }}/>:null}
              {this.state.visible2? <CloudIcon style={{ width: '40px', height: '40px', position: 'absolute', left: this.state.xCoordHumd, top: this.state.yCoordHumd }}/>:null}
              {this.state.visible3? <OpacityIcon style={{ width: '40px', height: '40px', position: 'absolute', left: this.state.xCoordMoisture, top: this.state.yCoordMoisture }}/>:null}
              {this.state.visible4? <EcoIcon style={{ width: '40px', height: '40px', position: 'absolute', left: this.state.xCoordPAR, top: this.state.yCoordPAR }}/>:null}
              {this.state.visible5? <HomeIcon style={{ width: '40px', height: '40px', position: 'absolute', left: this.state.xCoordHouse, top: this.state.yCoordHouse }}/>:null}          
          </div>
          <div className="textfield">
             Click buttons to add icons on the floorplan!
          </div>
          <div className="buttonfield">
          
          <Button variant="contained" onClick={this.changeTempColor.bind(this)} 
          style={{backgroundColor:this.state.backgroundTempColor}}
          startIcon={<WbSunnyIcon/>}>Temp </Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        
          <Button variant="contained"
          onClick={this.changeHumdColor.bind(this)} 
          style={{backgroundColor:this.state.backgroundHumdColor}}
          startIcon={<CloudIcon/>}>Humd </Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          
          <Button variant="contained"
          onClick={this.changeMoistureColor.bind(this)} 
          style={{backgroundColor:this.state.backgroundMoistureColor}}
          startIcon={<OpacityIcon/>}>Moisture </Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          
          <Button variant="contained"
          onClick={this.changePARColor.bind(this)}
          style={{backgroundColor:this.state.backgroundPARColor}}
          startIcon={<EcoIcon/>}>PAR </Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          
          <Button variant="contained"
          onClick={this.changeHouseColor.bind(this,"true")} 
          style={{backgroundColor:this.state.backgroundHouseColor}}
          startIcon={<HomeIcon/>}>House </Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          
          </div>
          <div >
          
        </div>
        
        <div className="linechart">  
        <Line
          data={this.state.data}
          options={{
            title:{
              display:true,
              text:'Hourly statistics',
              fontSize:20,
              boxwidth:10,
            },
            legend:{
              display:true,
              position:'top',
              
            }
          }}
        />
        <AppBar position="static" color="default" >
              <Tabs
                value={this.state.value}
                onChange={this.TabChange}
                indicatorColor="primary"
                textColor="primary"
                centered
               
              >
                <Tab label="Temperature" value={1} />
                <Tab label="Humdity" value={2} />
                <Tab label="Soil Moisture" value={3} />
                <Tab label="PAR" value={4} />

              </Tabs>
        </AppBar>  
        </div>
      </div>
     </div> 
    );
  }
}
