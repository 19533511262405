import React from "react";
import { Row, Col } from 'reactstrap';
import { Paper, Button, Tooltip } from '@material-ui/core';
import {
    lineChartModes,
    getPrevDay,
    getTimeInterval,
    getNextDay,
    getNearbyDates,
    getPrevDays,
} from "../../resources/constants.js";
import { SingleComparator, AggregateComparator } from "../../components/Electricity/Comparator.js";
import { Line } from "react-chartjs-2";

const Divider = () => (
    <hr style={{ border: '0.3px solid rgb(150, 150, 150)', width: '100%' }} />
);

// Note that increasing here is bad (indicated by red), 
// while decreasing is good (indicated by green).
const IncTriangle = (() =>
    <svg height="15" width="20">
        <polygon points="8,0 16,15 0,15" fill="red" />
        Sorry, your browser does not support inline SVG.
    </svg>
)

const DecTriangle = (() =>
    <svg height="15" width="20">
        <polygon points="8,15 16,0 0,0" fill="green" />
        Sorry, your browser does not support inline SVG.
    </svg>
)

export default class Report extends React.Component {

    constructor(props) {
        super(props);
        let { today, lastWeek } = getNearbyDates();
        let yesterday = getPrevDay(today);
        let interval = getTimeInterval(lastWeek, today);
        let dayBeforeLastWeek = getPrevDay(lastWeek);

        this.state = {
            youvyouComparator: null,
            youvyouLineData: [],
            selectionNotFinished: false,
            interval: interval,

            //day tab selections
            currentSelectedDay: today,
            pastSelectedDay: yesterday,

            //range tab selections, default to the last 7 days
            currentSelectedDayRange: {
                startDate: lastWeek,
                endDate: today
            },
            pastSelectedDayRange: {
                // want 1 less to make start date inclusive
                startDate: getPrevDays(dayBeforeLastWeek, interval),
                endDate: dayBeforeLastWeek
            },

            youvothersComparator: null,
            youvothersStartDate: lastWeek,
            youvothersEndDate: today,
            youvothersData: null,
        }

        this.state.youvyouComparator = new SingleComparator(
            this.props.device,
            lineChartModes.daily,
            this.state.currentSelectedDayRange,
            this.state.pastSelectedDayRange
        );

        this.state.youvothersComparator = new AggregateComparator(
            this.props.device,
            lineChartModes.daily,
            { startDate: this.state.youvothersStartDate, endDate: this.state.youvothersEndDate }
        );
    }

    async componentDidMount() {
        await this.refreshData();
    }

    async initializeDevice() {
        this.state.youvyouComparator.setDevice(this.props.device);
        this.state.youvothersComparator.setDevice(this.props.device);
    }

    async componentDidUpdate(prevProps) {
        if (
            this.props.device &&
            (!prevProps.device || this.props.device.id !== prevProps.device.id)
        ) {
            await this.initializeDevice();
            await this.refreshData();
        }
    }

    refreshData = async () => {
        this.props.setIsLoading(true);

        let nowDateRange = {
            startDate: this.state.currentSelectedDayRange.startDate,
            endDate: getNextDay(this.state.currentSelectedDayRange.endDate)
        };

        let pastDateRange = {
            startDate: this.state.pastSelectedDayRange.startDate,
            endDate: getNextDay(this.state.pastSelectedDayRange.endDate)
        };

        //note: setting ranges in the comparator doesn't refresh the data

        this.state.youvyouComparator.setNowDateRange(nowDateRange);
        this.state.youvyouComparator.setPastDateRange(pastDateRange);

        this.state.youvothersComparator.setDateRange({
            startDate: this.state.youvothersStartDate,
            endDate: this.state.youvothersEndDate
        });

        //actually refresh the data and generate the comparison values

        try {
            await this.state.youvyouComparator.compareDevices();

            this.state.youvyouComparator.getNowData().setName("current"); //set the names for the graphs
            this.state.youvyouComparator.getPastData().setName("past");

            await this.state.youvothersComparator.compareDevices();
            this.state.youvothersComparator.getNowData().setName("your");
            this.state.youvothersComparator.getAggregateData().setName("other buildings");

            let nowData = this.state.youvothersComparator.getNowData();
            let aggregateData = this.state.youvothersComparator.getAggregateData();
            let data = [];
            if (nowData.getValues().length === 0) {
                data = [aggregateData];
            } else {
                data = [nowData, aggregateData];
            }

            //line data is given to the chart class to render the correct data
            await this.setState({
                lineData: [
                    this.state.youvyouComparator.getNowData(),
                    this.state.youvyouComparator.getPastData()
                ],
                youvothersData: data
            });
        } catch (e) {
            console.log("error occurred while comparing", e);
        }
        this.props.setIsLoading(false);
    };

    render() {
        const youvyouNowData = this.state.youvyouComparator.getNowData();
        const youvyouPastData = this.state.youvyouComparator.getPastData();
        const youvyouSmallData = {
            labels: ["1", "2", "3", "4", "5", "6", "7"],
            datasets: [
                {
                    label: "First dataset",
                    data: youvyouNowData != null ?
                        youvyouNowData.getValues().map((point, _) => (((point['power']) * 24 / 1000).toFixed(1))) :
                        [],
                    fill: true,
                    backgroundColor: "rgba(255, 204, 20, 0.2)",
                    borderColor: "rgba(255, 204, 20, 1)"
                },
                {
                    label: "Second dataset",
                    data: youvyouPastData != null ?
                        youvyouPastData.getValues().map((point, _) => (((point['power']) * 24 / 1000).toFixed(1))) :
                        [],
                    fill: true,
                    backgroundColor: "rgba(184, 184, 184, 0.2)",
                    borderColor: "rgba(184, 184, 184, 1)"
                }
            ]
        };

        const youvothersSmallData = {
            labels: ["1", "2", "3", "4", "5", "6"],
            datasets: [
                {
                    label: "First dataset",
                    data: this.state.youvothersData != null && this.state.youvothersData.length === 2 ?
                        this.state.youvothersData[0].getValues().map((point, _) => (((point['power']) * 24 / 1000).toFixed(1))) :
                        [],
                    fill: true,
                    backgroundColor: "rgba(255, 204, 20, 0.2)",
                    borderColor: "rgba(255, 204, 20, 1)"
                },
                {
                    label: "Second dataset",
                    data: this.state.youvothersData != null && this.state.youvothersData.length === 2 ?
                        this.state.youvothersData[1].getValues().map((point, _) => (((point['power']) * 24 / 1000).toFixed(1))) :
                        [],
                    fill: true,
                    backgroundColor: "rgba(184, 184, 184, 0.2)",
                    borderColor: "rgba(184, 184, 184, 1)"
                }
            ]
        };

        const options = (title) => ({
            legend: {
                display: false
            },
            tooltips: {
                enabled: false
            },
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    ticks: {
                        display: false
                    }
                }],
                yAxes: [{
                    ticks: {
                        display: false
                    }
                }]
            },
            title: {
                display: true,
                text: title
            }
        })

        return (
            <Col md style={{ width: '30%', paddingRight: '40px' }}>
                <Row style={{ paddingBottom: '25px' }}>
                    <Paper elevation={3} className="electricity-paper" style={{ height: 260 }} children={
                        <div>
                            <Row>
                                <div style={{ fontSize: '25px' }}>This Week</div>
                            </Row>
                            <Row><Divider /></Row>
                            <Row>
                                <Col style={{ width: '45%' }}>
                                    <Paper elevation={3} className="electricity-paper" style={{ height: 130 }} children={
                                        <div className="electricity-report-small-card-body">
                                            <Row>
                                                <div className="electricity-report-small-card-header">Average Energy</div>
                                            </Row>
                                            <Row>
                                                <div>{this.state.youvyouComparator.getAverageIncrease() <= 0 ? <DecTriangle /> : <IncTriangle />}{" "}</div>
                                                <div style={{
                                                    color: this.state.youvyouComparator.getAverageIncrease() <= 0 ? "green" : "red"
                                                }}>{Math.abs(this.state.youvyouComparator.getAverageIncrease())}{"%"}</div>
                                            </Row>
                                            <Row>
                                                <div>{(this.state.youvyouComparator.getAverageA() * lineChartModes.daily.multiplier).toFixed(1)}{" kWh/day"}</div>
                                            </Row>
                                        </div>
                                    } />
                                </Col>
                                <Col style={{ width: '45%' }}>
                                    <Paper elevation={3} className="electricity-paper" style={{ height: 130 }} children={
                                        <div className="electricity-report-small-card-body">
                                            <Row>
                                                <div className="electricity-report-small-card-header">Peak Power</div>
                                            </Row>
                                            <Row>
                                                <div>{this.state.youvyouComparator.getMaxIncrease() <= 0 ? <DecTriangle /> : <IncTriangle />}{" "}</div>
                                                <div style={{
                                                    color: this.state.youvyouComparator.getMaxIncrease() <= 0 ? "green" : "red"
                                                }}>{Math.abs(this.state.youvyouComparator.getMaxIncrease())}{"%"}</div>
                                            </Row>
                                            <Row>
                                                <div>{this.state.youvyouComparator.getMaxA()} kW</div>
                                            </Row>
                                        </div>
                                    } />
                                </Col>
                            </Row>
                        </div>
                    } />
                </Row>
                <Row>
                    <Paper elevation={3} className="electricity-paper" style={{ height: 345 }} children={
                        <div>
                            <Row>
                                <div style={{ fontSize: '25px' }}>Detailed Report</div>
                            </Row>
                            <Row><Divider /></Row>
                            <Row>
                                <Col>
                                    <Tooltip title={<div style={{ fontSize: '12px' }}>Your current and historical usage</div>} arrow>
                                        <Button
                                            size='large'
                                            variant='outlined'
                                            className="electricity-report-small-graph-container"
                                            onClick={() => this.props.changeTab(this.props.tabDict.youvsyou)}
                                        >
                                            <Line data={youvyouSmallData} options={options('You vs You')} />
                                        </Button>
                                    </Tooltip>
                                </Col>
                                <Col>
                                    <Tooltip title={<div style={{ fontSize: '12px' }}>Your usage compared to others</div>} arrow>
                                        <Button
                                            size='large'
                                            variant='outlined'
                                            className="electricity-report-small-graph-container"
                                            onClick={() => this.props.changeTab(this.props.tabDict.youvsothers)}
                                        >
                                            <Line data={youvothersSmallData} options={options('You vs Others')} />
                                        </Button>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row className='justify-content-md-center'>
                                <div style={{ paddingTop: '8px', fontSize: '15px' }}>Click the graphs to view details</div>
                            </Row>
                        </div>
                    } />
                </Row>
            </Col>
        )
    }
}