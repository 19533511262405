import React from 'react';
import { Segment, Icon } from 'semantic-ui-react';

export default props => {
    const [isExpand, setExpand] = React.useState(false);

    const toggleExpand = () => {
        setExpand(!isExpand);
    }

    return (
        <div className='control-segment'>
            <div className='title'  style={isExpand ? { backgroundColor: '#ffdb9e' } : { }} onClick={toggleExpand}>
                <Icon name={isExpand ? 'caret down' : 'caret right'} />
                <h5>{props.title}</h5>
            </div>
            <div className={`expandable ${isExpand ? 'expand' : ''}`}>
                <Segment className='content'>{props.children}</Segment>
            </div>
        </div>
    )
}