export const building2Data = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-160, 60],
            [-60, 60],
            [-55, 50],
            [-15, 50],
            [-10, 60],
            [70, 60],
            [70, 40],
            [80, 40],
            [80, 50],
            [190, 50],
            [190, -50],
            [80, -50],
            [80, -40],
            [70, -40],
            [70, -60],
            [-10, -60],
            [-15, -50],
            [-55, -50],
            [-60, -60],
            [-160, -60],
            [-160, -10],
            [-156, -10],
            [-156, -56],
            [-64, -56],
            [-59, -46],
            [-11, -46],
            [-6, -56],
            [66, -56],
            [66, -36],
            [84, -36],
            [84, -46],
            [186, -46],
            [186, 46],
            [84, 46],
            [84, 36],
            [66, 36],
            [66, 56],
            [-6, 56],
            [-11, 46],
            [-59, 46],
            [-64, 56],
            [-156, 56],
            [-156, 10],
            [-160, 10],
          ],
          [
            [70, 2],
            [70, 22],
            [66, 22],
            [66, -22],
            [70, -22],
            [70, -2],
            [128, -2],
            [128, -28],
            [128, -32],
            [132, -32],
            [132, -28],
            [132, -2],
            [162, -2],
            [162, 2],
            [132, 2],
            [132, 28],
            [132, 32],
            [128, 32],
            [128, 28],
            [128, 2],
          ],
          [
            [0, 2],
            [0, 36],
            [4, 36],
            [4, 2],
            [44, 2],
            [44, -2],
            [4, -2],
            [4, -36],
            [0, -36],
            [0, -2],
            [-76, -2],
            [-76, -36],
            [-80, -36],
            [-80, -2],
            [-136, -2],
            [-136, 2],
            [-80, 2],
            [-80, 36],
            [-76, 36],
            [-76, 2],
          ],
        ],
      },
    },
  ],
};

export const building2RoomsData = [
  [
    [-156, 56],
    [-156, 2],
    [-80, 2],
    [-80, 56],
  ],
  [
    [-156, -56],
    [-156, -2],
    [-80, -2],
    [-80, -56],
  ],
  [
    [0, 56],
    [0, 2],
    [-76, 2],
    [-76, 56],
    [-64, 56],
    [-58, 46],
    [-12, 46],
    [-6, 56],
  ],
  [
    [0, -56],
    [0, -2],
    [-76, -2],
    [-76, -56],
    [-64, -56],
    [-58, -46],
    [-12, -46],
    [-6, -56],
  ],
  [
    [4, 56],
    [4, 2],
    [66, 2],
    [66, 56],
  ],
  [
    [4, -56],
    [4, -2],
    [66, -2],
    [66, -56],
  ],
  [
    [84, 46],
    [84, 36],
    [70, 36],
    [70, 2],
    [128, 2],
    [128, 46],
  ],
  [
    [84, -46],
    [84, -36],
    [70, -36],
    [70, -2],
    [128, -2],
    [128, -46],
  ],
  [
    [132, 46],
    [132, 2],
    [186, 2],
    [186, 46],
  ],
  [
    [132, -46],
    [132, -2],
    [186, -2],
    [186, -46],
  ],
];
