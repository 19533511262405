import React from "react";
import { landingFields } from "../../resources/constants";
// Menu Items
export default () => {
  return (
    <section className="bg-light page-section" id="mission">
      <div className="container">
        <div className="col-lg-12 text-center">
          <h2 className="section-heading text-uppercase">Mission Statement</h2>
          <h3 className="section-subheading text-muted">
            {landingFields.mission_statement.subtitle}
          </h3>
          <p>{landingFields.mission_statement.body}</p>
        </div>
      </div>
    </section>
  );
};
