import React from "react";

import YouVsOthers from "../../components/Electricity/YouVsOthers.js";
import YouVsYou from "../../components/Electricity/YouVsYou.js";

import Status from "../../components/Status/Status.js";
import Goal from "../../components/Electricity/Goal.js";
import Report from "../../components/Electricity/ElectricityReport.js";
import { getSurveyAnswersByDeviceId, getSurveyQuestions } from "../../resources/DataAccessors.js";

import { Container, Row, Col } from 'reactstrap';
import { Paper } from '@material-ui/core';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { electricityTips } from '../../resources/constants.js';
import { Tab, Menu, Icon, Dimmer, Loader, Message } from 'semantic-ui-react';

const Divider = () => (
  <hr style={{ border: '0.3px solid rgb(150, 150, 150)', width: '100%' }} />
);

const tabDict = {
  home: 0,
  youvsyou: 1,
  youvsothers: 2,
}

export default class Electricity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDevice: null,
      deviceSurveyAnswers: null,
      deviceSurveyQuestions: null,

      // boolean indicating whether the left side of the dashboard is loading
      isLoading: false,
      // boolean indicating whether the goal panel is loading
      isGoalPanelLoading: false,
      // boolean indicating whether the youvsyou graph is loading
      isYouVsYouLoading: false,
      // boolean indication whether the youvsothers graph is loading
      isYouVsOthersLoading: false,
      // index controlling which tab the page is on
      tabIndex: 0,
    };
  }

  /**
   * When the page first loads, query the survey questions and
   * survey answers given the list of electricity devices.
   */
  async componentDidMount() {
    if (this.props.devices && this.props.devices.length > 0) {
      const surveyAnswers = await this.getSurveyAnswers();
      const surveyQuestionsBundle = await getSurveyQuestions();
      const surveyQuestions = {}
      surveyQuestionsBundle.forEach(elt => {
        surveyQuestions[elt.question_id] = elt
      })
      await this.setState({
        selectedDevice: this.props.devices[0],
        deviceSurveyAnswers: surveyAnswers,
        deviceSurveyQuestions: surveyQuestions,
      });
    }
  }

  /**
   * If new devices are added, refresh the survey answers.
   */
  async componentDidUpdate(prevProps) {
    if (this.props.devices) {
      if (!prevProps.devices
        || (prevProps.devices.length !== this.props.devices.length)) {
        const surveyAnswers = await this.getSurveyAnswers();
        this.setState({ deviceSurveyAnswers: surveyAnswers })
      }
    }
    else if (!prevProps.devices) {
      this.setState({ deviceSurveyAnswers: null });
    }
  }

  /**
   * Get survey answers.
   * @returns a dictionary mapping from device id to a json representing
   * the survey answer
   */
  getSurveyAnswers = async () => {
    if (!this.props.devices || this.props.devices.length === 0) {
      return null;
    }
    const surveyAnswers = {};
    for (var i in this.props.devices) {
      const res = await getSurveyAnswersByDeviceId(this.props.devices[i].id);
      surveyAnswers[this.props.devices[i].id] = res.survey_answers;
    }
    return surveyAnswers;
  }

  /**
   * Refresh survey answers.
   * @param {Function} callback is the callback function after the survey answers
   * have been updated.
   */
  refreshSurveyAnswers = async (callback) => {
    const surveyAnswers = await this.getSurveyAnswers();
    this.setState({ deviceSurveyAnswers: surveyAnswers }, callback);
  }

  setIsYouVsYouLoading = async isLoading => {
    await this.setState({ isYouVsYouLoading: isLoading });
  };

  setIsYouVsOthersLoading = async isLoading => {
    await this.setState({ isYouVsOthersLoading: isLoading });
  };

  setIsLoading = async isLoading => {
    this.setState({ isLoading: isLoading });
  }

  setIsGoalPanelLoading = async isLoading => {
    this.setState({ isGoalPanelLoading: isLoading });
  }

  changeTab = async tabIndex => {
    await this.setState({ tabIndex });
  }

  /*
  Given a list of electrical devices, display an overview of all device data
  */
  render() {
    const panes = [{
      menuItem: (
        <Menu.Item key='home' style={{ fontSize: '18px', backgroundColor: '#fff7b0' }}>
          <Icon name='home' />
          Home
        </Menu.Item>
      ), render: () => this.state.selectedDevice && (
        <Container style={{ paddingTop: '20px' }}>
          <Row className="justify-content-md-center">
            <Report
              device={this.state.selectedDevice}
              changeTab={this.changeTab}
              tabDict={tabDict}
              setIsLoading={this.setIsLoading}
            />
            <Col lg={{ span: 7 }} style={{ width: '60%' }}>
              <Row style={{ paddingBottom: '25px' }}>
                <Paper elevation={3} className="electricity-paper" style={{ height: 380 }} children={
                  <div>
                    <Row>
                      <div style={{ fontSize: '25px' }}>Your Goal</div>
                    </Row>
                    <Row><Divider /></Row>
                    <Row><Goal
                      device={this.state.selectedDevice}
                      setIsLoading={this.setIsGoalPanelLoading} /></Row>
                  </div>
                } />
              </Row>
              <Row>
                <Paper elevation={3} className="electricity-paper" style={{ height: 225 }} children={
                  <div>
                    <Row>
                      <div style={{ fontSize: '25px' }}>Tips on Saving Electricity</div>
                    </Row>
                    <Row><Divider /></Row>
                    <Row className='justify-content-md-center' style={{ backgroundColor: '#fff7b0', height: '90px' }}>
                      <Carousel autoPlay={true} infiniteLoop={true} width={600} showStatus={false} >
                        {
                          electricityTips.tips.map((tip, index) =>
                            <div key={index} style={{ fontSize: '18px', padding: '10px' }}>
                              {tip}
                            </div>)
                        }
                      </Carousel>
                    </Row>
                  </div>
                } />
              </Row>
            </Col>
          </Row>
        </Container>
      )
    },
    {
      menuItem: (<Menu.Item key='youvsyou' style={{ fontSize: '18px' }}>
        <Icon name='line graph' />
        YouVsYou
      </Menu.Item>), render: () => this.state.selectedDevice && (
        <YouVsYou
          device={this.state.selectedDevice}
          setIsLoading={this.setIsYouVsYouLoading}
          isLoading={this.state.isYouVsYouLoading}
        />
      )
    },
    {
      menuItem: (<Menu.Item key='youvsothers' style={{ fontSize: '18px' }}>
        <Icon name='line graph' />
        YouVsOthers
      </Menu.Item>), render: () => this.state.selectedDevice && (
        <YouVsOthers
          device={this.state.selectedDevice}
          setIsLoading={this.setIsYouVsOthersLoading}
          isLoading={this.state.isYouVsOthersLoading}
        />
      )
    }];

    const deviceSurveyAns = this.state.deviceSurveyAnswers != null ? this.state.deviceSurveyAnswers[this.state.selectedDevice.id] : null;

    return (
      <div className="electricity-container">
        {this.props.devices && this.props.devices.length > 0 && (
          <Status
            isSelectable={true}
            isEnabled={
              !this.state.isYouVsOthersLoading && !this.state.isYouVsYouLoading
            }
            devices={this.props.devices}
            selectedDevice={this.state.selectedDevice}
            onClick={device => this.setState({ selectedDevice: device })}
            surveyAnswers={this.state.deviceSurveyAnswers}
            surveyQuestions={this.state.deviceSurveyQuestions}
            refreshSurveyAnswers={this.refreshSurveyAnswers}
          />
        )}
        <Dimmer
          active={this.state.isLoading
            || this.state.isGoalPanelLoading
            || this.state.isYouVsYouLoading
            || this.state.isYouVsOthersLoading}
          page
          style={{ backgroundColor: 'rgba(90, 90, 90, 0.7)' }}
        >
          <Loader
            size="massive"
            disabled={!this.state.isLoading
              && !this.state.isGoalPanelLoading
              && !this.state.isYouVsYouLoading
              && !this.state.isYouVsOthersLoading}>Loading</Loader>
        </Dimmer>
        {(deviceSurveyAns == null || deviceSurveyAns["1"] === "") && <Message negative>
          <Message.Header>
            <Icon name="warning sign" />
            You have not filled out the floor area of your building.
          </Message.Header>
          <p>Please edit the survey responses for this device ASAP. This will help us provide you with a more meaningful and accurate
            comparison to the electricity usage of other buildings.</p>
        </Message>}
        <Tab
          panes={panes}
          activeIndex={this.state.tabIndex}
          onTabChange={(_, target) => this.setState({ tabIndex: target.activeIndex })}
          style={{ paddingTop: '30px' }}
        />
      </div >
    );
  }
}
