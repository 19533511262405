import React from "react";

import { FormGroup, Input } from "reactstrap";
//import client functions that help me insert data into the db
import {
  getDeviceIdAndTypeById,
  addDevice,
} from "../../../resources/DataAccessors";


/*
To add an additional form for a device....

add the device code to the list this._availableAdditionalForms

add the submit function to submitAdditionalForm in the switch statement
under the device code you are adding
*/

export default class AddDevice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tempDescription: "",
      tempName: "",
      tempId: "",
    };
  }

  handleChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  };

  async checkDeviceValidity() {
    let tempDeviceInformation = await getDeviceIdAndTypeById(this.state.tempId);
    if (!tempDeviceInformation) {
      alert(
        "There is no device with this id. Check if the id was typed correctly."
      );
      return false;
    }
    // check if device id exists for the current user
    if (this.props.hasDeviceBeenRegistered(tempDeviceInformation.device_id)) {
      alert("You have already registered this device.");
      return false;
    }
    return tempDeviceInformation;
  }

  onContinue = async () => {
    //only continue if the device already exists
    let deviceInformation = await this.checkDeviceValidity();
    if (!deviceInformation) return;

    console.log(deviceInformation);

    await this.addDevice(deviceInformation.device_id);
  };


  /**
     Add a new device

     Passes in the hardware id for the device to be added
   */
  addDevice = async (deviceId) => {
    //update db to reflect changes
    await addDevice(
      this.state.tempId,
      this.state.tempName,
      this.state.tempDescription
    );

    this.props.onAddDevice();
    this.props.onComplete(deviceId);
  };

  renderDefaultContent() {
    return (
      <>
        <FormGroup>
          <Input
            onChange={this.handleChange}
            id="tempId"
            className="add-form-input"
            placeholder="Device ID"
            value={this.state.tempId}
          />
        </FormGroup>
        <FormGroup>
          <Input
            onChange={this.handleChange}
            id="tempName"
            className="add-form-input"
            placeholder="Device Name"
            value={this.state.tempName}
          />
        </FormGroup>
        <FormGroup>
          <Input
            onChange={this.handleChange}
            id="tempDescription"
            className="add-form-input"
            placeholder="Device Description"
            value={this.state.tempDescription}
          />
        </FormGroup>
        <div
          className="add-device-continue add-device-button"
          onClick={this.onContinue}
        >
          Add
        </div>
      </>
    );
  }

  render() {
    return this.renderDefaultContent();
  }
}
