import React, { Component } from "react"
import Chart from "chart.js";
import "chartjs-plugin-error-bars"
import { pageStyles } from "../../resources/constants.js";

class AltitudeChart extends Component {
  constructor(props) {
    super(props)
    this.canvasRef = React.createRef()
  }

  componentDidMount() {
    this.plotChart();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.plotChart();
    }
  }

  render() {
    return <canvas ref={this.canvasRef} height="200"/>
  }

  plotChart() {

    const canvas = this.canvasRef.current

    var midPM = [];
    var altitudeLabels = [];
    var errorBarPM = {};
    var max_graph = 0;

    if (!this.props.data || this.props.data.columns == null) {
      midPM = [];
      altitudeLabels = [];
      errorBarPM = {};
    }
    else {
      var curData = this.props.data;
      var alt_values = curData.values;
      let indexAlt = curData.columns.findIndex(
        field => field === "altitude"
      );
      let indexPM25 = curData.columns.findIndex(
        field => field === "pm25data"
      );

      var max_alt = 0;
      for (let i = 0; i < alt_values.length; i++) {
        max_alt = Math.max(max_alt, Math.floor(alt_values[i][indexAlt]));
      }
      var numBuckets = Math.max(max_alt + 1, 10);

      var alt_buckets = [...Array(numBuckets).keys()].map(x => []);

      for (let i = 0; i < alt_values.length; i++) {
        if (isNaN(alt_values[i][indexAlt])) {
          continue;
        }
        var ind = Math.floor(alt_values[i][indexAlt]);
        if (ind < 0) {
          ind = 0
        }
        alt_buckets[ind].push(alt_values[i][indexPM25])
      }
      alt_buckets.forEach(x => x.sort((a, b) => a - b));

      altitudeLabels = [...Array(numBuckets).keys()];
      midPM = [];
      for (let i = 0; i < numBuckets; i++) {
        if (alt_buckets[i].length === 0) {
          midPM.push(0);
          errorBarPM[i.toString()] = {plus: 0, minus: 0};
          continue;
        }
        var mid = alt_buckets[i][Math.floor(((alt_buckets[i]).length)/2)];
        midPM.push(mid);
        var end_ind = alt_buckets[i].length - 1;
        var max_val = alt_buckets[i][end_ind];
        for (let j = end_ind; j > 0; j--) {
          if (alt_buckets[i][j] < 500) {
            max_val = alt_buckets[i][j];
            break;
          }
        }
        if (max_val > max_graph) {
          max_graph = max_val;
        }
        var add = max_val - mid;
        var sub = alt_buckets[i][0] - mid;
        errorBarPM[i.toString()] = {plus: add, minus: sub};
      }
    }

    new Chart(canvas, {
      type: "line",
      data: {
        datasets: [
          {
            data: midPM,
            borderColor: "#666",
            errorBars: errorBarPM,
            label: "pm 25",
            ...pageStyles.lineChartLinesStyles
          },
        ],
        labels: altitudeLabels,
      },
      options: {
        legend: {
          display: false,
        },
        title: {
            display: true,
            text: 'Altitude vs PM2.5'
        },
        responsive: true,
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: pageStyles.lineChartStyles.ticksColor,
                suggestedMin: 0,
                suggestedMax: max_graph
              },
              scaleLabel: {
                display: true,
                padding: 1,
                labelString: "pm 2.5 (ug/m^3)"
              },
              gridLines: {
                display: true,
                color: pageStyles.lineChartStyles.gridLinesColor
              }
            }
          ],
          xAxes: [
            {
              ticks: {
                autoSkipPadding: 15,
                maxRotation: 0,
                fontColor: pageStyles.lineChartStyles.ticksColor
              },
              scaleLabel: {
                display: true,
                padding: 1,
                labelString: "Altitude (m)"
              },
              gridLines: {
                display: true,
                color: pageStyles.lineChartStyles.gridLinesColor
              }
            }
          ],
        },
        plugins: {
          chartJsPluginErrorBars: {
            color: "red",
            lineWidth: 2
          }
        }
      }
    })
  }
}

export default AltitudeChart
