import React from "react";
import { Segment, Divider } from "semantic-ui-react";
import DeviceInfo from "./DeviceInfo";
import AlertInfo from "./AlertInfo";
import AlertHistory from "./AlertHistory";

export default function InfoCard({ selectedDevice, alertData }) {
  let noDevice = new DeviceInfo(
    "No Device Selected",
    "0",
    null,
    null,
    null,
    null
  );

  if (!selectedDevice) {
    selectedDevice = noDevice;
  }

  let displayData = (data, alertData) => {
    if (data) {
      return (
        <div>
          <p>Status: online</p>
          <p>PM 2.5: {data.pm25 === null ? "N/A" : data.pm25}</p>
          <p>Relative humidity: {data.rh === null ? "N/A" : data.rh}</p>
          <p>Temperature: {data.t === null ? "N/A" : data.t}</p>
          <p>Active alerts: {alertData === null ? 0 : alertData.length} </p>
        </div>
      );
    } else {
      return (
        <div>
          <p>Status: offline</p>
          <p>Active alerts: {alertData === null ? 0 : alertData.length}</p>
        </div>
      );
    }
  };

  return (
    <Segment id="map-control">
      <div>
        <div className="ui header">
          <span>
            <strong>{selectedDevice.name}</strong>
          </span>
        </div>
        <div>
          <p>{selectedDevice.description}</p>
        </div>
        <Divider />
        {displayData(selectedDevice.data, alertData)}
        <br />
        <div style={{ display: "inline-flex" }}>
          <AlertInfo device={selectedDevice} />
          <AlertHistory device={selectedDevice} />
        </div>
      </div>
    </Segment>
  );
}
