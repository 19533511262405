import React from "react";

import {
  devices_codes,
  deviceTypesNames,
  IMAGES,
} from "../../resources/constants.js";

import AddDevice from "../../components/SideMenu/AddDeviceNew";

/*
Props:
updateIoTType
updateDevices
*/
export default class SideMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = { addButtonOpen: false };
  }

  renderButtons() {
    let buttons = [];

    //list of codes: e.g. electricity, air, water
    let codes = Object.values(devices_codes);
    for (let x = 0; x < codes.length; x++) {
      let code = codes[x];

      //if the type is selected, add the selected styles
      let styles = "sidemenu-button-container";
      if (this.props.selectedIoTType === code) {
        styles += " sidemenu-button-container-selected-" + code;
      }

      if (x === 0) {
        //round the first button in the list
        styles += " sidemenu-button-container-top";
      } else if (x === codes.length - 1) {
        //round the last button in the list
        styles += " sidemenu-button-container-bottom";
      }

      buttons.push(
        <div
          className={styles}
          key={code}
          onClick={() => this.props.updateIoTType(code)}
        >
          <img
            className="sidemenu-button-image"
            alt={code}
            src={IMAGES[code]}
          ></img>
          <div className="sidemenu-button-label">{deviceTypesNames[code]}</div>
        </div>
      );
    }

    return buttons;
  }

  hasDeviceBeenRegistered(deviceId) {
    return this.props.deviceIds.filter((d) => d.id === deviceId).length > 0;
  }

  renderAddButton() {
    return (
      <AddDevice
        hasDeviceBeenRegistered={(d) => this.hasDeviceBeenRegistered(d)}
        onAddDevice={this.props.updateDevices}
        openAddDeviceModal={this.props.openAddDeviceModal}
        setAddDeviceModalTo={this.props.setAddDeviceModalTo}
        setAddDeviceModalInfo={this.props.setAddDeviceModalInfo}
      />
    );
  }

  render() {
    return (
      <div className="sidemenu-container">
        <div className="sidemenu-container-iot-buttons">
          {this.renderButtons()}
        </div>
        <div className="sidemenu-add-button-container">
          {this.renderAddButton()}
        </div>
      </div>
    );
  }
}
