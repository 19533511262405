import { Tab, Modal, Segment, Grid, Header } from "semantic-ui-react";
import React from "react";
import _ from "lodash";

import SimpleLineChart from "../../components/Charts/SimpleLineChart";
import moment from "moment";

const modalChart = {
  pm25: "pm25data",
  rh: "rh",
  temp: "t"
};

export default class Charts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalChartSize: 10,
      modalOpen: false,
      modal: modalChart.pm25
    };
  }

  pm25Chart = size => {
    return (
      <SimpleLineChart
        className="simple-line-chart"
        formatXAxis={value => moment(value).format("h:mm:ss")}
        formatYAxis={value => value}
        formatter={value => value}
        data={this.props.data}
        fieldsX={["time"]}
        fieldsY={["pm25data"]}
        datasetLabels={["pm 25"]}
        xAxisLabel="Time (hh:mm:ss)"
        yAxisLabel="pm 2.5 (ug/m^3)"
        // size={size}
        alertData={this.props.alertData}
      />
    );
  };

  rhChart = size => {
    return (
      <SimpleLineChart
        formatXAxis={value => moment(value).format("h:mm:ss")}
        formatYAxis={value => value}
        formatter={value => value}
        data={this.props.data}
        fieldsX={["time"]}
        fieldsY={["rh"]}
        datasetLabels={["rh"]}
        // size={size}
        xAxisLabel="Time (hh:mm:ss)"
        yAxisLabel="Relative Humidity"
      />
    );
  };

  tempChart = size => {
    return (
      <SimpleLineChart
        formatXAxis={value => moment(value).format("h:mm:ss")}
        formatYAxis={value => value}
        formatter={value => value}
        data={this.props.data}
        fieldsX={["time"]}
        fieldsY={["t"]}
        datasetLabels={["temp"]}
        // size={size}
        xAxisLabel="Time (hh:mm:ss)"
        yAxisLabel="Temperature (Celsius)"
      />
    );
  };

  onModalClose = () => {
    this.setState({ modalOpen: false });
  };

  renderModal = () => {
    var modalTitle = null;
    var modalToShow = null;
    switch (this.state.modal) {
      case modalChart.pm25:
        modalTitle = "PM 2.5 Monitoring";
        modalToShow = this.pm25Chart(this.state.modalChartSize);
        break;
      case modalChart.rh:
        modalTitle = "Relative Humidity Monitoring";
        modalToShow = this.rhChart(this.state.modalChartSize);
        break;
      case modalChart.temp:
        modalTitle = "Temperature Monitoring";
        modalToShow = this.tempChart(this.state.modalChartSize);
        break;
      default:
        modalTitle = "PM 2.5 Monitoring";
        modalToShow = this.pm25Chart(this.state.modalChartSize);
        break;
    }
    return (
      <Modal
        open={this.state.modalOpen}
        onClose={this.onModalClose}
        style={{
          height: "auto",
          top: "auto",
          left: "auto",
          bottom: "auto",
          right: "auto"
        }}
      >
        <Modal.Header>
          {modalTitle === null ? "Content not available" : modalTitle}
        </Modal.Header>
        <Modal.Content>
          {modalToShow}
          {this.renderSingleEntrySummary(
            this.getDataIndexByName(this.state.modal)
          )}
        </Modal.Content>
      </Modal>
    );
  };

  getDataIndexByName = name => {
    if (
      !this.props.data ||
      !this.props.data.columns ||
      this.props.data.length === 0
    ) {
      return null;
    }

    var ans = -1;
    for (var i = 0; i < this.props.data.columns.length; i++) {
      if (this.props.data.columns[i] === name) {
        ans = i;
      }
    }
    if (ans === -1) {
      return null;
    } else {
      return ans;
    }
  };

  computeSingleEntryAverage = index => {
    let ans = 0;
    const dataArr = this.props.data.values;
    for (var i = 0; i < dataArr.length; i++) {
      ans += dataArr[i][index];
    }
    return ans / dataArr.length;
  };

  computeSingleEntryPeak = index => {
    var ans = -100;
    const dataArr = this.props.data.values;
    for (var i = 0; i < dataArr.length; i++) {
      ans = Math.max(ans, dataArr[i][index]);
    }
    return ans;
  };

  renderSingleEntrySummary = index => {
    if (
      !this.props.data ||
      !this.props.data.values ||
      !Array.isArray(this.props.data.values) ||
      this.props.data.values.length > index
    ) {
      return null;
    }

    if (index === null || !_.isNumber(this.props.data.values[0][index]))
      return null;
    const average = this.computeSingleEntryAverage(index);
    const current = this.props.data.values[this.props.data.values.length - 1][
      index
    ];
    const peak = this.computeSingleEntryPeak(index);
    return (
      <Segment>
        <Grid columns={3} stackable textAlign="center">
          <Grid.Row verticalAlign="middle">
            <Grid.Column>
              <Header>CURRENT</Header>
              {current}
            </Grid.Column>
            <Grid.Column>
              <Header>PEAK</Header>
              {peak}
            </Grid.Column>
            <Grid.Column>
              <Header>AVERAGE</Header>
              {average}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  };

  showModal = modal => {
    this.setState({
      modalOpen: true,
      modal: modal
    });
  };

  generatePanes = data => {
    if (!data || !data.values) {
      return [
        {
          menuItem: "PM 2.5",
          render: () => <Tab.Pane attached={false}></Tab.Pane>
        },
        {
          menuItem: "Rel Humidity",
          render: () => <Tab.Pane attached={false}></Tab.Pane>
        },
        {
          menuItem: "Temp",
          render: () => <Tab.Pane attached={false}></Tab.Pane>
        }
      ];
    }
    return [
      {
        menuItem: "PM 2.5",
        render: () => (
          <Tab.Pane
            attached={false}
            onClick={() => {
              this.showModal(modalChart.pm25);
            }}
          >
            {this.pm25Chart()}
          </Tab.Pane>
        )
      },
      {
        menuItem: "Rel Humidity",
        render: () => (
          <Tab.Pane
            attached={false}
            onClick={() => {
              this.showModal(modalChart.rh);
            }}
          >
            {this.rhChart()}
          </Tab.Pane>
        )
      },
      {
        menuItem: "Temp",
        render: () => (
          <Tab.Pane
            attached={false}
            onClick={() => {
              this.showModal(modalChart.temp);
            }}
          >
            {this.tempChart()}
          </Tab.Pane>
        )
      }
    ];
  };

  render() {
    return (
      <div>
        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={this.generatePanes(this.props.data)}
        />
        {this.renderModal()}
      </div>
    );
  }
}
