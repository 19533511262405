import React from "react";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import moment from "moment";

/*
Used to select a single date as end date,
and will highlight the date range according to number of days selected in current period
*/
export default class PastCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.state = {
      selectedDay: moment(props.to).toDate(),
      from: moment(props.from).toDate(),
      modifiers: this.props.modifiers || null
    };
  }

  handleDayClick(day, { disabled }) {
    if (disabled) {
      window.alert("Select a day prior to your first selection!");
      return;
    }
    this.props.onSelect(day);
  }

  render() {
    let disabledDays = this.props.disabledDays;
    let from = moment(this.props.from).toDate();
    let to = moment(this.props.to).toDate();

    const selectedDays = [from, { from, to: to }];
    const modifiers = { start: from, end: to };
    return (
      <div>
        <div>{!to && "Please select last day of compared period"}</div>
        <DayPicker
          className="Range"
          selectedDays={selectedDays}
          onDayClick={this.handleDayClick}
          disabledDays={disabledDays}
          modifiers={modifiers}
          month={from}
        />
      </div>
    );
  }
}
