import { lineChartModes } from "../../resources/constants.js";

import {
  getDailyElectricityData,
  getHourlyElectricityData,
  getDailyAggregateData,
  getHourlyAggregateData
} from "../../resources/DataAccessors.js";

import { aggregateDevice } from "./utils.js";

export class ElectricityHandler {
  constructor(dateRange, device, mode) {
    this.data = null;
    this.mode = mode;
    this.average = 0;
    this.max = 0;
    this.timeAtMax = null;
    this.dateRange = dateRange;
    this.device = device;
    this.carbonEmissions = -1;
    this.electricityCost = -1;
  }

  toString() {
    return `mode: ${this.mode}
            average: ${this.average}
            max: ${this.max}
            timeAtMax: ${this.timeAtMax}
            dateRange: ${this.dateRange}
            device: ${this.device}`;
  }

  async load() {
    let { startDate, endDate } = this.dateRange;

    //check if this device is an aggregate placeholder
    let hourlyFunction = getHourlyElectricityData;
    let dailyFunction = getDailyElectricityData;

    if (this.device.type === aggregateDevice) {
      hourlyFunction = getHourlyAggregateData;
      dailyFunction = getDailyAggregateData;
    }

    switch (this.mode) {
      case lineChartModes.hourly:
        this.data = await hourlyFunction(startDate, endDate, this.device.id);
        break;
      case lineChartModes.daily:
        this.data = await dailyFunction(startDate, endDate, this.device.id);
        break;
      default:
        console.error("invalid mode for electricity data", this.mode);
    }

    //now update stats based on new data
    this._calculate();
  }

  _calculate() {
    let values = this.data.getValues(); // list of Data objects from ElectricityData.js
    let { max, date } = this.data.getMaxPowerInformation();

    if (values.length === 0) {
      this.average = 0;
      this.max = 0;
      this.timeAtMax = undefined;
    }

    let averagePower = 0;
    for (const point of values) {
      averagePower += point.power;
    }

    this.average = averagePower / values.length;
    this.max = max;
    this.timeAtMax = date;
  }
}
