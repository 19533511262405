import React from "react";
import { Button, Modal, Icon } from 'semantic-ui-react'
import { Container, Row, Col } from 'reactstrap';
import GoalPanel from "../../components/Electricity/GoalPanel.js";
import {
  addGoalToAccountAndDevice,
  updateGoalPercentage,
  getGoalByAccountIdAndDeviceId,
} from "../../resources/DataAccessors.js";

export default class Goal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      goal: "Let's set a goal!",
      modalInput: "",
      goalPercentage: -1,
      goalModalInput: -1,
      isGoalPanelLoading: false
    };
  }

  async componentDidMount() {
    this.refreshGoal();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.device == null || this.props.device == null) {
      return;
    }
    if (prevProps.device.id !== this.props.device.id) {
      this.refreshGoal();
    }
  }

  refreshGoal = async () => {
    const goal = await getGoalByAccountIdAndDeviceId(this.props.device.id);
    if (typeof (goal) === "number") {
      this.setState({
        goalPercentage: goal,
      });
      if (goal !== 0) {
        this.setState({
          goal: "Your goal is to decrease energy usage by " + goal + "%.",
        })
      }
      else {
        this.setState({
          goal: "Your goal is to maintain current energy usage."
        })
      }
    }
  }

  handleChange(e, value, goalPercentage) {
    const target = e.target;
    target.active = true;

    this.setState({
      modalInput: value,
      goalModalInput: goalPercentage
    });
  }

  async handleSubmit(e) {
    if (this.state.goalPercentage === -1) {
      await addGoalToAccountAndDevice(this.props.device.id, this.state.goalModalInput);
    }
    else {
      await updateGoalPercentage(this.props.device.id, this.state.goalModalInput);
    }

    this.setState({
      goal: this.state.modalInput,
      goalPercentage: this.state.goalModalInput

    });

    this.modalClose();
  }

  modalOpen() {
    this.setState({ modal: true });
  }

  modalClose() {
    this.setState({
      modalInput: "",
      modal: false
    });
  }

  setIsGoalPanelLoading = async isLoading => {
    await this.setState({ isGoalPanelLoading: isLoading });
  }

  getButtonText() {
    if (this.state.goalPercentage === -1) {
      return "Set Goal"
    }
    return "Update Goal"
  }

  render() {
    return (
      <Container>
        <Row>
          <Col lg={{ span: 4 }}>
            <div style={{ fontSize: '18px', fontWeight: 'bold' }}><Icon name='flag checkered' />{this.state.goal}</div>
          </Col>
          <Col sm={{ offset: 1 }}>
            <Modal
              size='small'
              className='modal-main'
              centered={true}
              onClose={e => this.modalClose(e)}
              onOpen={e => this.modalOpen(e)}
              open={this.state.modal}
              trigger={<Button style={{ width: 200 }}>{this.getButtonText()}</Button>}
            >
              <Modal.Header>Select a goal</Modal.Header>
              <Modal.Content>
                <Modal.Description>
                  <div >
                    <Button
                      active={false}
                      content="Maintain current energy usage"
                      onClick={e => this.handleChange(e, "Your goal is to maintain current energy usage.", 0)}
                      fluid={true}
                    />
                    < br />
                    <Button
                      active={false}
                      content="Decrease energy usage by 5%"
                      onClick={e => this.handleChange(e, "Your goal is to decrease energy usage by 5%.", 5)}
                      fluid={true}
                    />
                    < br />
                    <Button
                      active={false}
                      content="Decrease energy usage by 10%"
                      onClick={e => this.handleChange(e, "Your goal is to decrease energy usage by 10%.", 10)}
                      fluid={true}
                    />
                    < br />
                    <Button
                      active={false}
                      content="Decrease energy usage by 20%"
                      onClick={e => this.handleChange(e, "Your goal is to decrease energy usage by 20%.", 20)}
                      fluid={true}
                    />
                    < br />
                  </div>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button color='black' onClick={e => this.modalClose(e)}>
                  Close
        </Button>
                <Button
                  content="Save"
                  labelPosition='right'
                  icon='checkmark'
                  onClick={e => this.handleSubmit(e)}
                  positive
                />
              </Modal.Actions>
            </Modal>
          </Col>
          {this.props.device &&
            <GoalPanel
              device={this.props.device}
              goal={this.state.goalPercentage}
              setIsLoading={this.props.setIsLoading}
            />
          }
        </Row>
      </Container>
    )
  }
}