import React from "react";
import SimpleCalendar from "../Calendar/SimpleCalendar.js";
import PastCalendar from "../Calendar/PastCalendar.js";
import PastRangeCalendar from "../Calendar/PastRangeCalendar";
import moment from "moment";
import CalendarWrapper from "../Calendar/CalendarWrapper";
import ElectricityChart from "../Electricity/ElectricityChart";
import { SingleComparator } from "../../components/Electricity/Comparator.js";

import {
  lineChartModes,
  comparisonModes,
  getPrevDay,
  getTimeInterval,
  getNextDay,
  getNearbyDates,
  getPrevDays,
  pageStyles,
  lineColors
} from "../../resources/constants.js";

import { dateRangeToString, dateToDayMonthString } from "./utils.js";

const dateFormat = "YYYY-MM-DD";

export default class YouVsYou extends React.Component {
  constructor(props) {
    super(props);
    let { today, lastWeek } = getNearbyDates();
    let yesterday = getPrevDay(today);
    let interval = getTimeInterval(lastWeek, today);
    let dayBeforeLastWeek = getPrevDay(lastWeek);

    this.state = {
      comparator: null,
      lineData: [],
      selectionNotFinished: false,
      interval: interval,

      //day tab selections
      currentSelectedDay: today,
      pastSelectedDay: yesterday,

      //range tab selections, default to the last 7 days
      currentSelectedDayRange: {
        startDate: lastWeek,
        endDate: today
      },
      pastSelectedDayRange: {
        // want 1 less to make start date inclusive
        startDate: getPrevDays(dayBeforeLastWeek, interval),
        endDate: dayBeforeLastWeek
      },

      disabledDays: {
        after: moment(dayBeforeLastWeek).toDate()
      },
      simpleDisabledDays: {
        after: moment(yesterday).toDate()
      },

      //average vs peak power and daily vs hourly modes
      mode: props.mode || lineChartModes.daily,
      comparisonMode: props.comparisonMode || comparisonModes.average
    };

    //used to calculate comparison data between a single device with multiple date ranges
    this.state.comparator = new SingleComparator(
      this.props.device,
      this.state.mode,
      this.state.currentSelectedDayRange,
      this.state.pastSelectedDayRange
    );
  }

  async componentDidMount() {
    await this.refreshData();
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.device &&
      (!prevProps.device || this.props.device.id !== prevProps.device.id)
    ) {
      await this.initializeDevice();
      await this.refreshData();
    }
  }

  async initializeDevice() {
    this.state.comparator.setDevice(this.props.device);
  }

  refreshData = async () => {
    await this.props.setIsLoading(true);

    //ensure the comparator is on the correct mode
    this.state.comparator.setMode(this.state.mode);

    let nowDateRange = {};
    let pastDateRange = {};

    //increase the end date by 1 to account for date exclusivity in the backend
    if (this.state.mode === lineChartModes.hourly) {
      nowDateRange = {
        startDate: this.state.currentSelectedDay,
        endDate: getNextDay(this.state.currentSelectedDay)
      };

      pastDateRange = {
        startDate: this.state.pastSelectedDay,
        endDate: getNextDay(this.state.pastSelectedDay)
      };
    } else {
      nowDateRange = {
        startDate: this.state.currentSelectedDayRange.startDate,
        endDate: getNextDay(this.state.currentSelectedDayRange.endDate)
      };

      pastDateRange = {
        startDate: this.state.pastSelectedDayRange.startDate,
        endDate: getNextDay(this.state.pastSelectedDayRange.endDate)
      };
    }

    //note: setting ranges in the comparator doesn't refresh the data

    this.state.comparator.setNowDateRange(nowDateRange);
    this.state.comparator.setPastDateRange(pastDateRange);

    //actually refresh the data and generate the comparison values

    try {
      await this.state.comparator.compareDevices();

      this.state.comparator.getNowData().setName("current"); //set the names for the graphs
      this.state.comparator.getPastData().setName("past");

      //line data is given to the chart class to render the correct data
      await this.setState({
        lineData: [
          this.state.comparator.getNowData(),
          this.state.comparator.getPastData()
        ]
      });
    } catch (e) {
      console.log("error occurred while comparing", e);
    }

    await this.props.setIsLoading(false);
  };

  async currentRangeCalendarSelect(start, end) {
    let formattedStartDate = moment(start).format(dateFormat);
    let formattedEndDate = moment(end).format(dateFormat);

    this.state.comparator.setNowDateRange(formattedStartDate, formattedEndDate);

    await this.setState({
      currentSelectedDayRange: {
        startDate: formattedStartDate,
        endDate: formattedEndDate
      },
      interval: getTimeInterval(formattedStartDate, formattedEndDate),

      //disable days after the start of the current selection
      disabledDays: {
        after: moment(getPrevDay(start)).toDate()
      }
    });

    //update the past selection if it overlaps with the current selection
    let formattedPastEndDate = moment(this.state.pastSelectedDayRange.endDate);
    if (formattedPastEndDate.isAfter(getPrevDay(formattedStartDate))) {
      //selection is invalid
      let interval = getTimeInterval(formattedStartDate, formattedEndDate) + 1;

      await this.setState({
        pastSelectedDayRange: {
          startDate: getPrevDays(formattedStartDate, interval),
          endDate: getPrevDay(formattedStartDate)
        }
      });
    }

    //await this.refreshData();
  }

  async currentDayCalendarSelect(date) {
    let startDate = moment(date).format(dateFormat);

    await this.setState({
      currentSelectedDay: startDate,
      simpleDisabledDays: {
        after: moment(getPrevDay(date)).toDate()
      }
    });

    //correct past date if invalid
    let pastDay = moment(this.state.pastSelectedDay);
    if (pastDay.isAfter(moment(getPrevDay(startDate)))) {
      await this.setState({
        pastSelectedDay: getPrevDay(startDate)
      });
    }

    await this.refreshData();
  }

  async dayCalendarSelect(date) {
    if (this.state.mode === lineChartModes.daily) {
      let { startDate, endDate } = this.state.currentSelectedDayRange;
      let differenceInDays = getTimeInterval(startDate, endDate);

      await this.setState({
        pastSelectedDayRange: {
          startDate: getPrevDays(date, differenceInDays),
          endDate: moment(date).format(dateFormat)
        },
        interval: differenceInDays
      });
    } else {
      await this.setState({
        pastSelectedDay: moment(date).format(dateFormat)
      });
    }
    await this.refreshData();
  }

  calendarResetHandler = async () => {
    await this.setState({
      disabledDays: {
        daysOfWeek: [0, 1, 2, 3, 4, 5, 6]
      },
      selectionNotFinished: true
    });
  };

  selectionFinished = async () => {
    await this.setState({
      selectionNotFinished: false
    });
  };

  renderCalendarPicker() {
    let disabledDays = this.state.disabledDays;
    let currentStartDate = this.state.currentSelectedDayRange.startDate;
    let currentEndDate = this.state.currentSelectedDayRange.endDate;

    let pastStartDate = this.state.pastSelectedDayRange.startDate;
    let pastEndDate = this.state.pastSelectedDayRange.endDate;

    return this.state.mode === lineChartModes.daily ? (
      <span>
        <div>
          <CalendarWrapper
            title={"Current Period"}
            from={currentStartDate}
            to={currentEndDate}
            backgroundColor={lineColors[0]}
          >
            <PastRangeCalendar
              onSelect={(start, end) =>
                this.currentRangeCalendarSelect(start, end)
              }
              from={currentStartDate}
              to={currentEndDate}
              calendarResetHandler={this.calendarResetHandler}
              selectionFinished={this.selectionFinished}
            />
          </CalendarWrapper>
        </div>
        <div>
          <CalendarWrapper
            title={"Past Period"}
            from={pastStartDate}
            to={pastEndDate}
            backgroundColor={lineColors[1]}
          >
            <PastCalendar
              onSelect={date => this.dayCalendarSelect(date)}
              interval={this.state.interval}
              from={pastStartDate}
              to={pastEndDate}
              disabledDays={disabledDays}
              selectionNotFinished={this.state.selectionNotFinished}
            />
          </CalendarWrapper>
        </div>
      </span>
    ) : (
      <span>
        <div>
          <CalendarWrapper
            title={"Current Day"}
            from={this.state.currentSelectedDay}
            backgroundColor={lineColors[0]}
          >
            <SimpleCalendar
              selectedDay={this.state.currentSelectedDay}
              onSelect={date => this.currentDayCalendarSelect(date)}
              disabledDays={{ after: new Date() }}
            />
          </CalendarWrapper>
        </div>
        <div>
          <CalendarWrapper
            title={"Past Day"}
            from={this.state.pastSelectedDay}
            backgroundColor={lineColors[1]}
          >
            <SimpleCalendar
              selectedDay={this.state.pastSelectedDay}
              onSelect={date => this.dayCalendarSelect(date)}
              disabledDays={this.state.simpleDisabledDays}
            />
          </CalendarWrapper>
        </div>
      </span>
    );
  }

  async swapToDaily() {
    await this.setState({ mode: lineChartModes.daily });
    await this.refreshData();
  }

  async swapToHourly() {
    await this.setState({ mode: lineChartModes.hourly });
    await this.refreshData();
  }

  async swapToMaximum() {
    await this.setState({
      comparisonMode: comparisonModes.maximum
    });
  }

  async swapToAverage() {
    await this.setState({
      comparisonMode: comparisonModes.average
    });
  }

  async swapToCarbonEmission() {
    await this.setState({
      comparisonMode: comparisonModes.carbon_emission
    });
  }

  async swapToElectricityCost() {
    await this.setState({
      comparisonMode: comparisonModes.cost
    });
  }

  dailySummary() {
    if (
      !this.state.comparator ||
      !this.state.comparator.areAValuesPresent() ||
      !this.state.comparator.areBValuesPresent()
    )
      return null;

    let cards = [];
    cards.push(
      <InformationCard
        key="avg-power-consumption"
        content={
          <div>
            Your average daily energy consumption{" "}
            <b id="medium-bold">
              {this.state.comparator.getAverageIncrease() > 0
                ? "increased"
                : "decreased"}
            </b>{" "}
            by{" "}
            <b id="medium-bold">
              {Math.abs(this.state.comparator.getAverageIncrease())}%
            </b>
            .
          </div>
        }
      />
    );

    cards.push(
      <InformationCard
        key="average-energy-consumption-a"
        content={
          <div>
            Your average energy consumption between <br />{" "}
            <b id="medium-bold">
              {dateRangeToString(this.state.currentSelectedDayRange)}
            </b>{" "}
            <br />
            was{" "}
            <b id="medium-bold">
              {(this.state.comparator.getAverageA() * lineChartModes.daily.multiplier).toFixed(1)}{" "}
              kWh
            </b>
            .
          </div>
        }
      />
    );

    cards.push(
      <InformationCard
        key="average-energy-consumption-b"
        content={
          <div>
            Your average energy consumption between <br />{" "}
            <b id="medium-bold">
              {dateRangeToString(this.state.pastSelectedDayRange)}
            </b>{" "}
            <br />
            was{" "}
            <b id="medium-bold">
              {(this.state.comparator.getAverageB() * lineChartModes.daily.multiplier).toFixed(1)}{" "}
              kWh
            </b>
            .
          </div>
        }
      />
    );

    cards.push(
      <InformationCard
        key="peak-power-consumption"
        content={
          <div>
            Your peak power consumption{" "}
            <b id="medium-bold">
              {this.state.comparator.getMaxIncrease() > 0
                ? "increased"
                : "decreased"}{" "}
            </b>
            by{" "}
            <b id="medium-bold">
              {Math.abs(this.state.comparator.getMaxIncrease())}%
            </b>
            .
          </div>
        }
      />
    );

    let timeAtMaxA = this.state.comparator.getTimeAtMaxA();
    let powerHoursA = moment(timeAtMaxA).format("h:mma");
    let powerDateA = moment(timeAtMaxA).format("MM/DD");

    cards.push(
      <InformationCard
        key="peak-power-consumption-a"
        content={
          <div>
            Your peak power between <br />{" "}
            <b id="medium-bold">
              {dateRangeToString(this.state.currentSelectedDayRange)}
            </b>{" "}
            <br />
            was <b id="medium-bold">{this.state.comparator.getMaxA()} kWh</b>
            <br /> at <b id="medium-bold">{powerHoursA}</b> on{" "}
            <b id="medium-bold"> {powerDateA}</b>.
          </div>
        }
      />
    );

    let timeAtMaxB = this.state.comparator.getTimeAtMaxB();
    let powerHoursB = moment(timeAtMaxB).format("h:mma");
    let powerDateB = moment(timeAtMaxB).format("MM/DD");
    cards.push(
      <InformationCard
        key="peak-power-consumption-b"
        content={
          <div>
            Your peak power between <br />{" "}
            <b id="medium-bold">
              {dateRangeToString(this.state.pastSelectedDayRange)}
            </b>{" "}
            <br />
            was <b id="medium-bold">{this.state.comparator.getMaxB()} kWh</b>
            <br /> at <b id="medium-bold">{powerHoursB}</b> on{" "}
            <b id="medium-bold"> {powerDateB}</b>.
          </div>
        }
      />
    );

    return <div className="electricity-information-cards"> {cards} </div>;
  }

  hourlySummary() {
    if (
      !this.state.comparator ||
      !this.state.comparator.areAValuesPresent() ||
      !this.state.comparator.areBValuesPresent()
    ) {
      return null;
    }

    let nowDate = dateToDayMonthString(this.state.currentSelectedDay);
    let pastDate = dateToDayMonthString(this.state.pastSelectedDay);
    let averageIncrease = this.state.comparator.getAverageIncrease();
    let averageGreaterOrSmaller =
      this.state.comparator.averageIncrease > 0 ? "greater" : "smaller";
    let maxIncrease = this.state.comparator.getMaxIncrease();
    let maxGreaterOrSmaller =
      this.state.comparator.maxIncrease > 0 ? "greater" : "smaller";

    let cards = [];

    cards.push(
      <InformationCard
        key={"avg-power"}
        content={
          <div>
            Your energy consumption for <b id="medium-bold">{nowDate} </b>
            was{" "}
            <b id="medium-bold">
              {Math.abs(averageIncrease)}% {averageGreaterOrSmaller}
            </b>{" "}
            than for <b id="medium-bold"> {pastDate} </b>.
          </div>
        }
      />
    );

    cards.push(
      <InformationCard
        key={"max-power"}
        content={
          <div>
            Your peak power consumption for <b id="medium-bold"> {nowDate}</b>{" "}
            was{" "}
            <b id="medium-bold">
              {Math.abs(maxIncrease)}% {maxGreaterOrSmaller}
            </b>{" "}
            than for <b id="medium-bold">{pastDate}</b>.
          </div>
        }
      />
    );

    cards.push(
      <InformationCard
        key={"max-power-val"}
        content={
          <div>
            {" "}
            Your peak power consumption for <b id="medium-bold">
              {nowDate}
            </b>{" "}
            was <b id="medium-bold">{this.state.comparator.getMaxA()}</b> kW.
          </div>
        }
      />
    );

    cards.push(
      <InformationCard
        key="max-power-past"
        content={
          <div>
            Your peak power consumption for <b id="medium-bold">{pastDate}</b>{" "}
            was <b id="medium-bold">{this.state.comparator.getMaxB()}</b> kW.
          </div>
        }
      />
    );

    return <div className="electricity-information-cards"> {cards} </div>;
  }

  //TODO: complete the carbon emission and electricity cost cases when data available for them
  getChartYAccessor() {
    let multiplier = this.state.mode.multiplier;
    switch (this.state.comparisonMode) {
      case comparisonModes.average:
        return { yAccessor: d => multiplier * d.power, label: "power" };
      case comparisonModes.maximum:
        return { yAccessor: d => d.maxPower, label: "max power" };
      case comparisonModes.carbon_emission:
        return { yAccessor: d => 0, label: "carbon emission" };
      case comparisonModes.cost:
        return { yAccessor: d => 0, label: "electricity cost" }
      default:
        throw Error("Invalid comparison mode");
    }
  }

  render() {
    let summaryWord =
      this.state.mode === lineChartModes.daily
        ? this.dailySummary()
        : this.hourlySummary();
    return (
      <div className="you-vs-container">
        <div className="you-vs-header">
          Let's explore your current and historical energy usage.
        </div>
        <div className="linechart-calendar">
          <div className="linechart-left">
            <ElectricityChart
              isLoading={this.props.isLoading}
              data={this.state.lineData}
              xAccessor={d => d.timestamp}
              yAccessorsAndLabel={[this.getChartYAccessor()]}
              formatXAxis={time => this.state.mode.format(time)}
              formatYAxis={value => value + " kWh"}
              formatter={value => (value / 1000).toFixed(1)}
              xAxisLabel="Date"
              yAxisLabel="kWh"
            />
            <span className="youvsyou-chart-footer">
              <span className="youvsyou-chart-button-wrapper">
                <div
                  className="youvsyou-chart-mode-button"
                  style={{
                    borderBottomLeftRadius: 10,
                    backgroundColor:
                      this.state.comparisonMode === comparisonModes.average
                        ? pageStyles.lineChartStyles.selectedColor
                        : pageStyles.lineChartStyles.deselectedColor,
                    color:
                      this.state.comparisonMode === comparisonModes.average
                        ? pageStyles.lineChartStyles.selectedColorText
                        : pageStyles.lineChartStyles.deselectedColorText
                  }}
                  onClick={async () => await this.swapToAverage()}
                >
                  Average Power
                </div>
                <div
                  className="youvsyou-chart-mode-button"
                  style={{
                    backgroundColor:
                      this.state.comparisonMode === comparisonModes.maximum
                        ? pageStyles.lineChartStyles.selectedColor
                        : pageStyles.lineChartStyles.deselectedColor,
                    color:
                      this.state.comparisonMode === comparisonModes.maximum
                        ? pageStyles.lineChartStyles.selectedColorText
                        : pageStyles.lineChartStyles.deselectedColorText
                  }}
                  onClick={async () => await this.swapToMaximum()}
                >
                  Peak Power
                </div>
                <div
                  className="youvsyou-chart-mode-button"
                  style={{
                    backgroundColor:
                      this.state.comparisonMode === comparisonModes.carbon_emission
                        ? pageStyles.lineChartStyles.selectedColor
                        : pageStyles.lineChartStyles.deselectedColor,
                    color:
                      this.state.comparisonMode === comparisonModes.carbon_emission
                        ? pageStyles.lineChartStyles.selectedColorText
                        : pageStyles.lineChartStyles.deselectedColorText
                  }}
                  onClick={async () => await this.swapToCarbonEmission()}
                >
                  Carbon Emissions
              </div>
                <div
                  className="youvsyou-chart-mode-button"
                  style={{
                    borderBottomRightRadius: 10,
                    backgroundColor:
                      this.state.comparisonMode === comparisonModes.cost
                        ? pageStyles.lineChartStyles.selectedColor
                        : pageStyles.lineChartStyles.deselectedColor,
                    color:
                      this.state.comparisonMode === comparisonModes.cost
                        ? pageStyles.lineChartStyles.selectedColorText
                        : pageStyles.lineChartStyles.deselectedColorText
                  }}
                  onClick={async () => await this.swapToElectricityCost()}
                >
                  Electricity Cost
              </div>
              </span>
              <span
                className="youvsyou-chart-button-wrapper"
                style={{ marginLeft: 30 }}
              >
                <div
                  className="youvsyou-chart-mode-button"
                  style={{
                    borderBottomLeftRadius: 10,
                    backgroundColor:
                      this.state.mode === lineChartModes.daily
                        ? pageStyles.lineChartStyles.selectedColor
                        : pageStyles.lineChartStyles.deselectedColor,
                    color:
                      this.state.mode === lineChartModes.daily
                        ? pageStyles.lineChartStyles.selectedColorText
                        : pageStyles.lineChartStyles.deselectedColorText
                  }}
                  onClick={async () => await this.swapToDaily()}
                >
                  Daily
                </div>
                <div
                  className="youvsyou-chart-mode-button"
                  style={{
                    borderBottomRightRadius: 10,
                    backgroundColor:
                      this.state.mode === lineChartModes.hourly
                        ? pageStyles.lineChartStyles.selectedColor
                        : pageStyles.lineChartStyles.deselectedColor,
                    color:
                      this.state.mode === lineChartModes.hourly
                        ? pageStyles.lineChartStyles.selectedColorText
                        : pageStyles.lineChartStyles.deselectedColorText
                  }}
                  onClick={async () => await this.swapToHourly()}
                >
                  Hourly
                </div>
              </span>
            </span>
            <div className="electricity-information-cards-wrapper">
              {summaryWord}
            </div>
          </div>
          <div className="right-calendar">{this.renderCalendarPicker()}</div>
        </div>
      </div >
    );
  }
}

const InformationCard = props => {
  return <div className="electricity-information-card">{props.content}</div>;
};
