export const building1Data = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-170, 10],
            [-170, 60],
            [190, 60],
            [190, -60],
            [-170, -60],
            [-170, -10],
            [-164, -10],
            [-164, -54],
            [3, -54],
            [3, -30],
            [13, -30],
            [13, -54],
            [184, -54],
            [184, -3],
            [160, -3],
            [160, 3],
            [184, 3],
            [184, 54],
            [130, 54],
            [130, 34],
            [124, 34],
            [124, 54],
            [70, 54],
            [70, 34],
            [64, 34],
            [64, 54],
            [13, 54],
            [13, 30],
            [3, 30],
            [3, 54],
            [-44, 54],
            [-44, 34],
            [-50, 34],
            [-50, 54],
            [-104, 54],
            [-104, 34],
            [-110, 34],
            [-110, 54],
            [-164, 54],
            [-164, 10],
            [-170, 10],
          ],
          [
            [-110, 20],
            [-44, 20],
            [-44, 3],
            [40, 3],
            [40, -3],
            [-44, -3],
            [-44, -40],
            [-50, -40],
            [-50, -20],
            [-104, -20],
            [-104, -40],
            [-110, -40],
            [-110, -3],
            [-140, -3],
            [-140, 3],
            [-110, 3],
            [-110, 20],
          ],
          [
            [64, 20],
            [130, 20],
            [130, -40],
            [124, -40],
            [124, -20],
            [70, -20],
            [70, -40],
            [64, -40],
            [64, 20],
          ],
        ],
      },
    },
  ],
};

export const building1RoomsData = [
  [
    [-164, 54],
    [-110, 54],
    [-110, 3],
    [-164, 3],
  ],
  [
    [-164, -54],
    [-110, -54],
    [-110, -3],
    [-164, -3],
  ],
  [
    [-104, 54],
    [-104, 20],
    [-50, 20],
    [-50, 54],
  ],
  [
    [-104, -54],
    [-104, -20],
    [-50, -20],
    [-50, -54],
  ],
  [
    [-44, 54],
    [3, 54],
    [3, 3],
    [-44, 3],
  ],
  [
    [-44, -54],
    [3, -54],
    [3, -3],
    [-44, -3],
  ],
  [
    [13, 54],
    [64, 54],
    [64, 3],
    [13, 3],
  ],
  [
    [13, -54],
    [64, -54],
    [64, -3],
    [13, -3],
  ],
  [
    [70, 54],
    [124, 54],
    [124, 20],
    [70, 20],
  ],
  [
    [70, -54],
    [124, -54],
    [124, -20],
    [70, -20],
  ],
  [
    [130, 54],
    [184, 54],
    [184, 3],
    [130, 3],
  ],
  [
    [130, -54],
    [184, -54],
    [184, -3],
    [130, -3],
  ],
];
