export default class DataPoint {
  // initialize all data to null
  constructor() {
    this.time = null;
    this.GPS_fix = null;
    this.latitude = null;
    this.longitude = null;
    this.altitude = null;
    this.pm1 = null;
    this.pm0 = null;
    this.pm25 = null;
    this.rh = null;
    this.t = null;
    this.vbus = null;
    this.position = null;
  }

  // set data with an array in orders specified
  setDataWithArray(pValue) {
    this.time = pValue[0];
    this.GPS_fix = pValue[1];
    this.latitude = pValue[2];
    this.longitude = pValue[3];
    this.altitude = pValue[4];
    this.pm0 = pValue[5];
    this.pm1 = pValue[6];
    this.pm25 = pValue[7];
    this.rh = pValue[8];
    this.t = pValue[9];
    this.vbus = pValue[10];
  }

  // set data entry with given type
  setDataEntry(type, value) {
    switch (type) {
      case "time":
        this.time = value;
        break;
      case "GPS_fix":
        this.GPS_fix = value;
        break;
      case "latitude":
        this.latitude = value;
        break;
      case "longitude":
        this.longitude = value;
        break;
      case "pm0":
        this.pm0 = value;
        break;
      case "pm25":
        this.pm25 = value;
        break;
      case "rh":
        this.rh = value;
        break;
      case "t":
        this.t = value;
        break;
      case "vbus":
        this.vbus = value;
        break;
      case "altitude":
        this.altitude = value;
        break;
      case "pm1":
        this.pm1 = value;
        break;
      default:
        break;
    }
  }

  toArray() {
    return [
      this.time,
      this.GPS_fix,
      this.latitude,
      this.longitude,
      this.altitude,
      this.pm0,
      this.pm1,
      this.pm25,
      this.rh,
      this.t,
      this.vbus
    ];
  }

  toDataDisplayArray() {
    return [
      ["time", `${this.time === null ? "N/A" : this.time} `],
      ["temperature", `${this.t === null ? "N/A" : this.t}`],
      ["relative humidity", `${this.rh === null ? "N/A" : this.rh}`],
      ["PM2.5", `${this.pm25 ===null ? "N/A" : this.pm25}`]
    ];
  }
}
