import React from "react";
import {
  Map as Openstreetmap,
  TileLayer,
  Popup,
  Circle,
  ZoomControl,
  SVGOverlay
} from "react-leaflet";
import Legend from "./Legend";
import { markerType } from "./constants";

import "leaflet/dist/leaflet.css";
import "../../assets/css/OpenMap.css";
import { latLng, latLngBounds, Polyline } from "leaflet";

export default class OpenMap extends React.Component {
  constructor(props) {
    super(props);
    this.markers = {
      connectedCircle: new Map(),
      default: new Map()
    };
    this.renderedMarkers = [];
    this.renderedLabels = [];
    this.renderedPath = [];
    this.path = new Map();
    this.devices = this.props.devices;
    this.lastPos = new Map();
    this.colorMap = new Map();
    this.markerIndex = 0;
    this.indexMap = new Map();
    this.state = {
      renderedMarkers: this.renderedMarkers,
      renderedLabels: this.renderedLabels,
      renderedPath: this.renderedPath,
      devices: this.devices,
      center: this.props.center,
      zoom: 17,
      markers: this.markers,
      path: this.path
    };
    this.points = [];

    // random select colors and assign them to each device
    this.props.devices.forEach(v => {
      this.colorMap.set(
        v.id,
        "#" + Math.floor(Math.random() * 16777215).toString(16)
      );
    });
  }

  // colors for different level of air pollution
  getColor = d => {
    return d > 500
      ? "#800026"
      : d > 200
      ? "#BD0026"
      : d > 100
      ? "#E31A1C"
      : d > 50
      ? "#FC4E2A"
      : d > 20
      ? "#FD8D3C"
      : d > 10
      ? "#FEB24C"
      : d > 3
      ? "#FED976"
      : "#80f445";
  };

  /**
   * generate circleMarker of data given
   * @param {MarkerInfo} data instance of DataInfo or at least should not be null
   * @param {Integer} index key of react Circle component
   * @return {React.Component} - generated circleMarkers
   */
  generateCircleMarker(markerInfo, index) {
    try {
      let data = markerInfo.data;
      let time = data.time;
      time = time.slice(0, 10) + " " + time.slice(11, 19);
      markerInfo.data.time = time;
      let position = [data.latitude, data.longitude];
      return (
        <Circle
          key={index}
          center={position}
          radius={20}
          fillColor={this.getColor(data.pm25)}
          fillOpacity={0.5}
          color={null}
        >
          <Popup>{this.generatePopupChildrenDiv(markerInfo)}</Popup>
        </Circle>
      );
    } catch (e) {
      return null;
    }
  }

  generateLabel(markerInfo, index) {
    try {
      let data = markerInfo.data;
      let position = [data.latitude, data.longitude];
      let bound = [
        [position[0] - 0.005, position[1] - 0.005],
        [position[0] + 0.005, position[1] + 0.005]
      ];
      let idx = 0;
      if (this.indexMap.has(markerInfo.id)) {
        idx = this.indexMap.get(markerInfo.id);
        this.indexMap.set(markerInfo.id, idx + 1);
      } else {
        this.indexMap.set(markerInfo.id, 1);
      }
      return (
        <SVGOverlay bounds={bound}>
          <text x="50%" y="50%" fill="black" textAnchor="middle">
            {markerInfo.name + " (" + idx + ")"}
          </text>
        </SVGOverlay>
      );
    } catch (e) {
      return null;
    }
  }

  generatePath(prevInfo, markerInfo) {
    let pos1 = [prevInfo.data.latitude, prevInfo.data.longitude];
    let pos2 = [markerInfo.data.latitude, markerInfo.data.longitude];
    return (
      <Polyline
        color={this.colorMap.get(markerInfo.id)}
        weight={6}
        positions={[pos1, pos2]}
      >
        <Popup>{this.generatePopupChildrenDiv(markerInfo)}</Popup>
      </Polyline>
    );
  }

  generatePopupChildrenDiv(markerInfo) {
    return markerInfo.data.toDataDisplayArray().map(element => {
      return <div>{`${element[0]}: ${element[1]}`}</div>;
    });
  }

  centerTo(position) {
    if (position != null) {
      this.map.leafletElement.panTo(position);
      this.setState({ center: position });
    }
  }

  addConnectedCircle(markerInfo) {
    if (markerInfo != null) {
      if (
        this.markers.connectedCircle.has(markerInfo.id) &&
        this.markers.connectedCircle.get(markerInfo.id) != null
      ) {
        let prev_ref = this.markers.connectedCircle.get(markerInfo.id)[0];
        let prevMarker = this.markers.connectedCircle.get(markerInfo.id)[1];
        if (this.isUpdate(markerInfo, prevMarker)) {
          markerInfo.data.latitude = prevMarker.data.latitude;
          markerInfo.data.longitude = prevMarker.data.longitude;
          let idx = 0;
          this.renderedMarkers.forEach((v, i) => {
            if (v === prev_ref) {
              idx = i;
            }
          });
          this.renderedMarkers.splice(idx, 1);
          this.renderedLabels.splice(idx, 1);
        } else {
          if (prevMarker.data) {
            this.renderedPath = this.renderedPath.concat([
              this.generatePath(prevMarker, markerInfo)
            ]);
          }
        }
      }
      let circleMarker = this.generateCircleMarker(
        markerInfo,
        this.markerIndex++
      );
      let circleLabel = this.generateLabel(markerInfo, this.markerIndex++);

      this.markers.connectedCircle.set(markerInfo.id, [
        this.renderedMarkers.length - 1,
        markerInfo
      ]);

      if (circleMarker && circleLabel) {
        this.renderedMarkers = this.renderedMarkers.concat([circleMarker]);
        this.renderedLabels = this.renderedLabels.concat([circleLabel]);
        this.markers.connectedCircle.set(markerInfo.id, [
          this.renderedMarkers[this.renderedMarkers.length - 1],
          markerInfo
        ]);
      }
    }
  }

  isUpdate(marker, prevMarker) {
    if (marker != null && prevMarker != null) {
      try {
        let newLat = marker.data.latitude;
        let newLng = marker.data.longitude;
        let prevLat = prevMarker.data.latitude;
        let prevLng = prevMarker.data.longitude;
        if (
          Math.abs(newLat - prevLat) < 0.005 &&
          Math.abs(newLng - prevLng) < 0.005
        )
          return true;
        return false;
      } catch (e) {
        return false;
      }
    }
    return false;
  }

  initializeStates() {}

  componentDidMount() {
    this.initializeStates();
  }

  addMarkers(markers) {
    markers.forEach(marker => {
      if (marker != null) {
        switch (marker.type) {
          case markerType.connectedCircle:
            this.addConnectedCircle(marker);
            break;
          default:
            break;
        }
      }
    });
    this.setState({
      renderedMarkers: this.renderedMarkers,
      renderedLabels: this.renderedLabels,
      renderedPath: this.renderedPath
    });
  }

  resetMap() {
    this.markers = {
      connectedCircle: new Map(),
      default: new Map()
    };
    this.renderedMarkers = [];
    this.path = new Map();
    this.setState({
      renderedMarkers: this.renderedMarkers,
      zoom: 17,
      markers: this.markers,
      path: this.path
    });
  }

  /**
    This function updates the marker position(add one more circle marker or move stationary marker if position changes)
    input
  */
  componentDidUpdate(prevProps) {
    if (this.props.addMarkers !== prevProps.addMarkers) {
      this.addMarkers(this.props.addMarkers);
    }

    if (this.props.reset !== prevProps.reset) {
      this.resetMap();
    }

    if (this.props.reCenter !== prevProps.reCenter) {
      this.centerTo(this.props.center);
    }
  }

  render() {
    var corner1 = latLng(30, -70.227),
      corner2 = latLng(50, -80),
      bounds = latLngBounds(corner1, corner2);

    return (
      <Openstreetmap
        center={this.props.center}
        zoom={this.state.zoom}
        zoomControl={false}
        animate={true}
        id="map1"
        ref={ref => {
          this.map = ref;
        }}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          bounds={bounds}
        />
        {this.state.renderedMarkers}
        {this.state.renderedLabels}
        {this.state.renderedPath}
        <ZoomControl position="bottomleft" />
        <Legend id="legend1" />
      </Openstreetmap>
    );
  }
}
