import React from "react";
import { Button, Popup, Input } from "semantic-ui-react";
import DayPicker from "react-day-picker";
import moment from "moment";

import TimePicker from "./TimePicker";

export default class ControlButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playbackDate: {
        start: new Date(),
        end: new Date()
      },
      showWarning: false,
      goButtonStyle: null
    };
  }

  onPlaybackClick = async () => {
    if (
      moment(this.state.playbackDate.start).isBefore(
        this.state.playbackDate.end
      )
    ) {
      this.setState({ goButtonStyle: null, showWarning: false });
      await this.props.handlePlayback(this.state.playbackDate);
    } else
      this.setState({
        goButtonStyle: { border: "1px solid red" },
        showWarning: true
      });
  };

  renderEndDateInput = () => {
    const handleDayClick = (day, { disabled }) => {
      if (!disabled) {
        this.setState({
          playbackDate: {
            start: this.state.playbackDate.start,
            end: day
          }
        });
      }
    };
    const handleTimeClick = time => {
      const m = moment(time);
      this.setState({
        playbackDate: {
          start: this.state.playbackDate.start,
          end: m.toDate()
        }
      });
    };
    const modifiers = {
      highlighted: this.state.playbackDate.end
    };
    const disabledDays = {
      after: new Date(),
      before: this.state.playbackDate.start
    };

    return (
      <div>
        <Popup
          trigger={
            <Input
              className="date-input"
              icon="calendar alternate outline"
              iconPosition="left"
              value={`${moment(this.state.playbackDate.end).format(
                "YYYY-MM-DD"
              )}`}
            />
          }
          content={
            <DayPicker
              modifiers={modifiers}
              disabledDays={disabledDays}
              onDayClick={handleDayClick}
              selectedDays={this.state.playbackDate.end}
            />
          }
          on="click"
        />
        <Popup
          trigger={
            <Input
              className="time-input"
              icon="clock outline"
              iconPosition="left"
              value={`${moment(this.state.playbackDate.end).format("hh:mm A")}`}
            />
          }
          content={
            <TimePicker
              handleTimeClick={handleTimeClick}
              time={this.state.playbackDate.end}
            />
          }
          on="click"
        />
      </div>
    );
  };

  renderWarning = () => {
    return <div className="warning">Please enter valid date</div>;
  };

  renderStartDateInput = () => {
    const handleDayClick = (day, { disabled }) => {
      if (!disabled) {
        this.setState({
          playbackDate: {
            start: day,
            end: this.state.playbackDate.end
          }
        });
      }
    };
    const handleTimeClick = time => {
      const m = moment(time);
      this.setState({
        playbackDate: {
          start: m.toDate(),
          end: this.state.playbackDate.end
        }
      });
    };
    const modifiers = {
      highlighted: this.state.playbackDate.start
    };
    const disabledDays = { after: new Date() };
    return (
      <div>
        <Popup
          trigger={
            <Input
              className="date-input"
              icon="calendar alternate outline"
              iconPosition="left"
              value={`${moment(this.state.playbackDate.start).format(
                "YYYY-MM-DD"
              )}`}
            />
          }
          content={
            <DayPicker
              modifiers={modifiers}
              disabledDays={disabledDays}
              onDayClick={handleDayClick}
              selectedDays={this.state.playbackDate.start}
            />
          }
          on="click"
        />
        <Popup
          trigger={
            <Input
              className="time-input"
              icon="clock outline"
              iconPosition="left"
              value={`${moment(this.state.playbackDate.start).format(
                "hh:mm A"
              )}`}
            />
          }
          content={
            <TimePicker
              handleTimeClick={handleTimeClick}
              time={this.state.playbackDate.start}
            />
          }
          on="click"
        />
      </div>
    );
  };

  render() {
    return (
      <div className="playback-control">
        <label className="new-line">FROM</label>
        {this.renderStartDateInput()}
        <label className="new-line">TO</label>
        {this.renderEndDateInput()}
        <div className="btn-group">
          <Button onClick={this.props.handleRealTime}>Real Time</Button>
          <div className="playback-btn">
            <Button
              onClick={this.onPlaybackClick}
              style={this.state.goButtonStyle}
            >
              GO!
            </Button>
            {this.state.showWarning ? this.renderWarning() : null}
          </div>
        </div>
      </div>
    );
  }
}
