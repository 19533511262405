export default class TRHData {
    constructor(startDate, endDate, deviceId, rawData){
        this._startDate = startDate;
        this._endDate = endDate;
        this._deviceId = deviceId;
        this._name = rawData.name;
        this._columns = rawData.columns;

        this._values = Array.isArray(rawData.values)
            ? this._convertValues(rawData.values)
            : [];
    }

    toString() {
        return this._rawData;
    }

    _convertValues(values) {
        return values.map(value => new Data(value[0], value[1], value[2]));
    }

    getValues() {
        return this._values;
    }
    
    getDeviceId() {
        return this._deviceId;
    }
    
    getStartDate() {
        return this._startDate;
    }
    
    getEndDate() {
        return this._endDate;
    }

    getName() {
        return this._name;
    }

    setName(name) {
        this._name = name;
    }
}

class Data {
    constructor(time, relativeHumidity, temperature) {
      this.timestamp = time;
      this.relativeHumidity = relativeHumidity;
      this.temperature = temperature;
    }
  
    toString() {
      return `Time: ${this.time} \t Relative Humidity: ${this.relativeHumidity} \t Temperature: ${this.temperature}`;
    }
  }