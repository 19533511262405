import React from "react";
import { Icon } from "semantic-ui-react";
import moment from "moment";

import "../../assets/css/timePicker.css";

export default class TimePicker extends React.Component {
  state = {
    time: null
  };

  componentDidMount() {
    if (this.props.time != null) {
      this.setState({ time: this.props.time });
    } else {
      this.setState({ time: new Date() });
    }
  }

  renderPicker(onUpClick, onDownClick, display) {
    return (
      <div className="single-picker">
        <Icon name="angle up" onClick={onUpClick} />
        <span>{display}</span>
        <Icon name="angle down" onClick={onDownClick} />
      </div>
    );
  }

  renderHourPicker = () => {
    const onUpClick = () => {
      var hour = moment(this.state.time).hour();
      if (hour === 11) hour = 0;
      else if (hour === 23) {
        hour = 12;
      } else {
        hour++;
      }
      let m = moment(this.state.time).hour(hour);
      this.setState({ time: m.toDate() }, () => {
        this.props.handleTimeClick(this.state.time);
      });
    };

    const onDownClick = () => {
      var hour = moment(this.state.time).hour();
      if (hour === 0) hour = 11;
      else if (hour === 12) hour = 23;
      else hour--;
      let m = moment(this.state.time).hour(hour);
      this.setState({ time: m.toDate() }, () => {
        this.props.handleTimeClick(this.state.time);
      });
    };

    return (
      <div className="hour-picker">
        {this.renderPicker(
          onUpClick,
          onDownClick,
          moment(this.state.time).format("hh")
        )}
      </div>
    );
  };

  renderMinutePicker = () => {
    const onUpClick = () => {
      var minute = moment(this.state.time).minute();
      minute = minute - (minute % 5);
      if (minute < 55) minute = minute + 5;
      else minute = 0;
      const m = moment(this.state.time).minute(minute);
      this.setState({ time: m.toDate() }, () => {
        this.props.handleTimeClick(this.state.time);
      });
    };

    const onDownClick = () => {
      var minute = moment(this.state.time).minute();
      minute = minute - (minute % 5);
      if (minute > 0) minute = minute - 5;
      else minute = 55;
      const m = moment(this.state.time).minute(minute);
      this.setState({ time: m.toDate() }, () => {
        this.props.handleTimeClick(this.state.time);
      });
    };
    return (
      <div className="minute-picker">
        {this.renderPicker(
          onUpClick,
          onDownClick,
          moment(this.state.time).format("mm")
        )}
      </div>
    );
  };

  renderAmPmPicker() {
    const toggleAmPm = () => {
      var hour = moment(this.state.time).hour();
      if (hour >= 12) hour = hour - 12;
      else hour = hour + 12;
      const m = moment(this.state.time).hour(hour);
      this.setState({ time: m.toDate() }, () => {
        this.props.handleTimeClick(this.state.time);
      });
    };
    return (
      <div className="ampm-picker">
        {this.renderPicker(
          toggleAmPm,
          toggleAmPm,
          moment(this.state.time).format("A")
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="time-picker">
        {this.renderHourPicker()}
        <div className="colon">
          <span> : </span>
        </div>
        {this.renderMinutePicker()}
        {this.renderAmPmPicker()}
      </div>
    );
  }
}
