import React from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import moment from "moment";
/*
Used to select a range of dates, where in between each date is highlighted.
Modified from the react-day-picker documentation
*/
export default class PastRangeCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleDayMouseEnter = this.handleDayMouseEnter.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.state = {
      from: moment(props.from).toDate(),
      to: moment(props.to).toDate(),
      enteredTo: moment(props.to).toDate() // Keep track of the last day for mouseEnter.
    };
  }
  getInitialState() {
    return {
      from: null,
      to: null,
      enteredTo: null // Keep track of the last day for mouseEnter.
    };
  }
  isSelectingFirstDay(from, to, day) {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  }
  async handleDayClick(day, { disabled }) {
    if (disabled) {
      window.alert("This day hasn't happened yet!");
      return;
    }
    const { from, to } = this.state;
    if (from && to && day >= from && day <= to) {
      await this.setState({
        from: day,
        to: null,
        enteredTo: null
      });
      return;
    }
    if (this.isSelectingFirstDay(from, to, day)) {
      await this.setState({
        from: day,
        to: null,
        enteredTo: null
      });
    } else {
      await this.setState({
        to: day,
        enteredTo: day
      });
      this.props.selectionFinished();
      this.props.onSelect(from, day);
    }
  }
  handleDayMouseEnter(day) {
    const { from, to } = this.state;
    if (!this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        enteredTo: day
      });
    }
  }

  handleResetClick() {
    this.setState(this.getInitialState());
    this.props.calendarResetHandler();
  }

  render() {
    const { from, to, enteredTo } = this.state;
    const modifiers = { start: from, end: enteredTo };
    const disabledDays = {
      before: to == null ? this.state.from : null,
      after: new Date()
    };
    const selectedDays = [from, { from, to: enteredTo }];

    return (
      <div>
        <div>
          {!from && !to && "Please select the first day."}
          {from && !to && "Please select the last day."}
        </div>
        <DayPicker
          className="Range"
          numberOfMonths={1}
          selectedDays={selectedDays}
          disabledDays={disabledDays}
          modifiers={modifiers}
          onDayClick={this.handleDayClick}
          onDayMouseEnter={this.handleDayMouseEnter}
        />
      </div>
    );
  }
}
