import React from "react";

import SideMenu from "../components/SideMenu/SideMenu.js";
import Landing from "../screens/Landing.js";

//import home pages for each iot devices
import Electricity from "./iot_homepages/Electricity.js";
import AirPollution from "./iot_homepages/AirPollution.js";
import TemperatureRelativeHumidity from "./iot_homepages/TRH.js";
import Covid from "./iot_homepages/Covid.js";
import DigitalAgriculture from "./iot_homepages/DigitalAgriculture.js";

//import constants to use in shared files
import { devices_codes, IMAGES } from "../resources/constants.js";

//import client functions
import { getDevices } from "../resources/DataAccessors.js";

/*
Page will render a side menu of IoT device on the left side.

If the user has multiple IoT device types, will default to first one available
in this order: electricity, air pollution.

If the user has no devices, should not select anything from the side menu and
render a no device / demo page.

On the right side of the page, a homepage for that device will appear.
*/
export default class Main extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      /*corresponds to the type of device that is  being viewed, use devices_codes
      in /resources/constants.js to set this
      */
      selectedIoTType: "",
      devices: this.convertDevicesToTypeMap(props.deviceIds),

      openAddDeviceModal: false,
      setAddDeviceModalTo: null,
    };
  }

  componentDidMount() {
    this.selectInitialTypes();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.deviceIds) !==
      JSON.stringify(prevProps.deviceIds)
    ) {
      let map = this.convertDevicesToTypeMap(this.props.deviceIds);
      this.setState({ devices: map });
    }

    if (this.props.isAuthenticated !== prevProps.isAuthenticated) {
      this.selectInitialTypes();
    }
  }

  setAddDeviceModalInfo = (showModal, modalType) => {
    this.setState({
      openAddDeviceModal: showModal,
      setAddDeviceModalTo: modalType,
    });
  };

  convertDevicesToTypeMap(devices) {
    let result = {};
    devices.forEach((device) => {
      if (!result[device.type]) {
        result[device.type] = [];
      }
      result[device.type].push(device);
    });
    return result;
  }

  selectInitialTypes() {
    let deviceIds = this.props.deviceIds;
    if (deviceIds && deviceIds.length > 0) {
      this.setState({ selectedIoTType: this.props.deviceIds[0].type });
    }
  }

  /*
    Type must come directly from /resources/constants.js OR from the DB
  */
  updateSelectedIoTType(type) {
    this.setState({ selectedIoTType: type });
  }

  /*
    Refresh the selected devices due to some change
  */
  updateDevices = async () => {
    let updatedDevices = await getDevices();
    this.props.updateDeviceIds(updatedDevices);
    this.setState({
      openAddDeviceModal: false,
      setAddDeviceModalTo: null,
    });
  };

  /*Returns the page to show when the user is NOT logged in*/
  renderLander() {
    return <Landing />;
  }

  /*Returns the page to show when the user IS logged in*/
  renderHome() {
    //TODO: create a default page when no devices are selected
    let page = null;

    let devices = this.state.devices[this.state.selectedIoTType] || [];
    //based on the selected iot type, render the homepage correspondingly
    switch (this.state.selectedIoTType) {
      case devices_codes.electricity:
        page = <Electricity devices={devices} />;
        break;
      case devices_codes.air:
        page = <AirPollution deviceIds={devices} />;
        break;
      case devices_codes.trh:
        page = (
          <TemperatureRelativeHumidity
            devices={devices}
            setAddDeviceModalInfo={this.setAddDeviceModalInfo}
            deviceCodes={devices_codes}
          />
        );
        break;
      case devices_codes.digitalAgriculture:
        page = <DigitalAgriculture devices={devices} />;
        break;
      case devices_codes.covid:
        page = <Covid deviceIds={devices} />;
        break;
      default:
        page = null;
        break;
    }

    if (devices.length === 0) {
      //return default page (where the user has no device registered)
      page = (
        <div className="default-iot-device-page">
          <div className="default-iot-device-page-text">
            Click on
            <br />
            <img src={IMAGES.addButton} alt="" />
            <br />
            on the bottom left to add a device!
          </div>
        </div>
      );
    } else {
      //based on the selected iot type, render the homepage correspondingly
      switch (this.state.selectedIoTType) {
        case devices_codes.electricity:
          page = <Electricity devices={devices} />;
          break;
        case devices_codes.air:
          page = <AirPollution deviceIds={devices} />;
          break;
        // trh page uses this.updataDevices
        case devices_codes.trh:
          page = (
            <TemperatureRelativeHumidity
              devices={devices}
              setAddDeviceModalInfo={this.setAddDeviceModalInfo}
              deviceCodes={devices_codes}
            />
          );
          break;
        case devices_codes.digitalAgriculture:
          page = <DigitalAgriculture devices={devices} />;
          break;
        case devices_codes.covid:
          page = <Covid devices={devices} />;
          break;
        default:
          page = null;
          break;
      }
    }
    return (
      <div className="main-container">
        {page}
        <SideMenu
          selectedIoTType={this.state.selectedIoTType}
          updateIoTType={(type) => this.updateSelectedIoTType(type)}
          deviceIds={this.props.deviceIds}
          updateDevices={this.updateDevices}
          openAddDeviceModal={this.state.openAddDeviceModal}
          setAddDeviceModalTo={this.state.setAddDeviceModalTo}
          setAddDeviceModalInfo={this.setAddDeviceModalInfo}
        />
      </div>
    );
  }

  render() {
    return this.props.isAuthenticated ? this.renderHome() : this.renderLander();
  }
}
