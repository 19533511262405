import React from "react";
import Status from "../../components/Status/Status.js";
import TRHInterface from "../../components/TRH/TRHInterface.js";
import TRHFloorplanInterface from "../../components/TRH/TRHFloorplanInterface.js";
import { Container, Row, Col } from "reactstrap";
import { getPrevDay, getNearbyDates } from "../../resources/constants.js";
import { Tab, Menu, Icon } from "semantic-ui-react";

export default class TemperatureRelativeHumidity extends React.Component {
  constructor(props) {
    super(props);

    let { today } = getNearbyDates();
    let yesterday = getPrevDay(today);

    this.state = {
      isChartLoading: false,
      tabIndex: 0,

      currentSelectedDay: today,
      pastSelectedDay: yesterday,
    };
  }

  setIsChartLoading = async (isLoading) => {
    await this.setState({ isChartLoading: isLoading });
  };

  render() {
    const panes = [
      {
        menuItem: (
          <Menu.Item
            key="home"
            style={{ fontSize: "18px", backgroundColor: "#ffe4e4" }}
          >
            <Icon name="home" />
            Home
          </Menu.Item>
        ),
        render: () => (
          <Row style={{ paddingBottom: "60px" }}>
            <TRHFloorplanInterface
              devices={this.props.devices}
              setAddDeviceModalInfo={this.props.setAddDeviceModalInfo}
              deviceCodes={this.props.deviceCodes}
            />
          </Row>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="temperature" style={{ fontSize: "18px" }}>
            <Icon name="line graph" />
            Temperature
          </Menu.Item>
        ),
        render: () => (
          <Row>
            <Col>
              <div className="you-vs-header">
                Let's explore your devices' temperature recordings.
              </div>
              {this.props.devices && (
                <TRHInterface
                  devices={this.props.devices}
                  setIsLoading={this.setIsChartLoading}
                  isLoading={this.state.isChartLoading}
                  label="temperature"
                />
              )}
            </Col>
          </Row>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="humidity" style={{ fontSize: "18px" }}>
            <Icon name="line graph" />
            Humidity
          </Menu.Item>
        ),
        render: () => (
          <Row>
            <Col>
              <div className="you-vs-header">
                Let's explore your devices' relative humidity recordings.
              </div>
              {this.props.devices && (
                <TRHInterface
                  devices={this.props.devices}
                  setIsLoading={this.setIsChartLoading}
                  isLoading={this.state.isChartLoading}
                  deviceNames={this.state.deviceName}
                  label="relative humidity"
                />
              )}
            </Col>
          </Row>
        ),
      },
    ];
    return (
      <div className="trh-container">
        <Status
          devices={this.props.devices}
          isSelectable={false}
          isCollapsable
        />
        <Container>
          <Tab
            panes={panes}
            activeIndex={this.state.tabIndex}
            onTabChange={(_, target) =>
              this.setState({ tabIndex: target.activeIndex })
            }
            style={{ paddingTop: "30px" }}
          />
        </Container>
      </div>
    );
  }
}
