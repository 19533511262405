import React, { Component } from "react";

// Menu Items
export default class Menu extends Component {
  render() {
    return (
      <nav
        className="navbar navbar-expand-lg navbar-dark fixed-top"
        id="mainNav"
      >
        <div className="container" style={navstyle}>
          <button
            className="navbar-toggler navbar-toggler-right"
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Menu
            <i className="fas fa-bars"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav text-uppercase">
              <li className="nav-item">
                <a className="nav-link js-scroll-trigger" href="#services">
                  Services
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link js-scroll-trigger" href="#mission">
                  Mission
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link js-scroll-trigger" href="#projects">
                  Projects
                </a>
              </li>
              {/*
              <li className="nav-item">
                <a className="nav-link js-scroll-trigger" href="#contact">
                  Contact
                </a>
              </li>
              */}
            </ul>

            <div className="navbar-nav text-uppercase ml-auto">
              <button
                id="login"
                className="btn btn-light"
                style={marginStyle}
                type="submit"
                onClick={() => this.props.goTo("/login")}
              >
                Log In
              </button>
              <button
                id="signup"
                className="btn btn-light"
                type="submit"
                onClick={() => this.props.goTo("/signup")}
              >
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

let navstyle = {
  marginTop: "-15px"
};

let marginStyle = {
  marginRight: "10px"
};
