import React from "react";
import { SingleComparator } from "../../components/Electricity/Comparator.js";

import { getNearbyDates, getPrevMonth, getPrevWeek, lineChartModes, IMAGES } from "../../resources/constants.js";
import { HorizontalBar } from 'react-chartjs-2';
import { Container, Row, Col } from 'reactstrap';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default class GoalPanel extends React.Component {
  constructor(props) {
    super(props);
    let { today, lastWeek, firstDayOfMonth } = getNearbyDates();

    this.state = {
      goal: props.goalPercentage,
      device: props.device,
      comparatorWeek: null,
      comparatorMonth: null,
      selectedDayRangeWeek: {
        startDate: lastWeek,
        endDate: today
      },
      pastDayRangeWeek: {
        startDate: getPrevWeek(lastWeek),
        endDate: lastWeek
      },
      selectedDayRangeMonth: {
        startDate: firstDayOfMonth,
        endDate: today
      },
      pastDayRangeMonth: {
        startDate: getPrevMonth(firstDayOfMonth),
        endDate: firstDayOfMonth
      },
      mode: lineChartModes.daily,
    }
    this.state.comparatorWeek = new SingleComparator(
      this.props.device,
      this.state.mode,
      this.state.selectedDayRangeWeek,
      this.state.pastDayRangeWeek
    );

    this.state.comparatorMonth = new SingleComparator(
      this.props.device,
      this.state.mode,
      this.state.selectedDayRangeMonth,
      this.state.pastDayRangeMonth
    );
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.device &&
      (!prevProps.device || this.props.device.id !== prevProps.device.id)
    ) {
      this.state.comparatorWeek.setDevice(this.props.device);
      this.state.comparatorMonth.setDevice(this.props.device);
      await this.refreshData();
    }
  }

  async componentDidMount() {
    await this.refreshData();
  }

  refreshData = async () => {
    await this.props.setIsLoading(true);

    await this.state.comparatorWeek.compareDevices();
    await this.state.comparatorMonth.compareDevices();

    await this.props.setIsLoading(false);
  };

  getBarColor(value) {
    return value > 0 ? "#d40b0b" : "#0ea808";
  }

  render() {
    let monthAvg = this.state.comparatorMonth.getAverageIncrease();
    let weekAvg = this.state.comparatorWeek.getAverageIncrease();

    let barData = {
      labels: ["Goal", "This Week", "This Month"],
      datasets: [
        {
          backgroundColor: ["#fff01f", this.getBarColor(weekAvg), this.getBarColor(monthAvg)],
          data: [this.props.goal * -1, weekAvg, monthAvg],
          // barThickness: 100,
        }
      ]
    }

    let progressData = {
      weekPercent: -weekAvg > this.props.goal ? 100 : (weekAvg >= 0 ? 0 : parseFloat(-weekAvg * 100 / this.props.goal)),
      weekText: -weekAvg > this.props.goal ? "You achieved your goal this week!" :
        ("You are " + (+this.props.goal + +weekAvg).toFixed(1) + "% away from your goal this week."),
      weekTrophy: -weekAvg > this.props.goal ? IMAGES.trophyLit : IMAGES.trophyGray,
      monthPercent: -monthAvg > this.props.goal ? 100 : (monthAvg >= 0 ? 0 : parseFloat(-monthAvg * 100 / this.props.goal)),
      monthText: -monthAvg > this.props.goal ? "You achieved your goal this month!" :
        ("You are " + (+this.props.goal + +monthAvg).toFixed(1) + "% away from your goal this month."),
      monthTrophy: -monthAvg > this.props.goal ? IMAGES.trophyLit : IMAGES.trophyGray,
    }

    let options = {
      legend: {
        display: false,
      },
      maintainAspectRatio: false,
      scales: {
        yAxes: [{
          ticks: {
            min: -25
          }
        }]
      }
    }

    return (
      <div className="goal-panel-wrapper custom-center">
        <Container style={{ paddingTop: 10 }}>
          <Row>
            <Col>
              {this.props.goal !== -1 &&
                <div>
                  <div style={{ width: 250 }}>
                    <HorizontalBar
                      height={175}
                      data={barData}
                      options={options}
                    />
                  </div>
                </div>
              }
            </Col>
            <Col>
              <div className="goal-panel-circular-progress-container">
                <img className="goal-panel-circular-progress-trophy" src={progressData.weekTrophy} alt="" />
                <div style={{ position: 'absolute' }}>
                  <CircularProgressbar
                    value={progressData.weekPercent}
                  />
                  <div className="goal-panel-circular-progress-text">
                    {progressData.weekText}
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="goal-panel-circular-progress-container">
                <img className="goal-panel-circular-progress-trophy" src={progressData.monthTrophy} alt="" />
                <div style={{ position: 'absolute' }}>
                  <CircularProgressbar
                    value={progressData.monthPercent}
                  />
                  <div className="goal-panel-circular-progress-text">
                    {progressData.monthText}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div >
    )
  }
}