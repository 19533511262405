import React from "react";
import OpenMap from "../../components/Maps/OpenMap";
import Controls from "../../components/Maps/Controls";
import "react-perfect-scrollbar/dist/css/styles.css";
import DataPoint from "../../components/Maps/DataPoint";
import { mapDisplayMode, markerType } from "../../components/Maps/constants";
import { displayTime, updatingInterval } from "../../resources/constants";

//import client functions to access data from the dbs
import {
  getAirPollutionReadings,
  getAlertsByDeviceID
} from "../../resources/DataAccessors.js";

import moment from "moment";

//import helper graph component
import { pageStyles } from "../../resources/constants.js";
import { MarkerInfo } from "../../components/Maps/MarkerInfo.js";

export default class AirPollution extends React.Component {
  /**
   * constructor
   * @param {Object} props { deviceIds = Array({ device_id, name, description, type, subtype(usually null) }) }
   */
  constructor(props) {
    super(props);
    this.deviceTrace = new Map(); // Map(key: {string} deviceId, value: {DataPoint[]} history and updated data)
    this.realtimeData = new Map();
    this.playbackData = new Map();
    this.alertData = new Map();
    let current_time = new Date();
    this.state = {
      reset: false, // negate this value whenever want to reset map
      reCenter: false, // has to negate this value whenever wants to center
      infoCard: null,
      deviceTrace: this.deviceTrace,
      lineChartData: this.realtimeData,
      alertData: this.alertData,
      playbackData: this.playbackData,
      markersToAdd: [], // these markers will be added to map (and sent to OpenMap class)
      currentPosition: [42.73, -76.78],
      devices: this.props.deviceIds,
      center: [42.73, -76.78],
      lastTrack: current_time.setMinutes(current_time.getMinutes() - 1), // get some initial data to display
      // specifies what to display and mode of display
      displayInfo: {
        displayMode: mapDisplayMode.realtime, // default to real time display
        hideDisplay: new Array(props.devicesIds ? props.devicesIds.length : 0) // {boolean[]} whether to display corresponding device or not, default to true
      },
      selectedDevices: null
    };
    this.playbackIndex = 0;
    this.playbackData = new Map(); // Map(key: {string} deviceId, value: {DataPoint[]} data points to playback)
  }

  /**
   * device trace is a record that maps from device id to data points
   */
  initializeDeviceTrace() {
    this.props.deviceIds.forEach(device => {
      this.deviceTrace.set(device.id, []);
    });
  }

  initializeRealtimeData() {
    this.props.deviceIds.forEach(device => {
      this.realtimeData.set(device.id, null);
    });
  }

  initializeAlertData() {
    this.props.deviceIds.forEach(device => {
      this.alertData.set(device.id, null);
    });
  }

  /**
   * similar to device trace, but this is for playback data points
   */
  initializePlaybackData() {
    this.props.deviceIds.forEach(device => {
      this.playbackData.set(device.id, null);
    });
  }

  /**
   * given a time period, update data points for all devices, and push them to device trace
   * @param {*} startDate
   * @param {*} endDate
   */
  async pullAndStoreDeivceData(startDate, endDate) {
    for (var i = 0; i < this.props.deviceIds.length; i++) {
      let data = await getAirPollutionReadings(
        startDate,
        endDate,
        this.props.deviceIds[i].id
      );
      data.datasetStyles = pageStyles.singleDatasetStyles;
      this.realtimeData.set(this.props.deviceIds[i].id, data);
      let response = await getAlertsByDeviceID(this.props.deviceIds[i].id);
      this.alertData.set(this.props.deviceIds[i].id, response[0].rules);
      let moving = this.deviceTrace.get(this.props.deviceIds[i].id);
      if (data.length !== 0 && data) {
        data.values.forEach(element => {
          if (element[2] < 180 && element[2] > -180) {
            let pValue = this.processDataPoint(element);
            moving.push(pValue);
          }
        });
      }
    }
  }

  /**
   * process row data
   * @param {Array} value in the order as this.props.deviceIds[i].data.values
   * @return {DataPoint} null if there is no data
   */
  processDataPoint = value => {
    try {
      var pValue = [...value];
      pValue[2] = this.DMS_to_DD(value[2]);
      pValue[3] = this.DMS_to_DD(value[3]);
      let position =
        pValue[2] < 180 && pValue[2] > -180
          ? [pValue[2], -pValue[3]]
          : [42.05, -76.05];
      pValue[2] = position[0];
      pValue[3] = position[1];
      let res = new DataPoint();
      res.setDataWithArray(pValue);
      return res;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  /**
   * Update devices(replace with new data) and corresponding deviceTrace(add to the corresponding this.deviceTrace array)
   * !!! this method can only do single update (data length == 1) !!!
   * !!! no setState is called in this method !!!
   * @param {string} deviceId - can be obtained from this.props.deviceIds[?].id
   * @param {in the format of data returned from getLatestAirPollutionData} data
   * @returns
   */
  updateDeviceWithId(deviceId, data) {
    try {
      let pData = this.processDataPoint(data.values);
      let device = this.getDeviceWithId(deviceId);
      try {
        device.data = pData;
        this.updateRealtimeData(deviceId, data);
        this.deviceTrace.get(deviceId).push(pData);
      } catch (e) {
        console.log(e);
      }
    } catch (e) {
      console.log(e);
    }
  }

  updateRealtimeData(deviceId, data) {
    if (data) {
      try {
        this.realtimeData.get(deviceId).values.shift();
        this.realtimeData.get(deviceId).values.push(data.values);
      } catch (e) {}
    }
  }

  /**
   * update hideDisplay state, default to set all elements in hideDisplay to true
   * @param {Integer} deviceInd - index of device in this.props.deviceIds array
   * @param {Boolean} isDisplay - whether to display the device or not
   * @returns
   */
  updateHideDisplay = (deviceInd = -1, isDisplay = true) => {
    let hideDisplay = this.state.displayInfo.hideDisplay;
    if (deviceInd === -1) {
      for (var i = 0; i < hideDisplay.length; i++) {
        hideDisplay[i] = true;
      }
    } else {
      hideDisplay[i] = isDisplay;
    }
  };

  /**
   * initialize every state
   */
  initializeStates() {
    this.initializeDeviceTrace();
    this.initializeRealtimeData();
    this.initializeAlertData();
    this.initializePlaybackData();
    this.setAllObjectStates();
  }

  /**
   * Get device from this.props.deviceIds array given deviceId
   * @param {string} deviceId - can be obtained from this.props.deviceIds[?].id
   * @returns {DeviceInfo} if device found, null otherwise
   */
  getDeviceWithId(deviceId) {
    let devices = this.state.devices;
    for (var i = 0; i < devices.length; i++) {
      if (devices[i].id === deviceId) {
        return devices[i];
      }
    }
    return null;
  }

  /**
   * call setState on every explicitly specified object in constructor
   */
  setAllObjectStates() {
    switch (this.state.displayInfo.displayMode) {
      case mapDisplayMode.realtime:
        this.setState({
          deviceTrace: this.deviceTrace,
          lineChartData: this.realtimeData,
          alertData: this.alertData
        });
        break;
      case mapDisplayMode.playback:
        this.setState({
          deviceTrace: this.deviceTrace,
          lineChartData: this.playbackData,
          alertData: this.alertData
        });
        break;
      default:
        this.setState({
          deviceTrace: this.deviceTrace,
          lineChartData: this.realtimeData,
          alertData: this.alertData
        });
        break;
    }
  }

  /**
   * playback data points of given device with start and end date
   */
  async generatePlaybackData(startDate, endDate) {
    const fstartDate = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
    const fendDate = moment(endDate).format("YYYY-MM-DD HH:mm:ss");
    const map = new Map();
    for (var i = 0; i < this.state.devices.length; i++) {
      let device = this.state.devices[i];
      let data = await getAirPollutionReadings(fstartDate, fendDate, device.id);
      map.set(device.id, data);
    }
    this.playbackData = map;
    return map;
  }

  pulledDataToMarkerInfo(data, deviceId, deviceName) {
    if (data === null || data.values === null) return null;
    console.log(data);
    return data.values.map(value => {
      const dataPoint = new DataPoint();
      dataPoint.setDataWithArray(value);
      return new MarkerInfo(
        deviceId,
        deviceName,
        dataPoint,
        markerType.connectedCircle
      );
    });
  }

  /**
   * create markers and update markersToAdd
   */
  updateRealtimeMarkers() {
    let markersToAdd = [];
    this.state.devices.forEach(device => {
      let marker = new MarkerInfo(
        device.id,
        device.name,
        device.data,
        markerType.connectedCircle
      );
      markersToAdd.push(marker);
    });
    this.setState({ markersToAdd: markersToAdd });
  }

  // called after update linechart data
  updateStaticPlaybackMarkers() {
    if (
      this.state.selectedDevices === null ||
      this.state.displayInfo.displayMode !== mapDisplayMode.playback
    )
      return;

    let device = this.state.selectedDevices;
    const markersToAdd = this.pulledDataToMarkerInfo(
      this.state.lineChartData.get(device.id),
      device.id,
      device.name
    );
    this.setState({ markersToAdd: markersToAdd });
  }

  updateStatic() {
    switch (this.state.displayInfo.displayMode) {
      case mapDisplayMode.playback:
        this.updateStaticPlaybackMarkers();
        break;
      case mapDisplayMode.realtime:
        break;
      default:
        break;
    }
  }

  /**
   * create markers and update markersToAdd (for playback mode)
   */
  updatePlaybackMarkers() {
    let markersToAdd = [];
    let device = this.state.selectedDevices;
    if (device != null) {
      let dataPoints = this.state.deviceTrace.get(device.id);
      if (this.playbackIndex < dataPoints.length) {
        markersToAdd.push(
          new MarkerInfo(
            device.id,
            device.name,
            dataPoints[this.playbackIndex],
            markerType.connectedCircle
          )
        );
        this.playbackIndex++;
      }
    }
    try {
      let lat = markersToAdd[0].data.latitude;
      let lng = markersToAdd[0].data.longitude;
      this.setState({
        markersToAdd: markersToAdd
      });
      this.centerTo(lat, lng);
    } catch (e) {}
  }

  updateMap() {
    switch (this.state.displayInfo.displayMode) {
      case mapDisplayMode.realtime:
        let realtimeData = new Map(this.realtimeData);
        this.setState({ lineChartData: realtimeData });
        this.updateRealtimeMarkers();
        break;
      default:
        break;
    }
  }

  /**
   * React lifecycle method
   * Retrive data of all devices in this.props.deviceIds between startDate and endDate and store them in state.dataTrace
   * Update every 1 second to corresponding fields
   */
  async componentDidMount() {
    console.log("MOUNTE");
    this.initializeStates();

    let startDate = new Date();
    let endDate = new Date();
    startDate.setMinutes(startDate.getMinutes() - displayTime);
    let fstartDate = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
    let fendDate = moment(endDate).format("YYYY-MM-DD HH:mm:ss");

    await this.pullAndStoreDeivceData(fstartDate, fendDate);
    this.setAllObjectStates();
    await this.fetchAndUpdateWithLatestData();
    this.timer = setInterval(async () => {
      await this.fetchAndUpdateWithLatestData();
    }, updatingInterval);
  }

  async fetchAndUpdateWithLatestData() {
    for (var i = 0; i < this.props.deviceIds.length; i++) {
      await this.getLatestAirPollutionData(this.props.deviceIds[i].id);
      let response = await getAlertsByDeviceID(this.props.deviceIds[i].id);
      this.alertData.set(this.props.deviceIds[i].id, response[0].rules);
    }
    this.updateMap();
    this.setState({ lastTrack: new Date() });
  }

  /**
   * parse the GPS data from hardware format to leaflet format
   * @param {*} d  GPS data
   */
  DMS_to_DD(d) {
    var degree = ("" + d).split(".")[0];
    var minutes = ("" + d).split(".")[1];
    minutes = minutes.slice(0, 2) + "." + minutes.slice(2);
    return Number(degree) + Number(minutes) / 60;
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  /* render line chart of data with format in processDataPoints */
  renderLineChart = data => {};

  /**
   * The callback to change the center of map to selected device
   */
  handleDeviceSelect = device => {
    this.setState({ selectedDevices: device }, () => {
      try {
        switch (this.state.displayInfo.displayMode) {
          case mapDisplayMode.realtime:
            if (device.data != null) {
              this.centerTo(device.data.latitude, device.data.longitude);
            }
            // this.setState({ center: [device.data.latitude, device.data.longitude] || this.state.currentPosition });
            break;
          case mapDisplayMode.playback:
            this.playbackIndex = 0;
            this.resetMap();
            break;
          default:
            break;
        }
      } catch (e) {
        console.log(e);
        // console.log("selected device does not have data");
      }
    });
    // device.data might be null
  };

  resetMap() {
    this.setState({ reset: !this.state.reset });
  }

  // handles play back button click in Controls
  handlePlayback = async playbackRange => {
    const { start, end } = playbackRange;
    this.generatePlaybackData(start, end).then(() => {
      this.setState(
        {
          lineChartData: this.playbackData,
          displayInfo: {
            displayMode: mapDisplayMode.playback,
            hideDisplay: this.state.displayInfo.hideDisplay
          }
        },
        () => {
          this.resetMap();
          this.updateStaticPlaybackMarkers();
        }
      );
    });
  };

  handleRealTime = () => {
    this.setState(
      {
        lineChartData: this.realtimeData,
        displayInfo: {
          displayMode: mapDisplayMode.realtime,
          hideDisplay: this.state.displayInfo.hideDisplay
        }
      },
      () => {
        this.resetMap();
      }
    );
  };

  centerTo(lat, lng) {
    if (lat != null && lng != null) {
      this.setState({
        center: [lat, lng],
        reCenter: !this.state.reCenter
      });
    }
  }

  async getLatestAirPollutionData(deviceId) {
    // set endDate to current date and startDate to last track
    let endDate = new Date();
    let startDate = this.state.lastTrack;

    //format dates for queries
    let fstartDate = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
    let fendDate = moment(endDate).format("YYYY-MM-DD HH:mm:ss");

    //make call to db
    let data = await getAirPollutionReadings(fstartDate, fendDate, deviceId);
    if (data.length === 0) {
      return null;
    }
    data.values.forEach(value => {
      var one_data = JSON.parse(JSON.stringify(data));
      one_data.datasetStyles = pageStyles.singleDatasetStyles;
      one_data.values = value;
      this.updateDeviceWithId(deviceId, one_data);
    });

    return data;
  }

  /*
  Given a list of air pollution deviceIds, display an overview of all device data
  */
  render() {
    return (
      <div className="airpollution-container">
        <div className="airpollution-map-container">
          <Controls
            devices={this.props.deviceIds}
            onDeviceSelect={this.handleDeviceSelect}
            lineChartData={this.state.lineChartData}
            handleDisplayHide={this.handleDisplayHide}
            handlePlayback={this.handlePlayback}
            handleRealTime={this.handleRealTime}
            alertData={this.state.alertData}
          />
          <div className="airpollution-line-map-container">
            <div className="airpollution-map-container">
              {
                <OpenMap
                  center={this.state.center}
                  devices={this.props.deviceIds}
                  addMarkers={this.state.markersToAdd}
                  reset={this.state.reset}
                  reCenter={this.state.reCenter}
                />
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
