import React from "react";
import ReactLoading from "react-loading";
import { Icon, Button } from "semantic-ui-react";
import { Tooltip } from "@material-ui/core";
import { Modal, ModalBody } from "reactstrap";
import { deviceTypesNames, pageStyles } from "../../resources/constants.js";
import AddElectricity from "../SideMenu/AddDeviceByTypes/AddElectricity.js";
//takes in list of DeviceInfo objects
export default class StatusCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lastSeen: null,
      isLoading: true,
      surveyResults: undefined,
      openEditModal: false,
    };
  }

  /*
    TODO: use axios to cancel asynchronous call on unmount
    also change interval based on the last time we saw the device, (devices
    that haven't been seen don't need to be checked as frequently)
  */
  async componentDidMount() {
    await this.setState({ isLoading: true });
    await this.refreshDeviceStatus();
    /*when this card is visible, refresh the last seen date every 30 seconds*/

    //commenting out timer to prevent over accessing our db
    // this.timer = setInterval(async () => {
    //   await this.refreshDeviceStatus();
    // }, 1000 * 60 * 30);



    await this.setState({ isLoading: false, surveyResults: this.computeSurveyResults() });
  }

  componentWillUnmount() {
    //clearInterval(this.timer);
  }

  async refreshDeviceStatus() {
    await this.props.device.refreshDeviceStatus();
  }

  /**
   * Method to convert unicodes in strings to corresponding characters.
   * @param {*} text the string to convert.
   * @returns formatted text, or null if text is null.
   */
  unicodeToChar(text) {
    if (text == null) {
      return null;
    }
    return text.replace(/\\u[\dA-F]{4}/gi,
      function (match) {
        return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
      });
  }

  /**
   * Processes survey answers and questions given.
   * @returns a list of tuples in the format of (icon, short question, answer, unit).
   * If no survey answers/questions are passed in, return undefined.
   */
  computeSurveyResults = () => {
    if (this.props.surveyAnswers && this.props.surveyQuestions) {
      const surveyResults = [];
      for (var key in this.props.surveyQuestions) {
        if (this.props.surveyAnswers[key] && this.props.surveyAnswers[key] !== "") {
          if (this.props.surveyQuestions[key].question_type === "select") {
            surveyResults.push([this.props.surveyQuestions[key].survey_icon,
            this.props.surveyQuestions[key].short_question,
            this.props.surveyQuestions[key].answer_options[this.props.surveyAnswers[key]],
            this.unicodeToChar(this.props.surveyQuestions[key].survey_unit)]);
          }
          else {
            surveyResults.push([this.props.surveyQuestions[key].survey_icon,
            this.props.surveyQuestions[key].short_question,
            this.props.surveyAnswers[key],
            this.unicodeToChar(this.props.surveyQuestions[key].survey_unit)]);
          }
        }
      }
      return surveyResults;
    }
    else {
      return undefined;
    }
  }

  renderExpanded(isOffline) {
    let lastSeenClass = "status-card-bottom ";
    if (isOffline) {
      lastSeenClass += "status-card-last-seen-date-offline";
    } else {
      lastSeenClass += "status-card-last-seen-date-online";
    }

    const Divider = () => (
      <hr style={{ border: '0.2px solid rgb(200, 200, 200)', width: '100%' }} />
    );

    return (
      <div
        className={`status-card-container${this.props.selected ? " status-card-selected" : ""
          }${this.props.enabled ? "" : " disabled"}`}
        onClick={() => this.props.onClick()}
      >
        <Modal
          isOpen={this.state.openEditModal}
          toggle={() => this.setState({ openEditModal: false })}
          style={pageStyles.editDeviceModal}
          backdrop={"static"}
          keyboard={false}
          className="add-device-modal"
        >
          <div className="add-device-header">
            Edit Survey Responses
          </div>
          <ModalBody className="add-form-modal-body">
            <AddElectricity
              onComplete={() => {
                this.props.refreshSurveyAnswers(() => this.setState({ openEditModal: false, surveyResults: this.computeSurveyResults() }));
              }}
              showSurvey={true}
              deviceId={this.props.device.id} />
          </ModalBody>
          <div className="add-device-footer">
            <div
              className="add-device-close add-device-button"
              onClick={() => this.setState({ openEditModal: false })}
            >
              Close
            </div>
          </div>
        </Modal>
        <div className="status-card-top">
          {/* edit button currently exclusive to electricity */}
          {deviceTypesNames[this.props.device.type] === "Electricity" &&
            (<Tooltip title={<div style={{ fontSize: '12px' }}>Edit your survey responses</div>} arrow>
              {<Button circular icon="edit"
                className="status-card-edit-button"
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({ openEditModal: true })
                }} />}
            </Tooltip>)}
          <div className="status-card-name">{this.props.device.name}</div>
          {this.props.device.description && (
            <div className="status-card-description">
              {this.props.device.description}
            </div>
          )}
          {this.state.surveyResults && this.state.surveyResults.length > 0 && (
            <Divider />
          )}
          {this.state.surveyResults && this.state.surveyResults.map((item, i) => {
            return (<div key={"survey" + i}>
              <Icon name={item[0]} />
              {item[3] != null ? `${item[1]}: ${item[2]} ${item[3]}` : `${item[1]}: ${item[2]}`}
            </div>)
          })}
        </div>
        <div className={lastSeenClass}>
          {!this.state.isLoading ? (
            this.props.device.statusText()
          ) : (
            <ReactLoading type={"spokes"} width={"20px"} height={"20px"} />
          )}
        </div>
      </div>
    );
  }

  renderMinimized(isOffline) {
    let isOfflineClass = isOffline
      ? "status-card-last-seen-date-offline"
      : "status-card-last-seen-date-online";

    let deviceStatusCircle = (
      <div className={"mini-status-circle " + isOfflineClass} />
    );

    return (
      <div
        className={`mini-status-card-container${this.props.selected ? " status-card-selected" : ""
          }${this.props.enabled ? "" : " disabled"}`}
        onClick={() => this.props.onClick()}
      >
        <div className="status-card-name">{this.props.device.name}</div>
        <div className={"mini-status-card-right"}>
          {!this.state.isLoading ? (
            deviceStatusCircle
          ) : (
            <ReactLoading type={"spokes"} width={"20px"} height={"20px"} />
          )}
        </div>
      </div>
    );
  }

  render() {
    let isOffline = this.props.device.isOffline();
    return this.props.selected
      ? this.renderExpanded(isOffline)
      : this.renderMinimized(isOffline);
  }
}
