import { ElectricityHandler } from "./ElectricityHandler.js";
import { getDaysString } from "../../resources/constants.js";
import { aggregateDevice } from "./utils.js";
import DeviceInfo from "../Maps/DeviceInfo.js";
/*
A = present
B = past

How to use:
Pass in two Device objects (class defined within this file). Synchronously call compareDevices
with on the Comparator object, and then you can access the following fields:

averageA
averageB
maxA
maxB
averageIncrease
maxIncrease
exceedDays
*/
export class Comparator {
  constructor(deviceA, dateRangeA, deviceB, dateRangeB, mode) {
    this._deviceA = new Device(deviceA, dateRangeA);
    this._deviceB = new Device(deviceB, dateRangeB);

    this._mode = mode;
    this._isAOutdated = true;
    this._isBOutdated = true;
    this._handlerA = null;
    this._handlerB = null;
    this._averageIncrease = -1;
    this._maxIncrease = -1;
    this._exceedDays = [];
  }

  toString() {
    return `mode: ${this._mode}
            isAOutdated: ${this._isAOutdated}
            isBOutdated: ${this._isBOutdated}
            averageIncrease: ${this._averageIncrease}
            maxIncrease: ${this._maxIncrease}
            exceedDays: ${this.exceedDays}
            deviceA: ${this._deviceA.toString()}
            deviceB: ${this._deviceB.toString()}
            `;
  }

  getDeviceA() {
    return this._deviceA;
  }

  getDeviceB() {
    return this._deviceB;
  }

  async setDevices(deviceA, dateRangeA, deviceB, dateRangeB) {
    this.setDeviceA(deviceA, dateRangeA);
    this.setDeviceB(deviceB, dateRangeB);
  }

  setDeviceA(deviceA, dateRange) {
    let device = new Device(deviceA, dateRange);

    this._deviceA = device;
    this._isAOutdated = true;
  }

  setDeviceB(deviceB, dateRange) {
    let device = new Device(deviceB, dateRange);
    this._deviceB = device;
    this._isBOutdated = true;
  }

  setMode(mode) {
    if (mode === this._mode) return;

    this._mode = mode;

    this._isAOutdated = true;
    this._isBOutdated = true;
  }

  async compareDevices() {
    if (!this._isAOutdated && !this._isBOutdated) {
      return;
    }

    if (this._isAOutdated) {
      this._handlerA = new ElectricityHandler(
        this._deviceA.dateRange,
        this._deviceA.device,
        this._mode
      );

      await this._handlerA.load();
      this._isAOutdated = false;
    }

    if (this._isBOutdated) {
      this._handlerB = new ElectricityHandler(
        this._deviceB.dateRange,
        this._deviceB.device,
        this._mode
      );

      await this._handlerB.load();
      this._isBOutdated = false;
    }

    let handlerA = this._handlerA;
    let handlerB = this._handlerB;

    let averageIncrease = (
      ((handlerA.average - handlerB.average) / handlerB.average) *
      100
    ).toFixed(1);

    let maxIncrease = (
      ((handlerA.max - handlerB.max) / handlerB.max) *
      100
    ).toFixed(1);

    if (handlerA.data && handlerB.data) {

      let handlerAValues = handlerA.data.getValues();
      let handlerBValues = handlerB.data.getValues();

      let exceedDays = [];
      let min = Math.min(handlerAValues.length, handlerBValues.length);
      for (let i = 0; i < min; i++) {
        let presentPower = handlerAValues[i].power;
        let pastPower = handlerBValues[i].power;
        if (presentPower > pastPower) {
          exceedDays.push(i);
        }
      }
      this._exceedDays = getDaysString(exceedDays);
    }
    this._averageIncrease = averageIncrease;
    this._maxIncrease = maxIncrease;
  }

  areValuesPresent(handler) {
    return (
      handler &&
      handler.data &&
      handler.data.getValues() &&
      handler.data.getValues().length > 0
    );
  }

  areAValuesPresent() {
    return this.areValuesPresent(this._handlerA);
  }

  areBValuesPresent() {
    return this.areValuesPresent(this._handlerB);
  }

  getAElectricityData() {
    return this._handlerA ? this._handlerA.data : null;
  }

  getBElectricityData() {
    return this._handlerB ? this._handlerB.data : null;
  }

  getExceedDays() {
    return this._exceedDays;
  }

  getAverageIncrease() {
    return this._averageIncrease;
  }

  getMaxIncrease() {
    return this._maxIncrease;
  }

  getAverageA() {
    return this._handlerA ? (this._handlerA.average / 1000).toFixed(2) : -1;
  }

  getAverageB() {
    return this._handlerB ? (this._handlerB.average / 1000).toFixed(2) : -1;
  }

  getMaxA() {
    return this._handlerA ? (this._handlerA.max / 1000).toFixed(2) : -1;
  }

  getMaxB() {
    return this._handlerB ? (this._handlerB.max / 1000).toFixed(2) : -1;
  }

  getElectricityCostA() {
    return this._handlerA ? this._handlerA.electricityCost.toFixed(1) : -1;
  }

  getElectricityCostB() {
    return this._handlerB ? this._handlerB.electricityCost.toFixed(1) : -1;
  }

  getCarbonEmissionsA() {
    return this._handlerA ? this._handlerA.carbonEmissions.toFixed(1) : -1;
  }

  getCarbonEmissionsB() {
    return this._handlerB ? this._handlerB.carbonEmissions.toFixed(1) : -1;
  }

  getTimeAtMaxA() {
    return this._handlerA ? this._handlerA.timeAtMax : null;
  }

  getTimeAtMaxB() {
    return this._handlerB ? this._handlerB.timeAtMax : null;
  }
}

class Device {
  constructor(device, dateRange) {
    this.device = device;
    this.dateRange = {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate
    };
  }

  toString() {
    return `device: ${this.device}
            dateRange: ${JSON.stringify(this.dateRange)}`;
  }

  isSame(device) {
    return (
      this.device.toString() === device.toString() &&
      JSON.stringify(this.dateRange) === JSON.stringify(device.dateRange)
    );
  }
}

export class SingleComparator extends Comparator {
  constructor(device, mode, nowDateRange, pastDateRange) {
    super(device, nowDateRange, device, pastDateRange, mode);
  }

  setDevice(device) {
    let deviceA = this.getDeviceA();
    let deviceB = this.getDeviceB();
    this.setDevices(device, deviceA.dateRange, device, deviceB.dateRange);
  }

  setNowDateRange(dateRange) {
    this.setDeviceA(this.getDeviceA().device, dateRange);
  }

  setPastDateRange(dateRange) {
    this.setDeviceB(this.getDeviceB().device, dateRange);
  }

  getNowData() {
    return this.getAElectricityData();
  }

  getPastData() {
    return this.getBElectricityData();
  }
}

export class AggregateComparator extends Comparator {
  constructor(device, mode, dateRange) {
    super(
      device,
      dateRange,
      new DeviceInfo(null, device.id, null, null, aggregateDevice),
      dateRange,
      mode
    );
  }

  getNowData() {
    return this.getAElectricityData();
  }

  getAggregateData() {
    return this.getBElectricityData();
  }

  setDevice(device) {
    let deviceA = this.getDeviceA();
    let deviceB = new DeviceInfo(null, device.id, null, null, aggregateDevice);

    this.setDevices(device, deviceA.dateRange, deviceB, deviceA.dateRange);
  }

  setDateRange(dateRange) {
    let deviceA = this.getDeviceA().device;

    this.setDevices(deviceA, dateRange, this.getDeviceB().device, dateRange);
  }

  getAggregateCalculatedValues() {
    return {
      average: this.getAverageB(),
      max: this.getMaxB(),
      electricityCost: this.getElectricityCostB(),
      carbonEmissions: this.getCarbonEmissionsB()
    };
  }

  getNowCalculatedValues() {
    return {
      average: this.getAverageA(),
      max: this.getMaxA(),
      electricityCost: this.getElectricityCostA(),
      carbonEmissions: this.getCarbonEmissionsA()
    };
  }
}
