import { markerType } from "./constants";

export class MarkerInfo {
  constructor(id, name, data, type = markerType.default) {
    this.name = name;
    this.id = id;
    this.data = data;
    this.type = type;
  }
}
