import React from "react";

import { Modal, ModalBody } from "reactstrap";
//import client functions that help me insert data into the db

import AddElectricity from "./AddDeviceByTypes/AddElectricity.js";
import AddDefault from "./AddDeviceByTypes/AddDefault.js";
import {
  pageStyles,
  devices_codes,
  deviceTypesColors,
  deviceTypesNames,
} from "../../resources/constants.js";

import "../../assets/css/AddDevice.css";

/*
To add an additional form for a device....

add the device code to the list this._availableAdditionalForms

add the submit function to submitAdditionalForm in the switch statement
under the device code you are adding
*/

export default class AddDevice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      visiblePage: null,
    };
  }

  handleBack = () => {
    this.setState({ visiblePage: null });
  };

  handleClose = () => {
    this.setState({ isOpen: false, visiblePage: null });
    this.props.setAddDeviceModalInfo(false, null);
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.openAddDeviceModal !== this.props.openAddDeviceModal ||
      prevProps.setAddDeviceModalTo !== this.props.setAddDeviceModalTo
    ) {
      this.setState({
        isOpen: this.props.openAddDeviceModal,
        visiblePage: this.props.setAddDeviceModalTo,
      });
    }
  }

  getDefault() {
    return (
      <AddDefault
        hasDeviceBeenRegistered={this.props.hasDeviceBeenRegistered}
        onAddDevice={this.props.onAddDevice}
        onComplete={() => this.handleClose()}
      />
    );
  }

  getDeviceSelectionButton(deviceType) {
    return (
      <DeviceButton
        key={deviceType}
        name={deviceTypesNames[deviceType]}
        type={deviceType}
        onClick={() =>
          this.setState({ visiblePage: devices_codes[deviceType] })
        }
      />
    );
  }

  getVisiblePage() {
    switch (this.state.visiblePage) {
      case devices_codes.electricity:
        //switch to electricity specific page
        return (
          <AddElectricity
            hasDeviceBeenRegistered={this.props.hasDeviceBeenRegistered}
            onAddDevice={this.props.onAddDevice}
            onComplete={() => this.handleClose()}
          />
        );
      case devices_codes.air:
        //switch to air page
        return this.getDefault();
      case devices_codes.trh:
        //switch to trh page
        return this.getDefault();
      case devices_codes.water:
        //switch to water page
        return this.getDefault();
      case devices_codes.covid:
        return this.getDefault();
      case devices_codes.digitalAgriculture:
        return this.getDefault();
      default:
        return this.renderDeviceTypesPage();
    }
  }

  renderDeviceTypesPage() {
    let devices = [];
    for (let type in devices_codes) {
      let deviceDiv = this.getDeviceSelectionButton(type);
      devices.push(deviceDiv);
    }

    return <div className="add-device-type-wrapper"> {devices} </div>;
  }

  render() {
    return (
      <div
        className="sidemenu-add-button"
        onClick={() => this.setState({ isOpen: !this.state.isOpen })}
      >
        +<div className="sidemenu-button-label"> {"Add New Device"} </div>
        <Modal
          isOpen={this.state.isOpen}
          toggle={this.handleClose}
          style={pageStyles.editDeviceModal}
          backdrop={"static"}
          keyboard={false}
          className="add-device-modal"
        >
          <div className="add-device-header">
            {this.state.visiblePage && !this.props.setAddDeviceModalTo && (
              <div className="add-device-back-button" onClick={this.handleBack}>
                {"←"}
              </div>
            )}
            Add Device
          </div>
          <ModalBody className="add-form-modal-body">
            {this.getVisiblePage()}
          </ModalBody>
          <div className="add-device-footer">
            <div
              className="add-device-close add-device-button"
              onClick={this.handleClose}
            >
              Close
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const DeviceButton = (props) => {
  let style = { backgroundColor: deviceTypesColors[props.type] };
  return (
    <div
      className="add-device-type-container"
      style={style}
      key={props.type}
      onClick={props.onClick}
    >
      {props.name}
    </div>
  );
};
