import React from "react";
import { landingFields } from "../../resources/constants";
// Our Service Section
export default () => {
  return (
    <section className="page-section" id="services">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading text-uppercase">Services</h2>
            <h3 className="section-subheading text-muted">
              {landingFields.services.subtitle}
            </h3>
          </div>
        </div>
        <div className="row text-center">
          <div className="col-md-4">
            <span className="fa-stack fa-4x">
              <i className="fa fa-circle fa-stack-2x text-primary"></i>
              <i className="fa fa-cloud fa-stack-1x fa-inverse"></i>
            </span>
            <h4 className="service-heading">
              {landingFields.services.column1.title}
            </h4>
            <p className="text-muted">{landingFields.services.column1.body}</p>
          </div>
          <div className="col-md-4">
            <span className="fa-stack fa-4x">
              <i className="fa fa-circle fa-stack-2x text-primary"></i>
              <i className="fa fa-laptop fa-stack-1x fa-inverse"></i>
            </span>
            <h4 className="service-heading">
              {landingFields.services.column2.title}
            </h4>
            <p className="text-muted">{landingFields.services.column2.body}</p>
          </div>
          <div className="col-md-4">
            <span className="fa-stack fa-4x">
              <i className="fa fa-circle fa-stack-2x text-primary"></i>
              <i className="fa fa-lock fa-stack-1x fa-inverse"></i>
            </span>
            <h4 className="service-heading">
              {landingFields.services.column3.title}
            </h4>
            <p className="text-muted">{landingFields.services.column3.body}</p>
          </div>
        </div>
      </div>
    </section>
  );
};
