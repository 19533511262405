import React, { Component } from "react";
import Routes from "./Routes";
import { withRouter } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Modal
} from "reactstrap";
import Amplify from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import Analytics from "@aws-amplify/analytics";
import { getDevicesByEmail, getTagsByEmail } from "./resources/DataAccessors";
import { pageStyles } from "./resources/constants";
import Login from "./screens/Login";
import SignUp from "./screens/SignUp.js";
import DeviceInfo from "./components/Maps/DeviceInfo.js";
import aws_exports from "./aws-exports";
import Menu from "./components/LandingPage/Menu";
import { MenuNavScroll } from "./index.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/agency.min.css";
import "./assets/css/styles.css";
import Leaflet from "leaflet";

Leaflet.Icon.Default.imagePath =
  "//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/";
Amplify.configure(aws_exports);
Analytics.disable();

let modalId = {
  login: "login",
  signUp: "signup"
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      deviceIds: [],
      userTags: [], // all the tags defined by the current user
      modalId: null
    };
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  };

  updateDeviceIds = devices => {
    let deviceIds = this.convertToDeviceInfo(devices);
    this.setState({ deviceIds: deviceIds });
  };

  updateUserTags = newTags => {
    this.setState({ userTags: newTags });
  };

  convertToDeviceInfo = deviceIds => {
    return deviceIds.map(device => {
      return new DeviceInfo(
        device.name,
        device.device_id,
        device.description,
        null,
        device.type,
        device.last_seen
      );
    });
  };

  async componentDidMount() {
    try {
      //on mount, check if user has logged in before and try to sign in
      let user = await Auth.currentAuthenticatedUser();
      let deviceInformation = await getDevicesByEmail(user.attributes.email);
      this.updateDeviceIds(deviceInformation);
      let tagInformation = await getTagsByEmail(user.attributes.email, null);
      this.updateUserTags(tagInformation);
      this.userHasAuthenticated(true);
    } catch (e) {
      //stay at login screen
    }

    this.setState({ isAuthenticating: false });
  }

  handleLogout = async event => {
    await Auth.signOut();
    this.userHasAuthenticated(false);
  };

  openModal(destination) {
    if (destination === "/signup") {
      this.setState({ modalId: modalId.signUp });
    } else if (destination === "/login") {
      this.setState({ modalId: modalId.login });
    }
  }

  render() {
    //pass these props directly to any child component
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      deviceIds: this.state.deviceIds,
      updateDeviceIds: this.updateDeviceIds,
      userTags: this.state.userTags,
      updateUserTags: this.updateUserTags
    };
    return (
      !this.state.isAuthenticating && (
        <div className={"outer-container"}>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
          {this.state.isAuthenticated ? (
            <Navbar className="navbar-custom" expand="md">
              <NavbarBrand
                className="nav-justified navbar-brand-custom"
                href="/"
              >
                <div>Engaged IoT</div>
              </NavbarBrand>
              <NavbarToggler onClick={() => {}} />
              <Collapse isOpen={true} navbar>
                <Nav className="ml-auto" navbar>
                  <NavItem
                    onClick={this.handleLogout}
                    className="nav-button-custom"
                  >
                    Logout
                  </NavItem>
                </Nav>
              </Collapse>
            </Navbar>
          ) : (
            <>
              <MenuNavScroll />
              <Menu goTo={destination => this.openModal(destination)} />
            </>
          )}
          <Modal
            isOpen={this.state.modalId === modalId.login}
            toggle={() => this.setState({ modalId: null })}
            style={{ opacity: 1 }}
            centered
          >
            <div style={pageStyles.modal}>
              <Login
                {...childProps}
                closeModal={() => {
                  this.setState({ modalId: null });
                }}
              />
            </div>
          </Modal>
          <Modal
            isOpen={this.state.modalId === modalId.signUp}
            toggle={() => this.setState({ modalId: null })}
            centered
          >
            <div style={pageStyles.modal}>
              <SignUp
                {...childProps}
                closeModal={() => this.setState({ modalId: null })}
              />
            </div>
          </Modal>
          <Routes childProps={childProps} />
        </div>
      )
    );
  }
}

export default withRouter(App);
