import React from "react";
import ElectricityChart from "../Electricity/ElectricityChart";

import CalendarWrapper from "../Calendar/CalendarWrapper";
import RangeCalendar from "../Calendar/RangeCalendar";
import SimpleCalendar from "../Calendar/SimpleCalendar";

import { AggregateComparator } from "./Comparator.js";

import {
  lineChartModes,
  getNearbyDates
} from "../../resources/constants.js";

import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";

export default class YouVsOthers extends React.Component {
  constructor(props) {
    super(props);
    let { today, lastWeek } = getNearbyDates();
    // device id will be passed in through props
    this.state = {
      comparator: null,
      startDate: props.startDate || lastWeek,
      endDate: props.endDate || today,
      data: null,
      mode: props.mode || lineChartModes.daily
    };

    this.state.comparator = new AggregateComparator(
      this.props.device,
      this.state.mode,
      { startDate: this.state.startDate, endDate: this.state.endDate }
    );
  }

  async componentDidMount() {
    await this.refreshData();
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.device &&
      (!prevProps.device || this.props.device.id !== prevProps.device.id)
    ) {
      await this.initializeDevices();
      await this.refreshData();
    }
  }

  initializeDevices() {
    this.state.comparator.setDevice(this.props.device);
  }

  async swapToDaily() {
    let { startDate, endDate } = this.state;
    await this.setMode(lineChartModes.daily, startDate, endDate);
  }

  async swapToHourly() {
    await this.setMode(lineChartModes.hourly, this.state.startDate, null);
  }

  async setMode(mode, startDate, endDate) {
    await this.setState({ mode: mode });
    await this.calendarSelect(startDate, endDate);
    await this.refreshData();
  }

  async refreshData() {
    await this.props.setIsLoading(true);
    let { mode, startDate, endDate } = this.state;
    this.state.comparator.setMode(mode);
    this.state.comparator.setDateRange({
      startDate: startDate,
      endDate: endDate
    });

    await this.state.comparator.compareDevices();
    this.state.comparator.getNowData().setName("your");
    this.state.comparator.getAggregateData().setName("other buildings");

    let nowData = this.state.comparator.getNowData();
    let aggregateData = this.state.comparator.getAggregateData();
    let data = [];
    if (nowData.getValues().length === 0) {
      data = [aggregateData];
    } else {
      data = [nowData, aggregateData];
    }

    await this.setState({ data: data });

    await this.props.setIsLoading(false);
  }

  async calendarSelect(start, end) {
    let formattedStartDate = moment(start).format("YYYY-MM-DD");
    let formattedEndDate =
      end == null ? formattedStartDate : moment(end).format("YYYY-MM-DD");

    await this.setState({
      startDate: formattedStartDate,
      endDate: formattedEndDate
    });
    await this.refreshData();
  }

  renderCalendar() {
    if (this.state.mode === lineChartModes.daily)
      return (
        <RangeCalendar
          onSelect={(start, end) => this.calendarSelect(start, end)}
          from={this.state.startDate}
          to={this.state.endDate}
        />
      );
    else {
      return (
        <SimpleCalendar
          onSelect={date => this.calendarSelect(date, null)}
          from={this.state.startDate}
          to={this.state.endDate}
        />
      );
    }
  }

  render() {

    return (
      <div className="you-vs-container">
        <div className="you-vs-header">
          How are you doing compared to other similar buildings?
        </div>
        <div className="linechart-calendar">
          <div className="linechart-left">
            <ElectricityChart
              isLoading={this.props.isLoading}
              data={this.state.data}
              xAccessor={d => d.timestamp}
              yAccessorsAndLabel={[{ yAccessor: d => d.power, label: "power" }]}
              formatXAxis={time => this.state.mode.format(time)}
              formatYAxis={value => value + " kWh"}
              formatter={value => (value / 1000).toFixed(1)}
              xAxisLabel="Date"
              yAxisLabel="kw"
            />
          </div>
          <div className="right-calendar">
            <CalendarWrapper
              title="Selected Period"
              from={this.state.startDate}
            >
              {this.renderCalendar()}
            </CalendarWrapper>
          </div>
        </div>
      </div>
    );
  }
}
