import React from "react";
import { Route, Switch } from "react-router-dom";
//import Home from "./screens/Home";
import Main from "./screens/Main";
import NotFound from "./screens/NotFound";
import AppliedRoute from "./components/AppliedRoute";

export default ({ childProps }) => (
  <Switch>
    <AppliedRoute path="/" exact component={Main} props={childProps} />
    {/* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
);
