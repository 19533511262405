import React from "react";
import { Button, Spinner } from "reactstrap";

export default ({
  isLoading,
  text,
  loadingText,
  className = "",
  disabled = false,
  ...props
}) => (
  <Button
    className={`LoaderButton ${className}`}
    disabled={disabled === true || isLoading === true}
    {...props}
  >
    {!isLoading ? text : loadingText}
    {isLoading && <Spinner color="primary" />}
  </Button>
);

//{isLoading && <Glyphicon glyph="refresh" className="spinning" />}
