import React from "react";
import StatusCard from "./StatusCard";
import { deviceTypesNames } from "../../resources/constants.js";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

export default class Statuses extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAccordionExpanded: true,
    };
  }

  render() {
    //must have at least one device
    let type = this.props.devices[0].type;
    return (
      <div className="statuses-container">
        <div className="statuses-title">{deviceTypesNames[type]}</div>
        {
          this.props.isCollapsable ? (
            <Accordion
              expanded={this.state.isAccordionExpanded}
              style={{ width: "1500px", margin: "auto" }}
              onChange={() =>
                this.setState((prevState) => {
                  return {
                    isAccordionExpanded: !prevState.isAccordionExpanded,
                  };
                })
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div style={{ fontSize: "25px" }}>Device Panel</div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="status-card-list">
                  {this.props.devices.map((device) => {
                    return (
                      <StatusCard
                        key={device.toString()}
                        device={device}
                        selected={
                          !this.props.isSelectable ||
                          this.props.selectedDevice === device
                        }
                        enabled={this.props.isSelectable && this.props.isEnabled}
                        onClick={() => this.props.onClick(device)}
                      />
                    );
                  })}
                </div>
              </AccordionDetails>
            </Accordion>
          ) : (
            <div className="status-card-list">
              {this.props.devices.map(device => {
                let surveyAnswers = this.props.surveyAnswers ? this.props.surveyAnswers[device.id] : undefined;
                return (
                  <StatusCard
                    key={device.toString()}
                    device={device}
                    selected={
                      !this.props.isSelectable ||
                      this.props.selectedDevice === device
                    }
                    enabled={this.props.isSelectable && this.props.isEnabled}
                    onClick={() => this.props.onClick(device)}
                    surveyAnswers={surveyAnswers}
                    surveyQuestions={surveyAnswers ? this.props.surveyQuestions : undefined}
                    refreshSurveyAnswers={this.props.refreshSurveyAnswers}
                  />
                );
              })}
            </div>
          )
        }
      </div >
    );
  }
}
