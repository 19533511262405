import React from "react";
import { Dropdown, Segment } from "semantic-ui-react";

import ControlButtons from "./ControlButtons";
import InfoCard from "../../components/Maps/InfoCard";
import Charts from "../../components/Maps/Charts";
import AltitudeChart from "../../components/Charts/AltitudeChart";
import ExpandableControlSegment from "./ExpandableControlSegment";

/**
 * this class is for the control component on the left side of page
 */
export default class Control extends React.Component {
  constructor(props) {
    super(props);
    this.dropdownOptions = this.generateDropdownOptions(props.devices);
    this.state = {
      devices: props.devices,
      dropdownOptions: this.dropdownOptions,
      selectedDevice: null,
      dataToDisplay: null,
      alertToDisplay: null
    };
  }

  generateDropdownOptions = devices => {
    if (!devices) {
      return [];
    }
    return devices.map((device, index) => {
      return { key: `${index}`, value: device.id, text: device.id };
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.lineChartData !== this.props.lineChartData) {
      if (this.state.selectedDevice) {
        this.setState({
          dataToDisplay: {
            ...this.props.lineChartData.get(this.state.selectedDevice.id)
          },
          alertToDisplay: this.props.alertData.get(this.state.selectedDevice.id)
        });
      }
    }
  }

  handleChange = (e, { value }) => {
    this.props.devices.forEach(device => {
      if (device.id === value) {
        this.setState(
          {
            selectedDevice: device,
            dataToDisplay: this.props.lineChartData.get(device.id),
            alertToDisplay: this.props.alertData.get(device.id)
          },
          () => {
            this.props.onDeviceSelect(device);
          }
        );
      }
    });
  };

  render() {
    return (
      <Segment className="airpollution-devicelist-container">
        <Dropdown
          fluid
          placeholder="Select Device"
          search
          selection
          options={this.dropdownOptions}
          onChange={this.handleChange}
        />
        <InfoCard
          selectedDevice={this.state.selectedDevice}
          alertData={this.state.alertToDisplay}
        />
        <div className="control-options">
          <ExpandableControlSegment title="Altitude vs PM2.5">
            <AltitudeChart
              data={this.props.lineChartData}
              selectedDevice={this.state.selectedDevice}
            />
          </ExpandableControlSegment>
          <ExpandableControlSegment title="Charts">
            <Charts
              data={this.state.dataToDisplay}
              alertData={this.state.alertToDisplay}
              chartSize={this.props.chartSize}
            />
          </ExpandableControlSegment>
          <ExpandableControlSegment title="History">
            <ControlButtons
              handleDisplayHide={this.props.handleDisplayHide}
              handlePlayback={this.props.handlePlayback}
              handleRealTime={this.props.handleRealTime}
            />
          </ExpandableControlSegment>
        </div>
      </Segment>
    );
  }
}
