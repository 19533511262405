import {
  building1Data,
  building1RoomsData,
} from "../resources/TRHbuilding1.js";
import {
  building2Data,
  building2RoomsData,
} from "../resources/TRHbuilding2.js";
import {
  building3Data,
  building3RoomsData,
} from "../resources/TRHbuilding3.js";

/**
 * Formatting the tooltip message
 * @param {string} name name of the device assigned
 * @param {number} temp temperature of the device assigned
 * @param {number} hum humidity of the device assigned
 * @returns the formatted string
 */
function formatName(name, temp, hum) {
  return name + "\n\u{1F321} " + temp + "\u2109\n\u{1F4A7} " + hum + "%";
}

function generateFeature(coordinates, devices) {
  return coordinates.map((c, i) => {
    return {
      type: "Feature",
      properties: {
        name:
          devices[i] == null
            ? "Room ".concat(i + 1)
            : formatName(
              devices[i].device.name,
              devices[i].currentTemp,
              devices[i].currentHumidity
            ),
        minTemp: devices[i] == null ? null : devices[i].minTemp,
        maxTemp: devices[i] == null ? null : devices[i].maxTemp,
      },
      geometry: {
        type: "Polygon",
        coordinates: [c],
      },
    };
  });
}

export const TRHstreetData = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-191, 55],
            [-189, 55],
            [-189, 40],
            [-191, 40],
          ],
          [
            [-191, 25],
            [-189, 25],
            [-189, 10],
            [-191, 10],
          ],
          [
            [-191, -5],
            [-189, -5],
            [-189, -20],
            [-191, -20],
          ],
          [
            [-191, -35],
            [-189, -35],
            [-189, -50],
            [-191, -50],
          ],
        ],
      },
    },
  ],
};

export const TRHstreetBackground = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-180, 60],
            [-180, -60],
            [-200, -60],
            [-200, 60],
          ],
        ],
      },
    },
  ],
};

export const TRHbuildings = [
  {
    buildingData: building1Data,
    roomData: building1RoomsData,
  },
  {
    buildingData: building2Data,
    roomData: building2RoomsData,
  },
  {
    buildingData: building3Data,
    roomData: building3RoomsData,
  },
];

export const getBuildingData = ({ buildingTypeIdx }) =>
  TRHbuildings[buildingTypeIdx];

export const TRHroomsData = ({ devices, buildingTypeIdx }) => ({
  type: "FeatureCollection",
  features: generateFeature(
    getBuildingData({ buildingTypeIdx }).roomData,
    devices
  ),
});
