import React from "react";

import VectorMap, { Layer, Tooltip, Label } from "devextreme-react/vector-map";

import {
  TRHroomsData,
  getBuildingData,
  TRHstreetBackground,
  TRHstreetData,
} from "../../resources/TRHconstants.js";

import { Menu, MenuItem } from "@material-ui/core";

import { DeleteForever, Router, AddCircle } from "@material-ui/icons";

const projection = {
  to: ([l, lt]) => [l / 100, lt / 100],
  from: ([x, y]) => [x * 100, y * 100],
};

export default class TRHFloorplan extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clientX: null,
      clientY: null,
      openMenu: false,
      roomClicked: null,

      assignedDevices: {},
      unassignedDevices: [],
    };
  }

  /**
   * Compute unassigned devices
   * @returns a list of unassigned devices
   */
  computeUnassigned = () => {
    if (!this.props.assignedDevices) {
      return this.props.devices;
    }
    const assignedDeviceIDs = new Set();
    for (var key in this.props.assignedDevices) {
      assignedDeviceIDs.add(this.props.assignedDevices[key].device.id);
    }
    return this.props.devices.filter(
      (device) => !assignedDeviceIDs.has(device.device.id)
    );
  };

  // when the component first loads, set assignedDevices to props
  // assignedDevices, and compute unassigned devices
  async componentDidMount() {
    if (!this.props.devices) {
      return;
    }

    await this.setState({
      assignedDevices: this.props.assignedDevices
        ? { ...this.props.assignedDevices }
        : {},
      unassignedDevices: this.computeUnassigned(),
    });
  }

  /**
   * Compute if devices have changed
   * @param {*} currDevices
   * @param {*} prevDevices
   * @returns boolean indicating if devices have changed
   */
  devicesChanged = (currDevices, prevDevices) => {
    const prevNames = new Set();
    prevDevices.forEach((device) => prevNames.add(device.device.id));
    var flag = false;
    currDevices.forEach(
      (device) => (flag = flag || !prevNames.has(device.device.id))
    );
    return flag;
  };

  // check if new device has been added. If new device is detected, handle its assignment
  async componentDidUpdate(prevProps) {
    if (!this.props.devices) {
      return;
    }
    if (
      this.props.assignedDevices !== prevProps.assignedDevices ||
      this.devicesChanged(this.props.devices, prevProps.devices)
    ) {
      this.setState({
        assignedDevices: this.props.assignedDevices
          ? { ...this.props.assignedDevices }
          : {},
        unassignedDevices: this.computeUnassigned(),
      });

      if (
        prevProps.devices.length < this.props.devices.length &&
        this.props.updateAssignedDevices != null &&
        this.props.updateIsAssignmentLoading != null
      ) {
        // this means that a new device has been added
        var addedDevice = null;
        const oldDevices = new Set();
        prevProps.devices.forEach((device) => oldDevices.add(device.device.id));
        this.props.devices.forEach((device) => {
          if (!oldDevices.has(device.device.id)) {
            addedDevice = device;
          }
        });
        this.props.updateIsAssignmentLoading(true);
        this.handleAssignNewDevice(addedDevice);
        this.handleClose();
        this.props.updateIsAssignmentLoading(false);
      }
    }
  }

  /**
   * Handles mouse click on interactive floorplan
   * @param {*} event
   * @param {*} ind
   */
  handleClick = (event, ind) => {
    event.preventDefault();
    this.setState({
      clientX: event.clientX - 2,
      clientY: event.clientY - 4,
      roomClicked: ind,
      openMenu: true,
    });
  };

  /**
   * Handle selection on interactive floorplan
   * @param {*} selectedDevice
   */
  handleSelect = (selectedDevice) => {
    this.setState(
      (prevState) => {
        const unassigned = prevState.unassignedDevices.filter(
          (device) => device.device.id !== selectedDevice.device.id
        );
        const assigned = prevState.assignedDevices;
        assigned[prevState.roomClicked] = selectedDevice;
        return {
          clientX: null,
          clientY: null,
          roomClicked: null,
          openMenu: false,
          unassignedDevices: unassigned,
          assignedDevices: assigned,
        };
      },
      () => this.props.updateAssignedDevices(this.state.assignedDevices)
    );
  };

  /**
   * Handles assigning a newly added device
   * @param {*} addedDevice
   */
  handleAssignNewDevice = (addedDevice) => {
    this.setState(
      (prevState) => {
        const unassigned = prevState.unassignedDevices;
        const assigned = prevState.assignedDevices;
        assigned[prevState.roomClicked] = addedDevice;
        return {
          unassignedDevices: unassigned.filter(
            (device) => device.device.id !== addedDevice.device.id
          ),
          assignedDevices: assigned,
        };
      },
      () => this.props.updateAssignedDevices(this.state.assignedDevices)
    );
  };

  /**
   * Handles closing after assigning new device
   */
  handleAssignNewDeviceClose = () => {
    this.setState({
      clientX: null,
      clientY: null,
      openMenu: false,
    });
  };

  /**
   * Handles closing of dropdown
   */
  handleClose = () => {
    this.setState({
      clientX: null,
      clientY: null,
      roomClicked: null,
      openMenu: false,
    });
  };

  /**
   * Handles removing assignment
   */
  handleRemove = () => {
    this.setState(
      (prevState) => {
        const prevUnassigned = prevState.unassignedDevices;
        prevUnassigned.push(prevState.assignedDevices[prevState.roomClicked]);
        var prevAssigned = prevState.assignedDevices;
        delete prevAssigned[prevState.roomClicked];
        return {
          ...prevState,
          unassignedDevices: prevUnassigned,
          assignedDevices: prevAssigned,
          clientX: null,
          clientY: null,
          roomClicked: null,
          openMenu: false,
        };
      },
      () => this.props.updateAssignedDevices(this.state.assignedDevices)
    );
  };

  /**
   * Checks if a room is assigned a device
   * @param {number} room_id
   * @returns boolean indicating if a room is assigned
   */
  isAssigned = (room_id) => {
    return this.state.assignedDevices[room_id] != null;
  };

  customizeLayer = (elements) => {
    elements.forEach((element, i) => {
      const device = this.state.assignedDevices[i];
      element.applySettings({
        color: device == null ? "transparent" : device.color,
        opacity: device == null ? 1 : 0.3,
        hoveredColor: "#ffe4e4",
        selectedColor: "#008f00",
      });
    });
  };

  customizeTooltip(arg) {
    if (arg.layer.name === "rooms") {
      let minTemp = arg.attribute("minTemp");
      let maxTemp = arg.attribute("maxTemp");
      if (minTemp != null && maxTemp != null) {
        return {
          text: `Min Temp This Week: \u{1F321}${minTemp}\u2109\nMax Temp This Week: \u{1F321}${maxTemp}\u2109`,
        };
      }
    }
  }

  render() {
    const rd = TRHroomsData({
      devices: this.state.assignedDevices,
      buildingTypeIdx: this.props.floorplanTypeIdx,
    });
    console.log(rd);
    return (
      <div>
        <VectorMap
          id="vector-map"
          projection={projection}
          panningEnabled={false}
          zoomingEnabled={false}
          controlBar={{ enabled: false }}
          onClick={(e) => {
            if (this.props.interactive && this.props.devices) {
              if (e.target != null && e.target.layer.name === "rooms") {
                this.handleClick(e.event, e.target.index);
              }
            }
          }}
        >
          <Layer
            dataSource={TRHstreetBackground}
            hoverEnabled={false}
            name="streetBackground"
            color="#696969"
          ></Layer>
          <Layer
            dataSource={TRHstreetData}
            hoverEnabled={false}
            name="streetCenter"
            color="yellow"
          ></Layer>
          <Layer
            dataSource={
              getBuildingData({ buildingTypeIdx: this.props.floorplanTypeIdx })
                .buildingData
            }
            hoverEnabled={false}
            name="building"
          ></Layer>
          <Layer
            dataSource={rd}
            name="rooms"
            borderWidth={1}
            customize={(elts) => this.customizeLayer(elts)}
          >
            <Label enabled={true} dataField="name"></Label>
          </Layer>
          <Tooltip
            enabled={true}
            customizeTooltip={this.customizeTooltip}
          ></Tooltip>
        </VectorMap>
        {this.props.interactive && this.props.devices && (
          <Menu
            id={"room menu"}
            keepMounted
            open={this.state.openMenu}
            onClose={this.handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
              this.state.clientY != null &&
                this.state.clientX != null &&
                this.state.roomClicked != null
                ? { top: this.state.clientY, left: this.state.clientX }
                : undefined
            }
          >
            {this.state.roomClicked != null &&
              this.isAssigned(this.state.roomClicked) ? (
              <MenuItem onClick={this.handleRemove}>
                <DeleteForever />
                Remove
              </MenuItem>
            ) : (
              <div>
                {this.state.unassignedDevices.map((device, i) => (
                  <MenuItem
                    onClick={() => this.handleSelect(device)}
                    key={"option" + i}
                  >
                    <Router />
                    {device.device.name}
                  </MenuItem>
                ))}
                <MenuItem
                  onClick={() => {
                    if (
                      this.props.setAddDeviceModalInfo != null &&
                      this.props.deviceCodes != null
                    ) {
                      this.props.setAddDeviceModalInfo(
                        true,
                        this.props.deviceCodes.trh
                      );
                    }
                    this.handleAssignNewDeviceClose();
                  }}
                  style={{ backgroundColor: "#ffe4e4" }}
                >
                  <AddCircle />
                  Add New Device
                </MenuItem>
              </div>
            )}
          </Menu>
        )}
      </div>
    );
  }
}
