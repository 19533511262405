import React from "react";
import {
    FormGroup,
    Label,
    Input,
    Col,
    Row,
} from "reactstrap";

export default class AddDigitalAgriculture extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DigitalDevice: Array(4).fill(null),
            DigitalName: Array(4).fill(null),
        };
    }

    handleDADeviceChange = (index, event) => {
        const DigitalDevice = this.state.DigitalDevice.slice();
        DigitalDevice[index] = event.target.value;
        this.setState({ DigitalDevice: DigitalDevice });

    };

    handleDigitalNameChange = (index, event) => {
        const DigitalName = this.state.DigitalName.slice();
        DigitalName[index] = event.target.value;
        this.setState({ DigitalName: DigitalName });

    };

    inputBlock(i) {
        const prompt = 'Device ' + (i + 1);
        return (
            <FormGroup row >
                <Label for="deviceInfo" lg={2} size="lg"> {prompt}</Label>
                <Col lg={5}>
                    <Input
                        type="text"
                        bsSize="lg"
                        style={{ width: "180px" }}
                        onChange={this.handleDADeviceChange.bind(this, i)}
                        value={this.state.DigitalDevice[i]}

                        placeholder="Device ID"
                    />
                </Col>
                <Col lg={5}>
                    <Input
                        type="text"
                        bsSize="lg"
                        style={{ width: "160px" }}
                        onChange={this.handleDigitalNameChange.bind(this, i)}
                        value={this.state.DigitalName[i]}
                        placeholder="Device Name"
                    />
                </Col>
            </FormGroup>
        );
    }

    render() {
        return (
            < >
                <Row>
                    <Col lg={2}></Col>
                    <Label lg={5} size="lg">Device ID</Label>
                    <Label lg={5} size="lg">Device Name</Label>
                </Row>
                {this.inputBlock(0)}
                {this.inputBlock(1)}
                {this.inputBlock(2)}
                {this.inputBlock(3)}
            </>
        );
    }
}