import React from "react";
import TRHFloorplan from "../../components/TRH/TRHFloorplan.js";
import { Container, Row, Col } from "reactstrap";
import {
  Dropdown,
  Menu,
  Segment,
  Sidebar,
  Icon,
  Button,
  Modal,
  Form,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { Alert } from "@material-ui/lab";
import { Paper } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import {
  getHourlyTemperatureRelativeHumidityReadings,
  addFloorplanToAccount,
  getDeviceToRoomAssignment,
  removeFloorplanFromAccount,
  updateDeviceToRoomAssignment,
  removeDeviceToRoomAssignment,
} from "../../resources/DataAccessors.js";
import { getPrevDay, getNearbyDates } from "../../resources/constants.js";

import { FaThermometerHalf } from "react-icons/fa";
import { WiHumidity } from "react-icons/wi";
import { TRHbuildings } from "../../resources/TRHconstants.js";

export default class TemperatureRelativeHumidity extends React.Component {
  constructor(props) {
    super(props);

    let { today } = getNearbyDates();
    let yesterday = getPrevDay(today);

    this.state = {
      // list of devices
      rooms: [],
      // id of the selected floorplan
      selectedFloorplanName: "",
      // boolean representing the show status of the sidebar
      showSidebar: false,

      // state variables relating to the add modal
      // boolean representing the show status of the add modal
      showAddModal: false,
      // user input floorplan name
      inputFloorplanName: "",
      // user selected floorplan type
      selectedFloorplanType: -1,
      // boolean representing the show status of add error
      showAddError: false,

      // state variables relating to the remove modal
      // boolean representing the show status of the add modal
      showRemoveModal: false,
      // user selected floorplan id to remove
      selectedFloorplanToRemove: -1,
      // boolean representing the show status of remove error
      showRemoveError: false,

      // state variables relating to the edit modal
      // boolean representing the show status of the add modal
      showEditModal: false,
      // user selected floorplan to edit
      selectedFloorplanToEdit: -1,
      // boolean representing the show status of edit error
      showEditError: false,
      // boolean representing if the edit dropdown is shown
      showEditDropdown: false,
      // temperary device assignment reflecting the state of the edit modal
      tempAssignedDevices: {},

      // boolean representing the show status of the modal in general
      showModal: false,
      // boolean indicating whether the assignment is loading
      isAssignmentLoading: false,
      // list of unassigned devices
      unassignedDevices: [],
      // device assignment in the format of
      // {floorplan_id: {floorplanTypeIdx, {room_id: device}}}
      deviceAssignment: {},
      // mapping from floorplan id to name of floorplan
      floorplanIdToName: {},

      currentSelectedDay: today,
      pastSelectedDay: yesterday,
    };
  }

  // when page is loaded for the first time
  // gets data for each room and display on the floorplan
  async componentDidMount() {
    await this.getData();
    this.setState((prevState) => ({
      unassignedDevices: this.computeUnassigned(prevState.deviceAssignment),
    }));
  }

  /**
   * Computes whether the device is unassigned given the assignment
   * @param {Device} device
   * @param {*} assignments
   * @returns boolean indicating if the device is unassigned
   */
  isUnassigned = (device, assignments) => {
    for (var floorplan in assignments) {
      for (var room in assignments[floorplan].assignment) {
        if (
          device.device.id === assignments[floorplan].assignment[room].device.id
        ) {
          return false;
        }
      }
    }
    return true;
  };

  /**
   * Computes the list of unassigned devices given the assignment
   * @param {*} assignments
   * @returns list of unassigned devices
   */
  computeUnassigned = (assignments) => {
    return this.state.rooms.filter((room) =>
      this.isUnassigned(room, assignments)
    );
  };

  // if the number of devices changes, update unassigned devices
  async componentDidUpdate(prevProps) {
    if (prevProps.devices.length !== this.props.devices.length) {
      await this.getData();
      this.setState((prevState) => ({
        unassignedDevices: this.computeUnassigned(prevState.deviceAssignment),
      }));
    }
  }

  /*
  fetch data for floor plan

  TODO: make specific backend call for this code, it is illogical to 24 points
  for each device just to use the last one
  */
  getData = async () => {
    this.setState({ isAssignmentLoading: true });
    let size = this.props.devices.length;

    const rooms = [];

    for (let i = 0; i < size; i++) {
      let device = this.props.devices[i];
      const trhData = await getHourlyTemperatureRelativeHumidityReadings(
        this.state.pastSelectedDay,
        this.state.currentSelectedDay,
        device.id
      );

      const values = trhData.getValues();
      const len = values.length - 1;
      if (values[len]) {
        let currentHumidity = values[len].relativeHumidity;
        let currentTemp = values[len].temperature;
        currentTemp = Math.round(currentTemp * 1.8 + 32);
        let temps = values.map((d) => d.temperature);
        let maxTemp = Math.max.apply(null, temps);
        maxTemp = Math.round(maxTemp * 1.8 + 32);
        let minTemp = Math.min.apply(null, temps);
        minTemp = Math.round(minTemp * 1.8 + 32);
        rooms.push(
          new Device(device, currentTemp, currentHumidity, minTemp, maxTemp)
        );
      } else {
        // No data is currently being sent from this device
        rooms.push(new Device(device, 0, 0, 0, 0));
      }
    }

    const floorplanAssignment = {};

    const trhAssignment = await getDeviceToRoomAssignment();

    const idToName = {};

    trhAssignment.forEach((assignment) => {
      idToName[assignment.floor_plan_id] =
        assignment.floor_plan_name == null
          ? "floor " + assignment.floor_plan_id
          : assignment.floor_plan_name;
      if (floorplanAssignment[assignment.floor_plan_id] == null) {
        floorplanAssignment[assignment.floor_plan_id] = {
          floorplanType: assignment.floor_plan_type,
          assignment: {},
        };
      }
      if (assignment.device_id == null || assignment.room_id == null) {
        return;
      }
      var device = null;
      rooms.forEach((room) => {
        if (room.device.id === assignment.device_id) {
          device = room;
        }
      });
      floorplanAssignment[assignment.floor_plan_id].assignment[
        assignment.room_id
      ] = device;
    });
    this.setState({
      rooms: rooms,
      deviceAssignment: floorplanAssignment,
      floorplanIdToName: idToName,
      isAssignmentLoading: false,
      unassignedDevices: this.computeUnassigned(floorplanAssignment),
    });
  };

  getFloorplanNames = (state) => {
    const names = [];
    for (var id in state.deviceAssignment) {
      names.push({
        id: id,
        name: state.floorplanIdToName[id],
      });
    }
    return names;
  };

  /**
   * Checks if the input name and type is valid
   * @param {string} name
   * @param {number} t
   * @returns boolean indicating if the input is valid
   */
  checkNameTypeValidity(name, t) {
    let names = this.getFloorplanNames(this.state).map((item) => item.name);
    return !(name === "" || names.includes(name) || t === -1);
  }

  updateTempAssignedDevices = (newTempAssignedDevices) => {
    this.setState({ tempAssignedDevices: newTempAssignedDevices });
  };

  updateIsAssignmentLoading = (isLoading) => {
    this.setState({ isAssignmentLoading: isLoading });
  };

  /**
   * Handles device assignment update
   * @param {*} prevA previous device assignment
   * @param {*} newA new device assignment
   * @param {*} newAFloorplanId id of target floorplan
   */
  handleUpdatedRooms = (prevA, newA, newAFloorplanId) => {
    const toInsert = [];
    const toRemove = [];
    let tempNew = newA;
    let tempPrev = prevA[newAFloorplanId].assignment;
    for (let room in tempNew) {
      if (
        tempPrev[room] == null ||
        tempPrev[room].device.id !== tempNew[room].device.id
      ) {
        toInsert.push({
          floor_plan_id: newAFloorplanId,
          room_id: room,
          device_id: tempNew[room].device.id,
        });
      }
    }
    for (let room in tempPrev) {
      if (tempNew[room] == null) {
        toRemove.push({ floor_plan_id: newAFloorplanId, room_id: room });
      }
    }
    if (toInsert.length !== 0) {
      updateDeviceToRoomAssignment(toInsert);
    }
    if (toRemove.length !== 0) {
      removeDeviceToRoomAssignment(toRemove);
    }

    if (toInsert.length !== 0 || toRemove.length !== 0) {
      this.getData();
    }
  };

  /**
   * Handles submission on the add modal
   */
  handleAddSubmit = async () => {
    this.setState(
      (prevState) => {
        let prevFloorplanList = this.getFloorplanNames(prevState).map(
          (item) => item.name
        );
        if (
          prevState.inputFloorplanName === "" ||
          prevFloorplanList.includes(prevState.inputFloorplanName) ||
          prevState.selectedFloorplanType === -1
        ) {
          // TODO: check no punctuation and notify
          return { ...prevState, showAddError: true };
        }
        addFloorplanToAccount(
          prevState.inputFloorplanName,
          prevState.selectedFloorplanType
        );
        return {
          ...prevState,
          showModal: false,
          showAddModal: false,
          inputFloorplanName: "",
          selectedFloorplanType: -1,
          showAddError: false,
        };
      },
      () => this.getData()
    );
  };

  /**
   * Computes max/min values given device assignment
   * @returns max/min temperature and max humidity on the current selected floorplan
   */
  computeExtremes = () => {
    var maxTemp = Number.NEGATIVE_INFINITY;
    var maxTempDevice = null;
    var minTemp = Number.MAX_VALUE;
    var minTempDevice = null;
    var maxHumid = Number.NEGATIVE_INFINITY;
    var maxHumidDevice = null;
    for (var key in this.state.deviceAssignment[
      this.state.selectedFloorplanName
    ].assignment) {
      const tempDevice =
        this.state.deviceAssignment[this.state.selectedFloorplanName]
          .assignment[key];
      if (tempDevice.currentTemp > maxTemp) {
        maxTemp = tempDevice.currentTemp;
        maxTempDevice = tempDevice;
      }
      if (tempDevice.currentTemp < minTemp) {
        minTemp = tempDevice.currentTemp;
        minTempDevice = tempDevice;
      }
      if (tempDevice.currentHumidity > maxHumid) {
        maxHumid = tempDevice.currentHumidity;
        maxHumidDevice = tempDevice;
      }
    }
    return {
      maxTemp: maxTempDevice,
      minTemp: minTempDevice,
      maxHumid: maxHumidDevice,
    };
  };

  /**
   * Handles submission on the remove modal
   */
  handleRemoveSubmit = async () => {
    this.setState(
      (prevState) => {
        if (prevState.selectedFloorplanToRemove === -1) {
          return { ...prevState, showRemoveError: true };
        }
        removeFloorplanFromAccount(prevState.selectedFloorplanToRemove);
        return {
          ...prevState,
          showModal: false,
          selectedFloorplanName:
            prevState.selectedFloorplanName ===
              prevState.selectedFloorplanToRemove
              ? ""
              : prevState.selectedFloorplanName,
          showRemoveModal: false,
          selectedFloorplanToRemove: -1,
          showRemoveError: false,
        };
      },
      () => this.getData()
    );
  };

  /**
   * Handles submission on the edit modal
   */
  handleEditSubmit = async () => {
    this.setState((prevState) => {
      if (prevState.selectedFloorplanToEdit === -1) {
        return { ...prevState, showEditError: true };
      }
      this.handleUpdatedRooms(
        prevState.deviceAssignment,
        prevState.tempAssignedDevices,
        prevState.selectedFloorplanToEdit
      );
      return {
        ...prevState,
        showModal: false,
        showEditModal: false,
        tempAssignedDevices: {},
        selectedFloorplanToEdit: -1,
      };
    });
  };

  render() {
    const extremes =
      this.state.selectedFloorplanName !== "" &&
        this.state.selectedFloorplanName != null
        ? this.computeExtremes()
        : null;
    return (
      <div>
        <Dimmer
          active={this.state.isAssignmentLoading}
          page
          style={{
            backgroundColor: "rgba(90,90,90,0.7)",
            zIndex: "10000",
          }}
        >
          <Loader size="massive" disabled={!this.state.isAssignmentLoading}>
            Loading
          </Loader>
        </Dimmer>
        <Modal
          onClose={() =>
            this.setState({
              showModal: false,
              showAddModal: false,
              showRemoveModal: false,
              showEditModal: false,
              inputFloorplanName: "",
              selectedFloorplanType: -1,
              showAddError: false,
              showRemoveError: false,
              selectedFloorplanToRemove: -1,
              selectedFloorplanToEdit: -1,
              showEditError: false,
              tempAssignedDevices: {},
            })
          }
          open={this.state.showModal}
          size="large"
          className="modal-main"
          centered={true}
        >
          <Modal.Header>
            {this.state.showAddModal
              ? "Add a Floorplan"
              : this.state.showRemoveModal
                ? "Remove a Floorplan"
                : "Edit a Floorplan"}
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              {this.state.showAddModal ? (
                <Container>
                  {this.state.showAddError && (
                    <Alert severity="error">
                      Nickname cannot be empty, or contain punctuation. You must
                      select a Floorplan type.
                    </Alert>
                  )}
                  <Row>
                    <Form>
                      <Form.Field>
                        <label>Floorplan Nickname</label>
                        <input
                          placeholder="ex: Floor 1"
                          onChange={(name) =>
                            this.setState({
                              inputFloorplanName: name.target.value,
                            })
                          }
                        />
                      </Form.Field>
                      <Form.Field
                        style={{
                          paddingBottom:
                            this.state.selectedFloorplanType !== -1
                              ? 0
                              : "300px",
                        }}
                      >
                        <label>Floorplan Type</label>
                        <Dropdown
                          placeholder="Select Floorplan Type"
                          onChange={(_, t) =>
                            this.setState({ selectedFloorplanType: t.value })
                          }
                          selection
                          scrolling={true}
                          options={TRHbuildings.map((_, i) => {
                            return {
                              key: "floorplantype" + (i + 1),
                              text: "Type " + (i + 1),
                              value: i,
                            };
                          })}
                        />
                      </Form.Field>
                    </Form>
                  </Row>
                  <Row style={{ paddingTop: "30px" }}>
                    {this.state.selectedFloorplanType !== -1 && (
                      <TRHFloorplan
                        interactive={false}
                        floorplanTypeIdx={this.state.selectedFloorplanType}
                      />
                    )}
                  </Row>
                </Container>
              ) : this.state.showRemoveModal ? (
                <Container>
                  {this.state.showRemoveError && (
                    <Alert severity="error">You must select a Floorplan.</Alert>
                  )}
                  <Form>
                    <Form.Field style={{ paddingBottom: "300px" }}>
                      <label>Floorplan Nickname</label>
                      <Dropdown
                        placeholder="Select Floorplan to Remove"
                        onChange={(_, t) =>
                          this.setState({ selectedFloorplanToRemove: t.value })
                        }
                        selection
                        value={this.state.selectedFloorplanToRemove}
                        options={this.getFloorplanNames(this.state).map(
                          (floorplan, _) => {
                            return {
                              key: "remove" + floorplan.id,
                              text: floorplan.name,
                              value: floorplan.id,
                            };
                          }
                        )}
                      />
                    </Form.Field>
                  </Form>
                </Container>
              ) : (
                <Container>
                  {this.state.showEditError && (
                    <Alert severity="error">You must select a Floorplan.</Alert>
                  )}
                  <Row>
                    <Form>
                      <Form.Field
                        style={{
                          paddingBottom:
                            this.state.selectedFloorplanToEdit !== -1
                              ? 0
                              : "300px",
                        }}
                      >
                        <label>Floorplan</label>
                        <Dropdown
                          placeholder="Select Floorplan"
                          onChange={(_, floorplan) =>
                            this.setState({
                              selectedFloorplanToEdit: floorplan.value,
                            })
                          }
                          onClick={() =>
                            this.setState({ showEditDropdown: true })
                          }
                          open={this.state.showEditDropdown}
                          onClose={() =>
                            this.setState({ showEditDropdown: false })
                          }
                          selection
                          value={this.state.selectedFloorplanToEdit}
                          scrolling={true}
                          options={this.getFloorplanNames(this.state).map(
                            (floorplan, _) => {
                              return {
                                key: floorplan.id,
                                text: floorplan.name,
                                value: floorplan.id,
                              };
                            }
                          )}
                        />
                      </Form.Field>
                    </Form>
                  </Row>
                  <Row style={{ paddingTop: "30px" }}>
                    {this.state.selectedFloorplanToEdit !== -1 && (
                      <>
                        <Col md={{ span: 1 }}>
                          <TRHFloorplan
                            interactive={true}
                            devices={this.state.unassignedDevices}
                            updateAssignedDevices={
                              this.updateTempAssignedDevices
                            }
                            assignedDevices={
                              this.state.deviceAssignment[
                                this.state.selectedFloorplanToEdit
                              ].assignment
                            }
                            setAddDeviceModalInfo={
                              this.props.setAddDeviceModalInfo
                            }
                            deviceCodes={this.props.deviceCodes}
                            updateIsAssignmentLoading={
                              this.updateIsAssignmentLoading
                            }
                            floorplanTypeIdx={
                              this.state.deviceAssignment[
                                this.state.selectedFloorplanToEdit
                              ].floorplanType
                            }
                          />
                        </Col>
                        <Col>
                          <Paper
                            elevation={3}
                            style={{
                              display: "flex",
                              overflow: "auto",
                              flexDirection: "column",
                              padding: "10px",
                            }}
                            children={
                              <>
                                <Info />
                                <p style={{ paddingTop: "10px" }}>
                                  To register a device to a room, click the
                                  room, then click on the device that
                                  corresponds to the room. If a device is
                                  matched to a room, click on the room again to
                                  remove the device.
                                </p>
                              </>
                            }
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                </Container>
              )}
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="black"
              onClick={() =>
                this.setState({
                  showModal: false,
                  showAddModal: false,
                  showRemoveModal: false,
                  showEditModal: false,
                  inputFloorplanName: "",
                  selectedFloorplanType: -1,
                  showAddError: false,
                  showRemoveError: false,
                  selectedFloorplanToRemove: -1,
                  selectedFloorplanToEdit: -1,
                  showEditError: false,
                  tempAssignedDevices: {},
                })
              }
            >
              Close
            </Button>
            <Button
              content="Save"
              labelPosition="right"
              icon="checkmark"
              onClick={
                this.state.showAddModal
                  ? this.handleAddSubmit
                  : this.state.showRemoveModal
                    ? this.handleRemoveSubmit
                    : this.handleEditSubmit
              }
              positive
            />
          </Modal.Actions>
        </Modal>
        <Container style={{ paddingTop: "50px" }}>
          <Row>
            {Object.keys(this.state.deviceAssignment).length > 0 ? (
              <Dropdown
                placeholder="Select Floorplan"
                onChange={(_, floorplan) =>
                  this.setState({ selectedFloorplanName: floorplan.value })
                }
                selection
                value={this.state.selectedFloorplanName}
                scrolling={true}
                options={this.getFloorplanNames(this.state).map(
                  (floorplan, _) => {
                    return {
                      key: floorplan.id,
                      text: floorplan.name,
                      value: floorplan.id,
                    };
                  }
                )}
              />
            ) : (
              <Button
                onClick={() =>
                  this.setState({ showModal: true, showAddModal: true })
                }
              >
                <Icon name="add" />
                Add Floorplan
              </Button>
            )}
          </Row>
          {this.state.selectedFloorplanName !== "" && (
            <div>
              <Row
                style={{
                  paddingTop: "30px",
                  display: "flex",
                  overflow: "auto",
                }}
              >
                <Sidebar.Pushable as={Segment}>
                  <Sidebar
                    as={Menu}
                    animation="overlay"
                    direction="right"
                    icon="labeled"
                    inverted
                    vertical
                    visible={this.state.showSidebar}
                    onHide={() => this.setState({ showSidebar: false })}
                    width="thin"
                    style={{ backgroundColor: "#C0C0C0" }}
                  >
                    <Menu.Item
                      as="a"
                      style={{ color: "black" }}
                      onClick={() =>
                        this.setState({ showModal: true, showAddModal: true })
                      }
                    >
                      <Icon name="add" />
                      Add Floorplan
                    </Menu.Item>
                    <Menu.Item
                      as="a"
                      style={{ color: "black" }}
                      onClick={() =>
                        this.setState({
                          showModal: true,
                          showRemoveModal: true,
                        })
                      }
                    >
                      <Icon name="remove" />
                      Remove Floorplan
                    </Menu.Item>
                    <Menu.Item
                      as="a"
                      style={{ color: "black" }}
                      onClick={() =>
                        this.setState({ showModal: true, showEditModal: true })
                      }
                    >
                      <Icon name="edit" />
                      Edit Floorplan
                    </Menu.Item>
                  </Sidebar>
                  <Sidebar.Pusher>
                    <Segment basic>
                      <Row>
                        <Col style={{ width: "200px", height: "300px" }}>
                          <svg
                            style={{ width: "500px", height: "508px" }}
                            viewBox="0 00 600 610"
                          >
                            <Legend x={50} y={0} />
                          </svg>
                        </Col>
                        <Col>
                          <TRHFloorplan
                            interactive={false}
                            devices={this.state.rooms}
                            assignedDevices={
                              this.state.deviceAssignment[
                                this.state.selectedFloorplanName
                              ].assignment
                            }
                            floorplanTypeIdx={
                              this.state.deviceAssignment[
                                this.state.selectedFloorplanName
                              ].floorplanType
                            }
                          />
                        </Col>
                        <Col lg>
                          <Button
                            circular
                            icon="th large"
                            onClick={() => this.setState({ showSidebar: true })}
                          />
                        </Col>
                      </Row>
                    </Segment>
                  </Sidebar.Pusher>
                </Sidebar.Pushable>
              </Row>
              {extremes != null && (
                <Row>
                  <div style={{ margin: "auto", paddingTop: "15px" }}>
                    <Row>
                      {extremes.maxTemp != null && (
                        <div className="electricity-information-card">
                          <div>
                            {" "}
                            Your highest temperature room is{" "}
                            <b id="medium-bold">
                              {extremes.maxTemp.device.name}
                            </b>{" "}
                            at{" "}
                            <b id="medium-bold">
                              {extremes.maxTemp.currentTemp}&#8457;
                            </b>
                          </div>
                        </div>
                      )}
                      {extremes.minTemp != null && (
                        <div className="electricity-information-card">
                          <div>
                            {" "}
                            Your lowest temperature room is{" "}
                            <b id="medium-bold">
                              {extremes.minTemp.device.name}
                            </b>{" "}
                            at{" "}
                            <b id="medium-bold">
                              {extremes.minTemp.currentTemp}&#8457;
                            </b>
                          </div>
                        </div>
                      )}
                      {extremes.maxHumid != null && (
                        <div className="electricity-information-card">
                          <div>
                            {" "}
                            Your highest humidity room is{" "}
                            <b id="medium-bold">
                              {extremes.maxHumid.device.name}
                            </b>{" "}
                            at{" "}
                            <b id="medium-bold">
                              {extremes.maxHumid.currentHumidity}%
                            </b>
                          </div>
                        </div>
                      )}
                    </Row>
                  </div>
                </Row>
              )}
            </div>
          )}
        </Container>
      </div>
    );
  }
}

// element of floorplan
const ColoredDiv = ({ x, y, color, room, temp, humid }) => (
  <g>
    <rect
      x={x + 1}
      y={y}
      width={200}
      height={100}
      fill={color}
      fillOpacity="0.3"
      stroke="gray"
      strokeWidth="0.5"
    />
    <text
      x={x + 100}
      y={y + 25}
      fill="black"
      fontSize="1.5em"
      textAnchor="middle"
    >
      {room}
    </text>
    <FaThermometerHalf x={x + 3} y={y + 50} size={27} />
    <text x={x + 45} y={y + 70} fill="black" fontSize="1em">
      {temp}
    </text>
    <text x={x + 63} y={y + 70} fill="black" fontSize="1em">
      &deg; F
    </text>
    <WiHumidity x={x + 103} y={y + 48} size={31} />
    <text x={x + 145} y={y + 70} fill="black" fontSize="1em">
      {humid}
    </text>
    <text x={x + 163} y={y + 70} fill="black" fontSize="1em">
      %{" "}
    </text>
  </g>
);

const Legend = ({ x, y }) => (
  <g>
    <text x={x + 60} y={y + 50} fill="black" textAnchor="start">
      Temperature
    </text>
    <rect
      x={x + 50}
      y={y + 55}
      width={100}
      height={40}
      fill="orangered"
      fillOpacity="0.3"
      stroke="gray"
    />
    <rect
      x={x + 50}
      y={y + 95}
      width={100}
      height={40}
      fill="orange"
      fillOpacity="0.3"
      stroke="gray"
    />
    <rect
      x={x + 50}
      y={y + 135}
      width={100}
      height={40}
      fill="green"
      fillOpacity="0.3"
      stroke="gray"
    />
    <rect
      x={x + 50}
      y={y + 175}
      width={100}
      height={40}
      fill="royalblue"
      fillOpacity="0.3"
      stroke="gray"
    />
    <rect
      x={x + 50}
      y={y + 215}
      width={100}
      height={40}
      fill="deepskyblue"
      fillOpacity="0.3"
      stroke="gray"
    />

    <text x={x + 40} y={y + 80} fill="black" textAnchor="end">
      High
    </text>
    <text x={x + 40} y={y + 160} fill="black" textAnchor="end">
      Comfort
    </text>
    <text x={x + 40} y={y + 240} fill="black" textAnchor="end">
      Low
    </text>
    <text x={x + 100} y={y + 80} fill="black" textAnchor="middle">
      &gt; 90
    </text>
    <text x={x + 100} y={y + 120} fill="black" textAnchor="middle">
      80 - 89
    </text>
    <text x={x + 100} y={y + 160} fill="black" textAnchor="middle">
      70 - 79
    </text>
    <text x={x + 100} y={y + 200} fill="black" textAnchor="middle">
      60 - 69
    </text>
    <text x={x + 100} y={y + 240} fill="black" textAnchor="middle">
      &lt; 60
    </text>
    <FaThermometerHalf x={x + 20} y={y + 270} size={25} />
    <WiHumidity x={x + 18} y={y + 300} size={30} />
    <text x={x + 50} y={y + 290} fill="black" textAnchor="start">
      Temperature
    </text>
    <text x={x + 50} y={y + 320} fill="black" textAnchor="start">
      Relative Humidity
    </text>
  </g>
);

class Device {
  constructor(device, currentTemp, currentHumidity, minTemp, maxTemp) {
    this.device = device;
    this.currentTemp = currentTemp;
    this.currentHumidity = Math.round(currentHumidity);
    this.minTemp = Math.round(minTemp);
    this.maxTemp = Math.round(maxTemp);
    this.color = this.calculateColor(currentTemp);
  }

  calculateColor(temp) {
    switch (true) {
      case temp < 60:
        return "deepskyblue";
      case temp >= 60 && temp < 70:
        return "royalblue";
      case temp >= 70 && temp <= 79:
        return "green";
      case temp > 79 && temp < 90:
        return "orange";
      default:
        return "orangered";
    }
  }
}
