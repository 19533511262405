// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "us-east-2",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_NdmRK065D",
    "aws_user_pools_web_client_id": "71eb5isrcapq0r6qp6b3ed5md4"
};


export default awsmobile;
