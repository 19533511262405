import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { FormGroup, Label, Input, FormText } from "reactstrap";
import LoaderButton from "../components/LoaderButton";
import SignUp, { pageId } from "./SignUp.js";
import { getDevicesByEmail } from "../resources/DataAccessors";
import { constants, pageStyles } from "../resources/constants";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      isLoading: "",
      confirmationCode: "",
      pageId: pageId.signIn,
      user: null
    };
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      //Auth.signIn will error out if login failed
      await Auth.signIn(this.state.email, this.state.password);
      let devices = await getDevicesByEmail(this.state.email);
      this.props.updateDeviceIds(devices);
      this.props.closeModal();
      this.props.userHasAuthenticated(true);
    } catch (e) {
      this.setState({ isLoading: false });
      if (e.message === "User is not confirmed.") {
        if (
          window.confirm(
            "Email already exists, but is not confirmed. Would you like to resend the confirmation code?"
          )
        ) {
          this.setState({ pageId: pageId.confirm });
        }
        this.setState({ isLoading: false });
      } else {
        alert(e.message);
      }
    }
  };

  handleConfirmationSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
      await Auth.signIn(this.state.email, this.state.password);
      this.props.closeModal();
      this.props.userHasAuthenticated(true);
    } catch (e) {
      alert(e.message);
    }
    this.setState({ isLoading: false });
  };

  validateForm() {
    if (this.state.pageId === pageId.confirm) {
      return (
        this.state.confirmationCode.length ===
        constants.confirmation_code_length
      );
    } else {
      return this.state.email.length > 0 && this.state.password.length > 0;
    }
  }

  renderSignUp() {
    return (
      <div className="Login">
        <form onSubmit={this.handleSubmit}>
          <FormGroup>
            <Input
              autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
              id="email"
              style={pageStyles.input}
              placeholder="Email"
            />
          </FormGroup>
          <FormGroup>
            <Input
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
              id="password"
              style={pageStyles.input}
              placeholder="Password"
            />
          </FormGroup>
          <LoaderButton
            block
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Login"
            loadingText="Logging in…"
          />
        </form>
      </div>
    );
  }

  renderConfirmationForm() {
    return (
      <form onSubmit={this.handleConfirmationSubmit}>
        <FormGroup>
          <Label>Confirmation Code</Label>
          <Input
            autoFocus
            type="tel"
            value={this.state.confirmationCode}
            onChange={this.handleChange}
            id="confirmationCode"
            style={pageStyles.input}
          />
          <FormText>
            Please check your email for the code. It may take up to a few
            minutes to appear in your inbox. If you don't see it, check spam.
          </FormText>
        </FormGroup>
        <LoaderButton
          block
          disabled={!this.validateForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Verify"
          loadingText="Verifying…"
        />
      </form>
    );
  }

  render() {
    let body = null;
    switch (this.state.pageId) {
      case pageId.signIn:
        body = this.renderSignUp();
        break;
      case pageId.confirm:
        body = this.renderConfirmationForm();
        break;
      case pageId.deviceId:
        body = (
          <SignUp
            pageId={pageId.deviceId}
            user={this.state.user}
            {...this.props}
          />
        );
        break;
      default:
    }

    return (
      <div className="login-container">
        <div className="login-header">Login</div>
        {body}
      </div>
    );
  }
}
