import React from "react";
import ReactLoading from "react-loading";
import { Line } from "react-chartjs-2";
import { pageStyles, getLineChartStyles } from "../../resources/constants.js";

/*
props:
data: list of TRH data
xAccessor: function to get the timestamp value
yAccessorAndLabel: list of objects of the following form:
{
label: "temperature" / "relative humidity"
yAccessor: y => y.temperature / y.relativeHumidity
}

formatYAxis: function to format y values
formatXAxis: function to format x values
*/

const lineStyles = getLineChartStyles();

export default class TRHChart extends React.Component {
  /*
  Formats data the way that react-chartjs-2 uses
  */
  parseData() {
    //if there is no data, render a blank chart
    if (
      !this.props.data ||
      this.props.data.length === 0 ||
      this.props.data[0] === null
    ) {
      return {};
    }

    let result = {
      labels: [], // x values
      datasets: []
    };

    //set x values
    for (const point of this.props.data[0].getValues()) {
      let xValue = this.props.xAccessor(point);
      let formattedXValue = this.props.formatXAxis(xValue);
      result.labels.push(formattedXValue);
    }

    for (let i = 0; i < this.props.data.length; i++) {
      let trhData = this.props.data[i];
      if (trhData === null) {
        continue;
      }

      let values = trhData.getValues();

      for (let a = 0; a < this.props.yAccessorsAndLabel.length; a++) {
        let yAccessorAndLabel = this.props.yAccessorsAndLabel[0];
        let yAccessor = yAccessorAndLabel.yAccessor;

        let dataset = {
          label: `${trhData.getName()}`,
          data: [],
          ...lineStyles[result.datasets.length],
        };

        for (let b = 0; b < values.length; b++) {

          let point = yAccessor(values[b]);

          //used to break apart the line

          let yValue = NaN;

          if (point) {
            yValue = this.props.formatter(point);
            // change °C to °F
            if (this.props.label === "temperature") {
              yValue = (yValue * 9.0 / 5.0) + 32.0;
            }
          }

          dataset.data.push(yValue);
        }

        result.datasets.push(dataset);
      }
    }

    return result;
  }

  render() {
    let data = this.parseData();
    options = JSON.parse(JSON.stringify(options));
    options.scales.yAxes[0].scaleLabel.labelString = this.props.yAxisLabel;
    options.scales.xAxes[0].scaleLabel.labelString = this.props.xAxisLabel;

    return (
      <div className={"simple-line-chart-wrapper"} style={{ width: "100%" }}>
        {this.props.isLoading && (
          <div className={"line-chart-wrapper-loading"}>
            <ReactLoading
              type={"spinningBubbles"}
              color={"#ffcc14"}
              height={"75%"}
              width={"100%"}
            />
          </div>
        )}
        {data.length !== 0 && <Line data={data} options={options} />}
        {data.length === 0 && (
          <div className="default-line-data"> No data for selected range </div>
        )}
      </div>
    );
  }
}

let options = {
  legend: {
    labels: {
      fontColor: pageStyles.lineChartStyles.legendLabelTextColor
    }
  },
  scales: {
    yAxes: [
      {
        ticks: {
          fontColor: pageStyles.lineChartStyles.ticksColor
        },
        scaleLabel: {
          display: true,
          padding: 1
        },
        gridLines: {
          display: true,
          color: pageStyles.lineChartStyles.gridLinesColor
        },
        position: 'left'
      }
    ],
    xAxes: [
      {
        ticks: {
          autoSkipPadding: 15,
          maxRotation: 0,
          fontColor: pageStyles.lineChartStyles.ticksColor
        },
        scaleLabel: {
          display: true,
          padding: 1,
          labelString: "Time (hh:mm:ss)"
        },
        gridLines: {
          display: true,
          color: pageStyles.lineChartStyles.gridLinesColor
        }
      }
    ]
  }
};
