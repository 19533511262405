import React from "react";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import moment from "moment";

/*
Picks one day from calendar.
Modified from react-day-picker documentation.
*/
export default class SimpleCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.state = {
      selectedDay: props.selectedDay || null
    };
  }

  async componentDidUpdate(prevProps) {
    if (this.props.selectedDay !== prevProps.selectedDay) {
      this.setState({
        selectedDay: this.props.selectedDay
      });
    }
  }

  handleDayClick(day, { selected, disabled }) {
    if (disabled) {
      window.alert("Try selecting a day in the right range!");
      return;
    }
    this.setState({
      selectedDay: selected ? undefined : day
    });
    this.props.onSelect(day);
  }

  render() {
    let disabledDays = this.props.disabledDays;

    const highlightedStyle = `.DayPicker-Day--highlighted {
        background-color: #1b76d3;
        color: white;
      }`;
    const modifiers = {
      highlighted: moment(this.state.selectedDay).toDate()
    };
    return (
      <div>
        <style>{highlightedStyle}</style>
        <div>{!this.state.selectedDay && "Please select a day"}</div>
        <DayPicker
          className="Simple"
          modifiers={modifiers}
          selectedDay={this.state.selectedDay}
          onDayClick={this.handleDayClick}
          disabledDays={disabledDays}
        />
      </div>
    );
  }
}
