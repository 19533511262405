import React, { Component } from "react";
import Mission from "../components/LandingPage/Mission";
import Service from "../components/LandingPage/Service";
import Sample from "../components/LandingPage/Sample";

export default class Landing extends Component {
  render() {
    return (
      <div className="Landing">
        {/* <!-- Header --> */}
        <header className="masthead">
          <div className="container">
            <div className="intro-text">
              <div className="intro-heading">Engaged IoT</div>
              <div className="intro-lead-in">Monitor all your devices</div>
              <a
                className="btn btn-primary btn-xl text-uppercase js-scroll-trigger"
                style={{ marginRight: "20px" }}
                href="#services"
              >
                Learn More
              </a>
              <a
                className="btn btn-secondary btn-xl text-uppercase js-scroll-trigger"
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.google.com/forms/d/e/1FAIpQLSe5syGLeqm_kQZsLJt42yXbykJ9CHRZae9abxzgbBpLDOYguQ/viewform"
              >
                Apply for our Lab / Project Team
              </a>
            </div>
          </div>
        </header>

        {/* <!-- Services --> */}
        <Service></Service>

        {/* <Portfolio portfolioLinks = {portfolioLinks}></Portfolio> */}

        {/* <!-- Mission Statement --> */}
        <Mission></Mission>

        {/* Sample Examples */}
        <Sample></Sample>

        {/* <!-- Contact --> */}
        {/*
        <section className="page-section" id="contact">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2 className="section-heading text-uppercase">Contact Us</h2>
                <h3 className="section-subheading text-muted">
                  {landingFields.contact_us.subtitle}
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <form
                  id="contactForm"
                  name="sentMessage"
                  noValidate="novalidate"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          id="name"
                          type="text"
                          placeholder="Your Name *"
                          required="required"
                          data-validation-required-message="Please enter your name."
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                      <div className="form-group">
                        <input
                          className="form-control"
                          id="email"
                          type="email"
                          placeholder="Your Email *"
                          required="required"
                          data-validation-required-message="Please enter your email address."
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                      <div className="form-group">
                        <input
                          className="form-control"
                          id="phone"
                          type="tel"
                          placeholder="Your Phone *"
                          required="required"
                          data-validation-required-message="Please enter your phone number."
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          id="message"
                          placeholder="Your Message *"
                          required="required"
                          data-validation-required-message="Please enter a message."
                        ></textarea>
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-lg-12 text-center">
                      <div id="success"></div>
                      <button
                        id="sendMessageButton"
                        className="btn btn-primary btn-xl text-uppercase"
                        type="submit"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
*/}
        {/* <!-- Footer --> */}
        <footer className="footer bg-dark">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-4">
                <span className="custom-copyright">Copyright &copy; Cornell 2021</span>
              </div>
              <div className="col-md-4">
                <ul className="list-inline quicklinks">
                  <li className="list-inline-item">
                    <a
                      href="https://energy.mae.cornell.edu/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Engaged IoT
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
