import moment from "moment";

import { lineChartModes } from "../../resources/constants.js";

export function dateRangeToString(dateRange) {
  if (!dateRange) {
    return "";
  }

  let { startDate, endDate } = dateRange;
  let dayFormatter = lineChartModes.daily.format;

  return `${dayFormatter(startDate)}-${dayFormatter(endDate)}`;
}

export function dateToDayMonthString(date) {
  return moment(date).format("M/D");
}

export function dateToString(date) {
  return moment(date).format("hh:mma M/D");
}

export const aggregateDevice = "aggregate-device-placeholder";
