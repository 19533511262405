import React from "react";
import Status from "../../components/Status/Status.js";
import "../../assets/css/styles.css";
import sensor from "../../assets/img/sensor.png";
import sensor_green from "../../assets/img/sensor_green.png";
import sensor_blue from "../../assets/img/sensor_blue.png";

import { Line } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import SimpleCalendar from "../../components/Calendar/SimpleCalendar";
import AddCovid from "../../components/SideMenu/AddDeviceByTypes/AddCovid";

function chartDataCO2() {
  return {
    labels: ['12AM', '1AM', '2AM', '3AM', '4AM', '5AM', '6AM', '7AM', '8AM',
      '9AM', '10AM', '11AM', '12PM'],
    datasets: [
      {
        label: 'CO2',
        fillColor: 'rgba(220,220,220,0.2)',
        strokeColor: 'rgba(220,220,220,1)',
        pointColor: 'rgba(220,220,220,1)',
        pointStrokeColor: '#fff',
        pointHighlightFill: '#fff',
        pointHighlightStroke: 'rgba(220,220,220,1)',
        data: [1142, 1102, 1250, 1298, 1200, 1180, 1185, 1172, 1180, 1120, 1145, 1170, 1180],
      }
    ]
  }
}
function chartDataPM() {
  return {
    labels: ['12AM', '1AM', '2AM', '3AM', '4AM', '5AM', '6AM', '7AM', '8AM',
      '9AM', '10AM', '11AM', '12PM'],
    datasets: [
      {
        label: 'PM2.5',
        fillColor: 'rgba(220,220,220,0.2)',
        strokeColor: 'rgba(220,220,220,1)',
        pointColor: 'rgba(220,220,220,1)',
        pointStrokeColor: '#fff',
        pointHighlightFill: '#fff',
        pointHighlightStroke: 'rgba(220,220,220,1)',
        data: [88, 92, 94, 92, 91, 95, 98, 82, 81, 80, 78, 79, 80],
      }
    ]
  }
}
function humanData1() {
  return {
    labels: ['12AM', '1AM', '2AM', '3AM', '4AM', '5AM', '6AM', '7AM', '8AM',
      '9AM', '10AM', '11AM', '12PM'],
    datasets: [
      {
        label: '# of people',
        backgroundColor: 'lightgrey',
        borderColor: 'lightgrey',
        borderWidth: 2,
        data: [65, 59, 80, 81, 56, 100, 120, 132, 128, 110, 95, 98, 96]
      }
    ]
  }
}

function humanData2() {
  return {
    labels: ['12AM-1AM', '1AM-2AM', '2AM-3AM', '3AM-4AM', '4AM-5AM', '5AM-6AM', '6AM-7AM', '7AM-8AM', '8AM-9AM',
      '9AM-10AM', '10AM-11AM', '11AM-12PM'],
    datasets: [
      {
        label: 'net entries',
        backgroundColor: 'lightgrey',
        borderColor: 'lightgrey',
        borderWidth: 2,
        data: [-6, 21, 1, -25, 44, 20, 12, -4, -18, -15, 3, -2]
      }
    ]
  }
}


export default class Covid extends React.Component {

  constructor(props) {
    super(props);
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const date = new Date()
    this.state = {
      data: chartDataCO2(),
      selectedOption: "CO2",
      chartTitle: "CO2 Level",
      selectedDate: monthNames[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear(),
      tabValue: 1,
      xRatioBlack: (250 / 750) + (20 / 750),
      yRatioBlack: (120 / 400) + (20 / 750),
      xRatioGreen: (250 / 750) + (20 / 750),
      yRatioGreen: (120 / 400) + (20 / 750),
      xRatioBlue: (250 / 750) + (20 / 750),
      yRatioBlue: (120 / 400) + (20 / 750),
      activeSensor: ""
    };
    this.imageClick.bind(this);
  }
  handleTabChange = (event, newValue) => {
    console.log(newValue)
    this.setState({
      tabValue: newValue,
    })
    if (newValue === 2) {
      this.setState({
        data: chartDataPM(),
        chartTitle: "PM2.5 Level"
      })
    } else if (newValue === 1) {
      this.setState({
        data: chartDataCO2(),
        chartTitle: "CO2 Level"
      })
    } else if (newValue === 3) {
      this.setState({
        data: humanData1(),
        chartTitle: "# of People in the Room"
      })
    } else {
      this.setState({
        data: humanData2(),
        chartTitle: "Net Entries into the Room"
      })
    }
  }
  changeDate(newDate) {
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const dateStr = monthNames[newDate.getMonth()] + " " + newDate.getDate() + ", " + newDate.getFullYear()
    this.setState({
      selectedDate: dateStr
    })
  }

  imageClick = (event) => {
    event.persist()
    if (this.state.activeSensor === "black") {
      this.setState({
        xRatioBlack: event.clientX / 750,
        yRatioBlack: event.clientY / 400
      })
    } else if (this.state.activeSensor === "green") {
      this.setState({
        xRatioGreen: event.clientX / 750,
        yRatioGreen: event.clientY / 400
      })
    } else if (this.state.activeSensor === "blue") {
      this.setState({
        xRatioBlue: (event.clientX / 750),
        yRatioBlue: event.clientY / 400
      })
    }
  }

  setSensorBlack = () => {
    this.setState({
      activeSensor: "black"
    })
  }
  setSensorBlue = () => {
    this.setState({
      activeSensor: "blue"
    })
  }
  setSensorGreen = () => {
    this.setState({
      activeSensor: "green"
    })
  }
  getBlackBoxShadow = () => {
    if (this.state.activeSensor === "black") {
      return "0 0 2px 2px #8FFFE0"
    }
  }
  getBlueBoxShadow = () => {
    if (this.state.activeSensor === "blue") {
      return "0 0 2px 2px #8FFFE0"
    }
  }
  getGreenBoxShadow = () => {
    if (this.state.activeSensor === "green") {
      return "0 0 2px 2px #8FFFE0"
    }
  }

  render() {
    return (
      <div className="covid-container">
        {this.props.devices && this.props.devices.length > 0 && (
          <Status devices={this.props.deviceIds} isSelectable={false} />
        )}
        <div>
          <h6 style={{ fontSize: '50px', marginLeft: "350px" }}>Floor Plan of Your Room</h6>
          <div onMouseDown={this.imageClick} style={{ marginLeft: "250px", borderStyle: 'solid', borderColor: 'black', backgroundColor: 'white', width: '750px', height: '400px', position: 'relative' }}>
            <img alt="" src={sensor} style={{ width: '40px', height: '40px', position: 'absolute', left: (this.state.xRatioBlack * 750) - 250 - 20, top: (this.state.yRatioBlack * 400) - 120 - 20, boxShadow: this.getBlackBoxShadow(), borderRadius: "4px" }}></img>
            <img alt="" src={sensor_green} style={{ width: '40px', position: 'absolute', height: '40px', left: (this.state.xRatioGreen * 750) - 250 - 20, top: (this.state.yRatioGreen * 400) - 120 - 20, boxShadow: this.getGreenBoxShadow(), borderRadius: "4px" }}></img>
            <img alt="" src={sensor_blue} style={{ width: '40px', position: 'absolute', height: '40px', left: (this.state.xRatioBlue * 750) - 250 - 20, top: (this.state.yRatioBlue * 400) - 120 - 20, boxShadow: this.getBlueBoxShadow(), borderRadius: "4px" }}></img>
          </div>
        </div>
        <div style={{ marginLeft: "250px", display: "flex" }}>
          <button onClick={this.setSensorBlack} style={{ width: "180px", height: "50px" }}>
            <img alt="" src={sensor} style={{ width: '25px', height: '25px' }}></img>
            CO2 sensor
          </button>
          <br />
          <button onClick={this.setSensorGreen} style={{ width: "180px", height: "50px", color: "#3FFF6F" }}>
            <img alt="" src={sensor_green} style={{ width: '25px', height: '25px' }}></img>
            PM2.5 sensor
          </button>
          <br />
          <button onClick={this.setSensorBlue} style={{ width: "180px", height: "50px", color: "#1D6BFF" }}>
            <img alt="" src={sensor_blue} style={{ width: '25px', height: '25px' }}></img>
            Foot Traffic sensor
          </button>
        </div>
        <br />
        <h6 style={{ fontSize: '40px', marginLeft: "250px" }}>Summary Statistics for: {this.state.selectedDate}</h6>
        <div style={{ display: "flex" }}>
          <Card style={{ width: "950px", marginLeft: "100px" }}>
            <AppBar position="static" color="default" >
              <Tabs
                value={this.state.tabValue}
                onChange={this.handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                centered
                aria-label="simple tabs example"
              >
                <Tab label="CO2" value={1} />
                <Tab label="PM2.5" value={2} />
                <Tab label="Total # of People in the Room" value={3} />
                <Tab label="Net Entries" value={4} />

              </Tabs>
            </AppBar>
            <Card style={{ paddingLeft: "30px" }}>

              <h5 style={{ display: "inline-block", fontSize: '30px' }}>{this.state.chartTitle}</h5>

              <div style={{ width: "100%", margin: "0 auto" }}>
                <div style={{ margin: "0 auto" }}>
                  <h6 style={{ display: "inline-block", fontSize: '20px', marginLeft: "150px" }}>MAX: 1298 ppm</h6>
                  <h6 style={{ display: "inline-block", fontSize: '20px', marginLeft: "150px" }}>AVG: 865 ppm</h6>
                  <h6 style={{ display: "inline-block", fontSize: '20px', marginLeft: "150px" }}>CURR: 921 ppm</h6>
                </div>
                {this.state.tabValue === 1 || this.state.tabValue === 2 ?
                  <div>
                    <Line data={this.state.data}
                      width="600" height="250" />
                  </div> :
                  <div>
                    <Bar
                      data={this.state.data}
                      width="700" height="350"
                      options={{
                        legend: {
                          display: true,
                          position: 'top'
                        }
                      }}
                    />
                  </div>

                }

              </div>
            </Card>
          </Card>
          <SimpleCalendar style={{ marginLeft: "40px" }} onSelect={date => this.changeDate(date)} />

        </div>
        <AddCovid style={{ marginLeft: "250px" }} />
      </div >
    );
  }
}