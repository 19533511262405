import { Auth } from "aws-amplify";
import { getQueryString, devices_codes } from "./constants.js";
import ElectricityData from "../components/Electricity/ElectricityData.js";
import TRHData from "../components/TRH/TRHData.js";
/* DataAccessors.js is a client file that wraps all functions that need to
communicate with the backend. NEVER call fetch directly outside of this file -- if
necessary to communicate with the api, write a function here. */

async function getData(startDate, endDate, deviceId, queryHeader) {
  let queryParameters = {
    deviceID: deviceId,
    startDate: startDate,
    endDate: endDate,
  };
  const maxPower = await getMaxPower(startDate, endDate, deviceId);

  const response = await fetch(queryHeader + getQueryString(queryParameters));
  const json = await response.json();

  json["max-power"] = maxPower;
  return json;
}

async function getMaxPower(startDate, endDate, deviceId) {
  let queryParameters = {
    deviceID: deviceId,
    startDate: startDate,
    endDate: endDate,
  };

  const response = await fetch(
    `/api/max_power${getQueryString(queryParameters)}`
  );
  const json = await response.json();
  return json;
}

export async function getDailyElectricityData(startDate, endDate, deviceId) {
  let data = await getData(startDate, endDate, deviceId, "/api/daily_power");
  return new ElectricityData(startDate, endDate, deviceId, "daily", data);
}

export async function getHourlyElectricityData(startDate, endDate, deviceId) {
  let data = await getData(startDate, endDate, deviceId, "/api/hourly_power");
  return new ElectricityData(startDate, endDate, deviceId, "hourly", data);
}

export async function getAirPollutionReadings(startDate, endDate, deviceId) {
  let queryParameters = {
    deviceID: deviceId,
    startDate: startDate,
    endDate: endDate,
  };
  const response = await fetch(
    `/api/air_pollution${getQueryString(queryParameters)}`
  );
  return await response.json();
}

/*
  Get temperature and relative humidity readings for given time intervals
*/
async function getTemperatureRelativeHumidityReadings(
  startDate,
  endDate,
  deviceId,
  timeInterval
) {
  let queryParameters = {
    deviceID: deviceId,
    startDate: startDate,
    endDate: endDate,
    timeInterval: timeInterval,
  };

  const response = await fetch(
    `api/temperature_and_relative_humidity${getQueryString(queryParameters)}`
  );
  let data = await response.json();
  return new TRHData(startDate, endDate, deviceId, data);
}

/*
  Get daily temperature and relative humidity readings
*/
export async function getDailyTemperatureRelativeHumidityReadings(
  startDate,
  endDate,
  deviceId
) {
  return await getTemperatureRelativeHumidityReadings(
    startDate,
    endDate,
    deviceId,
    "time(1d)"
  );
}

/*
  Get hourly temperature and relative humidity readings
*/
export async function getHourlyTemperatureRelativeHumidityReadings(
  startDate,
  endDate,
  deviceId
) {
  return await getTemperatureRelativeHumidityReadings(
    startDate,
    endDate,
    deviceId,
    "time(1h)"
  );
}

/* Gets last time device sent data*/
export async function getLatestTimeForDevice(deviceId, type) {
  let queryParameters = {
    deviceID: deviceId,
  };
  let query = "";
  switch (type) {
    case devices_codes.electricity:
      query = `/api/device_status_electricity${getQueryString(
        queryParameters
      )}`;
      break;
    case devices_codes.air:
      query = `/api/device_status_air_monitoring${getQueryString(
        queryParameters
      )}`;
      break;
    case devices_codes.trh:
      query = `/api/device_status_trh${getQueryString(queryParameters)}`;
      break;
    default:
      break;
  }
  const response = await fetch(query);
  return (await response.json())[0];
}

/*
Retrieve aggregate data from startDate to endDate tailored to device with
deviceId
*/
export async function getHourlyAggregateData(startDate, endDate, deviceId) {
  let data = await getData(
    startDate,
    endDate,
    deviceId,
    "/api/aggregate_hourly_power_by_device"
  );
  return new ElectricityData(startDate, endDate, deviceId, "hourly", data);
}

/*
Retrieve aggregate data from startDate to endDate tailored to device with
deviceId
*/
export async function getDailyAggregateData(startDate, endDate, deviceId) {
  let data = await getData(
    startDate,
    endDate,
    deviceId,
    "/api/aggregate_daily_power_by_device"
  );
  return new ElectricityData(startDate, endDate, deviceId, "daily", data);
}

/*
  Adds new account to accounts table (and in turn creates a new account id)
*/
export async function addNewAccount(email) {
  let queryParameters = {
    email: email,
  };

  const response = await fetch(
    `/api/add_new_account${getQueryString(queryParameters)}`
  );
  const json = await response.json();
  return json[0].success; // true if query succeeded, false otherwise
}

/*
  Can ONLY use this method if the user has successfully logged in.
*/
export async function getDevices() {
  const email = await getEmail();
  return await getDevicesByEmail(email);
}

/*
  Gets all tags associated with the given device id and defined by the current
  user. If deviceID is null or "null", then all tags defined by the current user
  are returned.
  Can ONLY use this method if the user has successfully logged in.
*/
export async function getTags(deviceID) {
  const email = await getEmail();
  return await getTagsByEmail(email, deviceID);
}

/*
  Gets device information for the associated email address by first getting
  the account id, and then retrieving devices for that account id.
*/
export async function getDevicesByEmail(email) {
  const accountId = await getAccountIdByEmail(email);
  let devices = await getDevicesByAccountId(accountId);
  return devices;
}

/*
  Gets tags defined by a user with the email address by first getting the
  account id, and then retrieving tags of the devices for that account id if
  deviceID is null or "null", or just the tags associated with the given
  device id.
*/
export async function getTagsByEmail(email, deviceID) {
  const accountId = await getAccountIdByEmail(email);
  return await getTagsByAccountId(accountId, deviceID);
}

/*
  Gets the subtypes of all the devices the user has.
  Can ONLY use this method if the user has successfully logged in.
*/
export async function getSubtypes() {
  const email = await getEmail();
  const accountId = await getAccountIdByEmail(email);
  return await getByAccountId(
    accountId,
    null,
    "/api/get_subtypes_by_account_id"
  );
}

/*
  Can ONLY use this method if the user has successfully logged in.
*/
export async function addDevice(user_device_id, name, description) {
  const email = await getEmail();
  await addDeviceToAccount(email, user_device_id, name, description);
}

/*
  Adds a list of tags to a device.
  Can ONLY use this method if the user has successfully logged in.
*/
export async function addTags(deviceId, tags) {
  const email = await getEmail();
  await addTagsToAccountDeviceByTTNid(email, deviceId, tags);
}

/*
user_device_id is USER GIVEN one (not the TTN one)
*/
export async function addDeviceToAccount(
  email,
  user_device_id,
  name,
  description
) {
  //first need to get corresponding account
  const accountId = await getAccountIdByEmail(email);

  //then get device id
  const deviceInformation = await getDeviceIdAndTypeById(user_device_id);

  let queryParameters = {
    account_id: accountId,
    device_id: deviceInformation.device_id,
    name: name,
    description: description,
  };
  await fetch(`/api/add_device_to_account${getQueryString(queryParameters)}`);
}

export async function getDeviceToRoomAssignment() {
  const email = await getEmail();
  const accountId = await getAccountIdByEmail(email);

  return await getByAccountId(
    accountId,
    null,
    "/api/get_device_to_room_assignment"
  );
}

export async function addFloorplanToAccount(floorplanName, floorplanType) {
  const email = await getEmail();
  const accountId = await getAccountIdByEmail(email);

  let queryParameters = {
    account_id: accountId,
    name: floorplanName,
    type: floorplanType,
  };
  await fetch(
    `/api/add_floorplan_to_account${getQueryString(queryParameters)}`
  );
}

export async function removeFloorplanFromAccount(floorplanId) {
  const email = await getEmail();
  const accountId = await getAccountIdByEmail(email);

  let queryParameters = {
    account_id: accountId,
    floorplan_id: floorplanId,
  };
  await fetch(
    `/api/remove_floorplan_from_account${getQueryString(queryParameters)}`
  );
}

export async function updateDeviceToRoomAssignment(lstToUpdate) {
  // [lstToUpdate] has format [{floor_plan_id: ..., room_id: ..., device_id: ...}]
  const email = await getEmail();
  const accountId = await getAccountIdByEmail(email);

  const valuesLst = [];

  lstToUpdate.forEach((item) => {
    valuesLst.push(
      `(${item.floor_plan_id}, ${item.room_id}, ${accountId}, "${item.device_id}")`
    );
  });

  const valuesStr = valuesLst.join(", ");

  let queryParameters = {
    values: valuesStr,
  };
  await fetch(
    `/api/update_device_to_room_assignment${getQueryString(queryParameters)}`
  );
}

export async function removeDeviceToRoomAssignment(lstToRemove) {
  // [lstToRemove] has format [{floor_plan_id: ..., room_id: ...}]

  const valuesLst = [];

  lstToRemove.forEach((item) => {
    valuesLst.push(`(${item.floor_plan_id}, ${item.room_id})`);
  });

  const valuesStr = valuesLst.join(", ");

  let queryParameters = {
    values: valuesStr,
  };
  await fetch(
    `/api/remove_device_to_room_assignment${getQueryString(queryParameters)}`
  );
}

export async function addGoalToAccountAndDevice(device_id, goal_percentage) {
  const email = await getEmail();

  //first need to get corresponding account
  const accountId = await getAccountIdByEmail(email);

  //then get device id
  // const deviceInformation = await getDeviceIdAndTypeById(user_device_id);

  let queryParameters = {
    account_id: accountId,
    device_id: device_id,
    goal_percentage: goal_percentage,
  };
  await fetch(
    `/api/add_goal_to_account_and_device${getQueryString(queryParameters)}`
  );
}

export async function updateGoalPercentage(deviceId, newGoalPercentage) {
  const email = await getEmail();
  const accountId = await getAccountIdByEmail(email);
  let queryParameters = {
    goal_percentage: newGoalPercentage,
    account_id: accountId,
    device_id: deviceId,
  };

  await fetch(
    `/api/update_goal_by_account_id_and_device_id${getQueryString(
      queryParameters
    )}`
  );
}

/*
  Gets goal percentage for the associated account id and device id
*/
export async function getGoalByAccountIdAndDeviceId(deviceId) {
  const email = await getEmail();
  const accountId = await getAccountIdByEmail(email);

  let queryParameters = {
    account_id: accountId,
    device_id: deviceId,
  };

  const response = await fetch(
    `/api/get_goal_by_account_id_and_device_id${getQueryString(
      queryParameters
    )}`
  );
  const json = await response.json();
  return json;
}

/*tags is a list of tags to be added to the given device of an account */
export async function addTagsToAccountDeviceByTTNid(email, device_id, tags) {
  if (tags.length > 0) {
    //first need to get corresponding account
    const accountId = await getAccountIdByEmail(email);
    let queryParameters = {
      account_id: accountId,
      device_id: device_id,
      numTags: tags.length,
    };
    for (let i = 0; i < tags.length; i++) {
      queryParameters["tag" + i.toString()] = tags[i];
    }

    await fetch(
      `/api/add_tag_to_account_device${getQueryString(queryParameters)}`
    );
  }
}

//deviceId is the TTN device identifier
export async function removeDeviceByDeviceId(deviceId) {
  const email = await getEmail();
  const accountId = await getAccountIdByEmail(email);
  await removeDeviceByAccountIdAndDeviceId(accountId, deviceId);
}

/*Removes all tags of a device (by TTN id) for an account */
export async function removeAllTagsFromAccountDeviceByTTNId(deviceId) {
  const email = await getEmail();
  const accountId = await getAccountIdByEmail(email);
  let queryParameters = {
    account_id: accountId,
    device_id: deviceId,
  };

  await fetch(
    `/api/remove_all_tags_from_account_device${getQueryString(queryParameters)}`
  );
}

export async function updateDeviceInformation(
  deviceId,
  newName,
  newDescription
) {
  const email = await getEmail();
  const accountId = await getAccountIdByEmail(email);
  let queryParameters = {
    account_id: accountId,
    device_id: deviceId,
    name: newName,
    description: newDescription,
  };

  await fetch(
    `/api/update_device_by_account_id_and_device_id${getQueryString(
      queryParameters
    )}`
  );
}

/*Updates the tags of a device for an account so that after the update the tags
are the given tags. tags is a list of tags.*/
export async function updateDeviceTags(deviceId, tags) {
  removeAllTagsFromAccountDeviceByTTNId(deviceId);
  const email = await getEmail();
  addTagsToAccountDeviceByTTNid(email, deviceId, tags);
}

/*Example input:
  [
    {subtypeArr: ["subtype1", "subtype2"], tagArr: ["residence", "floor 1"]},
    {subtypeArr: ["subtype1"], tagArr: ["office", "floor 1"]}
  ]
  where the first constraint group says the device needs to be subtype1 or
    subtype2, and has to be tagged with both "residence" and "floor 1". The
    second constraint group says the device needs to be subtype 1 and has to be
    tagged with both "office" and "floor 1". All the devices that satisfy at
    least one constraint group are returned (in terms of and ordered by device_id).
*/
export async function getDevicesBySubtypesAndTags(constraintGroups) {
  const email = await getEmail();
  const accountId = await getAccountIdByEmail(email);

  let queryParameters = {
    account_id: accountId,
    numConstraintGroups: constraintGroups.length.toString(),
  };

  for (let j = 0; j < constraintGroups.length; j++) {
    let { subtypeArr, tagArr } = constraintGroups[j];
    let jStr = j.toString() + "-";
    queryParameters[jStr + "numSubtypes"] = subtypeArr.length.toString();
    queryParameters[jStr + "numTags"] = tagArr.length.toString();
    for (let i = 0; i < subtypeArr.length; i++) {
      queryParameters[jStr + "subtype" + i.toString()] = subtypeArr[i];
    }

    for (let i = 0; i < tagArr.length; i++) {
      queryParameters[jStr + "tag" + i.toString()] = tagArr[i];
    }
  }

  const response = await fetch(
    `/api/get_devices_by_subtypes_and_tags${getQueryString(queryParameters)}`
  );
  const json = response && (await response.json());
  return json;
}

/*
  Retrieves the TTN device id from the user given id.
  Returns array in form [deviceId, type]
*/
export async function getDeviceIdAndTypeById(id) {
  let queryParameters = {
    id: id,
  };

  const response = await fetch(
    `/api/get_device_id_and_type_by_id${getQueryString(queryParameters)}`
  );
  const json = await response.json();
  return json.length === 0 ? null : json[0];
}

/*
  Get all survey questions
*/
export async function getSurveyQuestions() {
  const response = await fetch(`/api/get_survey_questions`);
  const json = response && (await response.json());
  return json;
}

/*
  Get survey answers by device ID
*/
export async function getSurveyAnswersByDeviceId(deviceId) {
  return getSurveyAnswersByDeviceIdHelper(deviceId);
}

/*
  Get all binded users by device ID
*/
export async function getAllBindedUsersByDeviceId(deviceId) {
  let queryParameters = {
    device_id: deviceId,
  };

  const response = await fetch(
    `/api/get_all_binded_users_by_device_id${getQueryString(queryParameters)}`
  );
  const json = response && (await response.json());
  return json;
}

/*
  Check if an admin ID is the same as the current account ID
*/
export async function isAdmin(adminID) {
  const email = await getEmail();
  const accountId = await getAccountIdByEmail(email);
  return accountId === adminID;
}

/*
  Update survey answers by device ID
*/

export async function updateSurveyAnswers(deviceId, surveyAnswers) {
  if (!surveyAnswers) return;

  // get accountId
  const email = await getEmail();
  const accountId = await getAccountIdByEmail(email);
  // determine survey status
  let numFilledQuestions = 0;
  Object.keys(surveyAnswers).forEach(function (questionId) {
    if (surveyAnswers[questionId] || surveyAnswers[questionId] === 0) {
      numFilledQuestions++;
    }
  });
  let surveyStatus =
    Object.keys(surveyAnswers).length === numFilledQuestions ? 2 : 1;

  let queryParameters = {
    account_id: accountId,
    device_id: deviceId,
  };

  const settings = {
    method: "POST",
    body: JSON.stringify({
      survey_answers: JSON.stringify(surveyAnswers),
      survey_status: surveyStatus,
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  await fetch(
    `/api/update_survey_answers_by_device_id${getQueryString(queryParameters)}`,
    settings
  );
}

/*
  Get electricity service classification (SC) formula by device ID
*/
export async function getElectricitySCFormulaByDeviceId(deviceId) {
  // get SC ID by device ID
  const surveyAnswers = (await getSurveyAnswersByDeviceIdHelper(deviceId))
    .survey_answers;

  const questionId = "3";
  if (surveyAnswers && surveyAnswers[questionId]) {
    let queryParameters = {
      sc_id: surveyAnswers[questionId],
    };

    // get SC formula by SC ID
    const response = await fetch(
      `/api/get_electricity_sc_formula_by_sc_id${getQueryString(
        queryParameters
      )}`
    );
    const json = response && (await response.json());
    return json;
  } else {
    return {
      sc_formula: "0",
    };
  }
}

//HELPERS

async function getEmail() {
  const user = await Auth.currentAuthenticatedUser();
  return user.attributes.email;
}

/*
  Gets device information for the associated account id
*/
async function getDevicesByAccountId(account_id) {
  return await getByAccountId(
    account_id,
    null,
    "/api/get_devices_by_account_id"
  );
}

/*
  Gets tags defined by an account with the account id
*/
async function getTagsByAccountId(account_id, device_id) {
  return await getByAccountId(
    account_id,
    device_id,
    "/api/get_tags_by_account_id"
  );
}

/*
  Helper function that submits a read request solely based on account id
*/
async function getByAccountId(account_id, device_id, route) {
  let queryParameters = {
    account_id: account_id,
    device_id: device_id,
  };

  const response = await fetch(route + getQueryString(queryParameters));
  const json = response && (await response.json());
  return json;
}

/*
  Helper function that retrieves the account id associated with the email address.
*/
async function getAccountIdByEmail(email) {
  if (typeof email != "string") {
    return null;
  }

  let queryParameters = {
    email: email,
  };

  const response = await fetch(
    `/api/get_account_id_by_email${getQueryString(queryParameters)}`
  );
  if (response.status === 500) {
    //internal server problems
    throw new Error("Internal server issue, unable to log in!");
  }
  const json = await response.json();
  return json;
}

async function removeDeviceByAccountIdAndDeviceId(accountId, deviceId) {
  let queryParameters = {
    account_id: accountId,
    device_id: deviceId,
  };
  await fetch(
    `/api/remove_device_by_account_id_and_device_id${getQueryString(
      queryParameters
    )}`
  );
}

async function getSurveyAnswersByDeviceIdHelper(deviceId) {
  let queryParameters = {
    device_id: deviceId,
  };

  const response = await fetch(
    `/api/get_survey_answers_by_device_id${getQueryString(queryParameters)}`
  );
  const json = response && (await response.json());
  return json;
}

/**
 * Get Alerts by device ID
 * @param  {String} the device ID
 * @returns {JSON} Status of success (and the rules quried when success)
 */
export async function getAlertsByDeviceID(deviceID) {
  let queryParameters = {
    deviceID: deviceID,
  };

  const response = await fetch(`/api/alerts${getQueryString(queryParameters)}`);
  const json = await response.json();
  return json;
}

/**
 * Create a kapacitor alert
 *
 * @param {String} name: The alert name (required)
 * @param {String} type: The alert type (required), either "threshold" or "deadman"
 * @param {String} threshold: The threshold value(required)
 * @param {String} emails: Email addresses to receive the alerts, seperated by spaces or comma (required)
 * @param  {Object} query: it should contain database, measurement, retentionPolicy,  field and deviceID (required)
 * @returns {JSON} Status of success (and ruleID when success)
 */
export async function createAlert(name, type, threshold, emails, query) {
  if (!emails) {
    emails = "";
  }
  const settings = {
    method: "POST",
    body: JSON.stringify({
      name: name,
      threshold: threshold,
      emails: emails.split(/[ ,]+/),
      type: type,
      query: query,
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(`/api/alerts`, settings);
  const json = await response.json();
  return json;
}

/**
 * Update a kapacitor alert
 *
 * @param {String} ruleID: The alert rulle id (required)
 * @param {String} name: The alert name (required)
 * @param {String} type: The alert type (required), either "threshold" or "deadman"
 * @param {String} threshold: The threshold value(required)
 * @param {String} emails: Email addresses to receive the alerts, seperated by spaces or comma (required)
 * @param  {Object} query: it should contain database, measurement, retentionPolicy,  field and deviceID (required)
 * @returns {JSON} Status of success (and ruleID when success)
 */
export async function updateAlert(
  ruleID,
  name,
  type,
  threshold,
  emails,
  query
) {
  if (!emails) {
    emails = "";
  }
  const settings = {
    method: "PUT",
    body: JSON.stringify({
      ruleID: ruleID,
      name: name,
      type: type,
      threshold: threshold,
      emails: emails.split(/[ ,]+/),
      query: query,
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(`/api/alerts`, settings);
  const json = await response.json();
  return json;
}

/**
 * Delete a specific alert rule
 *
 * @param {String} ruleID: the alert rulle id (required)
 * @returns {JSON} Status of success
 */
export async function deleteAlert(ruleID) {
  const settings = {
    method: "DELETE",
    body: JSON.stringify({
      ruleID: ruleID,
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(`/api/alerts`, settings);
  const json = await response.json();
  return json;
}

/**
 * Get most recent alert history by deviceID
 *
 * @param {deviceID} the device id (required)
 * @param {name} the alert name (optional)
 * @param {limit} the maximum number of history items returned (optional)
 * @returns {JSON} Status of success (and alert history when success)
 */
export async function getAlertHistory(deviceID, name, limit) {
  const settings = {
    method: "POST",
    body: JSON.stringify({
      name: name,
      deviceID: deviceID,
      limit: limit,
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(`/api/alerts/history`, settings);
  let json = await response.json();
  return json;
}
